import React from "react";
import {
  Container,
  Typography
} from "@material-ui/core";
import Bitcoin from "./Bitcoin";
import { getLanguage } from "../../../languages/getLanguage";
import { isMobileApp } from "../../../utils/auth";
import clsx from "clsx";
import "./style.scss";

function Index() {
  return (
    <div className="buy-plans">
      <Container maxWidth={isMobileApp() ? false : "md"}>
        <Typography variant="h5" className="title-page">
          {getLanguage("BUY_PLANS")}
        </Typography>
        <Bitcoin />
      </Container>
    </div>
  );
}
export default Index;
