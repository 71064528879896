import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getLanguage } from "../../../languages/getLanguage";
import { CustomToast } from "../../../settings";
import {
  ENDPOINT_ADD_BINARY_TREE,
  ENDPOINT_GET_SEARCH_POSITION,
} from "../../../settings/endpoint";
import { get, post } from "../../../utils/api";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AddTree({ data, _handleClose, _handleSubmit }) {
  const [position, setPosition] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setPosition(null);
      get(`${ENDPOINT_GET_SEARCH_POSITION}?side=${data.side}`, (data) =>
        setPosition(data)
      );
    }
  }, [data]);

  const _handleAddTree = () => {
    _handleClose();
    post(
      ENDPOINT_ADD_BINARY_TREE,
      {
        userId: data.user.id,
        side: position.side,
        sponsorId: position.id,
      },
      () => {
        CustomToast("success", "Success");
        _handleSubmit();
      },
      (error) => {
        CustomToast("error", error.msg);
      }
    );
  };

  if (data) {
    return position ? (
      <Dialog
        open={data !== null}
        onClose={_handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          margin: 16
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Do you want add <b>{data && data.user.username}</b> to binary tree ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="initial">
            {getLanguage("USERNAME")}: {position.username}
          </DialogContentText>
          <DialogContentText color="initial">
            {getLanguage("POSITION")}: {position.side}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleClose} variant="contained" color="secondary">
            {getLanguage("CANCEL")}
          </Button>
          <Button
            onClick={_handleAddTree}
            color="primary"
            autoFocus
            variant="contained"
          >
            {getLanguage("SUBMIT")}
          </Button>
        </DialogActions>
      </Dialog>
    ) : (
      <Backdrop open={true} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  } else {
    return null;
  }
}
