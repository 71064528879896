import {
  Button,
  Container,
  Checkbox,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import { getLanguage } from "../../../languages/getLanguage";
import { countryOptions } from "../../../settings/countries";
import VerificationPhoto from "./VerificationPhoto";
import { useHistory } from "react-router-dom";
import { CustomToast } from "../../../settings";
import { post } from "../../../utils/api";
import { ENDPOINT_POST_SEND_KYC } from "../../../settings/endpoint";
import { useDispatch, useSelector } from "react-redux";
import { _getNewProfile } from "../../../actions/miningActions";
import CustomLoading from "../common/CustomLoading";
import { makeStyles } from "@material-ui/core/styles";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

export default function NewKYC() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [errors, setErrors] = useState([]);
  const [FrontPhoto, setFrontPhoto] = useState(null);
  const [BackPhoto, setBackPhoto] = useState(null);
  const [AddressPhoto, setAddressPhoto] = useState(null);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);
  const { information } = user;
  const { verifyLevel } = information;

  const _handleSubmit = (e) => {
    e.preventDefault();
    const errors = [];
    const body = {};
    for (let item of Object.values(e.target)) {
      if (item.id) {
        if (!item.value && item.id !== "middleName") {
          errors.push(item.id);
        } else {
          body[item.id] = item.value;
        }
      }
    }
    if (!FrontPhoto) errors.push("FrontPhoto");
    if (!BackPhoto) errors.push("BackPhoto");
    if (!AddressPhoto) errors.push("AddressPhoto");
    setErrors(errors);
    if (errors.length === 0) _handleSendVerification(body);
  };

  const _handleSendVerification = (body) => {
    body.FrontPhoto = FrontPhoto;
    body.BackPhoto = BackPhoto;
    body.AddressPhoto = AddressPhoto;
    body.dateOfBirth = body.dateOfBirth + "T00:00:00";
    if (!checked) {
      CustomToast("error", getLanguage("PLEASER_CHECK_TOS"));
    } else {
      console.log(body);
      setLoading(true);
      post(
        ENDPOINT_POST_SEND_KYC,
        body,
        () => {
          CustomToast("success", getLanguage("KYC_SUCCESS"));
          dispatch(_getNewProfile());
          if (isMobileApp()) {
            history.push(`/mobile-app/dashboard/my-profile`);
          } else {
            history.push(`/dashboard/my-profile`);
          }
          setLoading(false);
        },
        (error) => console.log(error)
      );
    }
  };

  const _onChange = (e) => {
    const value = e.target.name ? e.target.name : e.target.id;
    let tempErrors = [...errors];
    tempErrors = tempErrors.filter((item) => item !== value);
    setErrors(tempErrors);
  };

  if (!verifyLevel) {
    return <CustomLoading />;
  } else if (verifyLevel === "PENDING" || verifyLevel === "VERIFIED") {
    return (
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Typography variant="h5" color="primary" align="center">
          {getLanguage("KYC_NOTIFICATION")}
        </Typography>
      </Container>
    );
  } else {
    return (
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Paper variant="outlined" className="p-3">
          <form onSubmit={_handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {getLanguage("IDENTITY_VERIFICATION")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  1. {getLanguage("PERSONAL_INFORMATION")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 4} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      maxLength={32}
                      required
                      error={errors.includes("firstName")}
                      fullWidth
                      label={getLanguage("FIRST_NAME")}
                      id="firstName"
                    />
                    <FormHelperText error>
                      {errors.includes("firstName") &&
                        getLanguage("PLEASE_ENTER_FIRST_NAME")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 4} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      id="middleName"
                      maxLength={32}
                      fullWidth
                      label={getLanguage("MIDDLE_NAME")}
                    />
                  </Grid>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 4} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      label={getLanguage("LAST_NAME")}
                      id="lastName"
                      maxLength={32}
                      required
                      error={errors.includes("lastName")}
                      fullWidth
                    />
                    <FormHelperText error>
                      {errors.includes("lastName") &&
                        getLanguage("PLEASE_ENTER_LAST_NAME")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      label={getLanguage("DOB")}
                      id="dateOfBirth"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors.includes("dateOfBirth")}
                      color="primary"
                    />
                    <FormHelperText error>
                      {errors.includes("dateOfBirth") &&
                        getLanguage("PLEASE_ENTER_DOB")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 6} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      size="medium"
                      select
                      fullWidth
                      error={errors.includes("documentType")}
                      label={getLanguage("ID_TYPE")}
                      inputProps={{
                        id: "documentType",
                        name: "documentType",
                        onChange: _onChange,
                      }}
                      defaultValue=""
                    >
                      {[
                        {
                          key: 0,
                          value: 0,
                          label: getLanguage("ID_CARD"),
                        },
                        {
                          key: 1,
                          value: 1,
                          label: getLanguage("PASSPORT"),
                        },
                        {
                          key: 2,
                          value: 2,
                          label: getLanguage("DRIVER_LICENSE"),
                        },
                      ].map((option) => (
                        <MenuItem
                          key={option.key + "_" + option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormHelperText error>
                      {errors.includes("documentType") &&
                        getLanguage("PLEASE_CHOOSE_DOCUMENT_TYPE")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 6}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      label={getLanguage("ID_CODE")}
                      id="documentNumber"
                      maxLength={32}
                      fullWidth
                      error={errors.includes("documentNumber")}
                      required
                    />
                    <FormHelperText error>
                      {errors.includes("documentNumber") &&
                        getLanguage("PLEASE_ENTER_DOCUMENT_NUMBER")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} lg={isMobileApp() ? 12 : 9}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      label={getLanguage("ADDRESS")}
                      id="residentialAddress"
                      maxLength={128}
                      fullWidth
                      error={errors.includes("residentialAddress")}
                      required
                    />
                    <FormHelperText error>
                      {errors.includes("residentialAddress") &&
                        getLanguage("PLEASE_ENTER_YOUR_ADDRESS")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 4} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      size="medium"
                      select
                      fullWidth
                      label={getLanguage("COUNTRY")}
                      error={errors.includes("nationality")}
                      required
                      inputProps={{
                        id: "nationality",
                        name: "nationality",
                        onChange: _onChange,
                      }}
                      defaultValue=""
                    >
                      {countryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormHelperText error>
                      {errors.includes("nationality") &&
                        getLanguage("PLEASE_ENTER_COUNTRY")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 4} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      label={getLanguage("CITY")}
                      id="city"
                      maxLength={32}
                      fullWidth
                      error={errors.includes("city")}
                      required
                    />
                    <FormHelperText error>
                      {errors.includes("city") &&
                        getLanguage("PLEASE_ENTER_CITY")}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={isMobileApp() ? 12 : 4} lg={isMobileApp() ? 12 : 3}>
                    <TextField
                      variant="outlined"
                      onChange={_onChange}
                      size="medium"
                      label={getLanguage("POSTAL_CODE")}
                      id="postalCode"
                      maxLength={32}
                      fullWidth
                      error={errors.includes("postalCode")}
                      required
                    />
                    <FormHelperText error>
                      {errors.includes("postalCode") &&
                        getLanguage("PLEASE_ENTER_POSTAL_CODE")}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  2. {getLanguage("PERSONAL_DOCUMENTS")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={isMobileApp() ? 12 : 4}>
                    <VerificationPhoto
                      _handleComplete={(e) => {
                        setFrontPhoto(e);
                        _onChange({
                          target: {
                            id: "FrontPhoto",
                          },
                        });
                      }}
                      title={getLanguage("Front_ID_card")}
                      errors={errors}
                      type="FrontPhoto"
                    />
                  </Grid>
                  <Grid item xs={12} sm={isMobileApp() ? 12 : 4}>
                    <VerificationPhoto
                      _handleComplete={(e) => {
                        setBackPhoto(e);
                        _onChange({
                          target: {
                            id: "BackPhoto",
                          },
                        });
                      }}
                      title={getLanguage("Back_ID_card")}
                      errors={errors}
                      type="BackPhoto"
                    />
                  </Grid>
                  <Grid item xs={12} sm={isMobileApp() ? 12 : 4}>
                    <VerificationPhoto
                      _handleComplete={(e) => {
                        setAddressPhoto(e);
                        _onChange({
                          target: {
                            id: "AddressPhoto",
                          },
                        });
                      }}
                      title={getLanguage("Address_verify")}
                      errors={errors}
                      type="AddressPhoto"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Checkbox
                      value="allowPrivacyPolicy"
                      color="primary"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                  </Grid>
                  <Grid item>
                    <PrivacyPolicy />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  // fullWidth
                  disabled={loading}
                  size="large"
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    getLanguage("CONTINUE")
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    );
  }
}

function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <Typography variant="body2">
      {getLanguage("CheckBox_PrivacyPolicy", null, [
        <a className={classes.link + " text-primary"} href="/terms-of-services" target="_blank">
          {getLanguage("Terms_and_Conditions")}
        </a>,
        <a className={classes.link + " text-primary"} href="/privacy-policy" target="_blank">
          {getLanguage("Privacy_Policy")}
        </a>])}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))