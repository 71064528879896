import React from "react";
import {
    Grid,
    Typography,
    Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getLanguage } from "../../../languages/getLanguage";
import { isMobileApp } from "../../../utils/auth";

function PlansMining() {
    return (
        <Grid item xs={12}>
            <Typography variant="h5" className="text-center mb-2">{getLanguage("PLANS")}</Typography>
            <Paper variant="outlined" className="p-2 bg-transparent">
                <Grid container spacing={2} className="pb-2 pt-2">
                    <Grid item xs={6} className="text-center">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/plans/buy" : "/dashboard/plans/buy"}>
                            <img src="/images/app-icon/buyplans.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {getLanguage("BUY_PLANS")}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6} className="text-center">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/plans/my" : "/dashboard/plans/my"}>
                            <img src="/images/app-icon/Myplans.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {getLanguage("MY_PLANS")}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
export default PlansMining;