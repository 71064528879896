import { FETCH_TRANSFER_HISTORY, FETCH_WITHDRAW_HISTORY } from "../constants";
import {
  ENDPOINT_GET_WITHDRAW,
  ENDPOINT_GET_WITHDRAW_HISTORY,
} from "../settings/endpoint";
import { post } from "../utils/api";

export const _getWithdrawHistory = (page, pageSize, type) => (dispatch) => {
  post(
    ENDPOINT_GET_WITHDRAW,
    {
      page,
      pageSize,
      search: "",
      orderBy: "",
      responseMeta: true,
      filters: {
        type,
      },
    },
    (data) =>
      dispatch({
        type:
          type === "TRANSFER" ? FETCH_TRANSFER_HISTORY : FETCH_WITHDRAW_HISTORY,
        payload: data,
      })
  );
};
