import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Container,
  useMediaQuery,
} from "@material-ui/core";
import "./style.scss";
import Carousel from "./Carousel";
import Balances from "../common/Balances";
import CustomLoading from "../common/CustomLoading";
import HomeInfo from "./HomeInfo";

import PlansMining from "../../mobile-app-areas/home-page/PlansMining";
import Transactions from "../../mobile-app-areas/home-page/Transactions";
import Wallet from "../../mobile-app-areas/home-page/Wallet";
import Menu from "../../mobile-app-areas/home-page/Menu";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

function Index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { mining } = useSelector((state) => state);
  const { balances, minedBalances } = mining;

  return minedBalances.length ? (
    <div className="home">
      <div className="banner mb-3">
        {
          (isMobileApp() || isMobile) ?
            <div style={{ marginTop: "-30px" }}>
              <Carousel />
            </div> :
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Carousel />
                </Grid>
              </Grid>
            </Container>
        }
      </div>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <HomeInfo />
          {
            (isMobileApp() || isMobile) ?
              <>
                <PlansMining />
                <Transactions />
                <Wallet />
                <Menu />
              </> :
              <Balances balances={balances} minedBalances={minedBalances} />
          }
        </Grid>
      </Container>
    </div>
  ) : (
    <CustomLoading />
  );
}
export default Index;
