import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Video_IminingClubVideoIntro from "../../assets/videos/miningclub-videointro.mp4";
import { getLanguage } from "../../../languages/getLanguage";

const Banner = () => {
  return (
    <div className="banner-homepage">
      <div className="contents text-center">
        <Container maxWidth="md">
          <img
            src="/images/banner-img/miningclub-banner.png"
            alt=""
            className="logo-banner"
          />
          <Typography variant="h3" className="text-white text-bolder pt-3 pb-5">
            {getLanguage("BEST_WAY_TO_MINING", null, [
              <img
                src="/images/banner-img/filecoin-logo.svg"
                alt="logo-filecoin"
                className="fil-coin-logo"
              />,
            ])}
          </Typography>
          <Container maxWidth="sm">
            <Typography variant="h6" className="text-white">
              {getLanguage("home_banner_text")}
            </Typography>
          </Container>
        </Container>
      </div>
      <video
        poster="/images/banner-img/Pic-background-MiningClub.jpg"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={Video_IminingClubVideoIntro} type="video/mp4"></source>
      </video>
    </div>
  );
};

export default Banner;
