import { Container, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getLanguage } from "../../../languages/getLanguage";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MatchHeight from "../../common/fix-height/MatchHeight";
import MutationObserver from 'react-mutation-observer';

const Tutorial = () => {
  const cards = [
    {
      title: `${getLanguage("tutorial_card_title_1")}`,
      text: `${getLanguage("tutorial_card_text_1")}`,
      step: 1,
      urlImg: "/images/tutorial-img/tutorial-item-1.png",
    },
    {
      title: `${getLanguage("tutorial_card_title_2")}`,
      text: `${getLanguage("tutorial_card_text_2")}`,
      step: 2,
      urlImg: "/images/tutorial-img/tutorial-item-2.png",
    },
    {
      title: `${getLanguage("tutorial_card_title_3")}`,
      text: `${getLanguage("tutorial_card_text_3")}`,
      step: 3,
      urlImg: "/images/tutorial-img/tutorial-item-3.png",
    },
    {
      title: `${getLanguage("tutorial_card_title_4")}`,
      text: `${getLanguage("tutorial_card_text_4")}`,
      step: 4,
      urlImg: "/images/tutorial-img/tutorial-item-4.png",
    },
  ];

  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className="tutorial" id="tutorial">
      <Container>
        <Typography
          variant="h4"
          className="text-center pb-5 mb-5 text-white m-auto"
          style={{ maxWidth: 600 }}
        >
          {getLanguage("tutorial_title")}
        </Typography>
        {screen ? <Mobile cards={cards} /> : <Desktop cards={cards} />}
      </Container>
    </div>
  );
};

export default Tutorial;

const Desktop = (props) => {
  const { cards } = props;
  return (
    <Grid container spacing={2}>
      {cards &&
        cards.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
            <MutationObserver
              onContentChange={() => MatchHeight.init()}
              onChildRemoval={() => MatchHeight.init()}
              onChildAddition={() => MatchHeight.init()}
            >
              <Paper className="card text-center pt-4 pb-4 pl-2 pr-2">
                <Typography variant="h2" color="primary" className="text-bolder">
                  {item.step}
                </Typography>
                <div className="mt-5 mb-5">
                  <img src={item.urlImg} alt="tutorial-img-item" />
                </div>
                <div data-mh className="mb-2">
                  <Typography
                    variant="h5"
                    color="primary"
                    className="text-bolder"
                  >
                    {item.title}
                  </Typography>
                </div>
                <Typography variant="body1">{item.text}</Typography>
              </Paper>
            </MutationObserver>
          </Grid>
        ))}
    </Grid>
  );
};

const responsive = {
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};
const Mobile = (props) => {
  const { cards } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MutationObserver
          onContentChange={() => MatchHeight.init()}
          onChildRemoval={() => MatchHeight.init()}
          onChildAddition={() => MatchHeight.init()}
        >
          {cards && (
            <MultiCarousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
            >
              {cards.map((item, index) => (
                <div className="ml-1 mr-1" key={index}>
                  <Paper className="card text-center pt-4 pb-4 pl-2 pr-2">
                    <Typography variant="h2" color="primary" className="text-bolder">
                      {item.step}
                    </Typography>
                    <div className="mt-5 mb-5">
                      <img src={item.urlImg} alt="tutorial-img-item" />
                    </div>
                    <div data-mh className="mb-2">
                      <Typography
                        variant="h5"
                        color="primary"
                        className="text-bolder"
                      >
                        {item.title}
                      </Typography>
                    </div>
                    <Typography variant="body1">{item.text}</Typography>
                  </Paper>
                </div>
              ))}
            </MultiCarousel>
          )}
        </MutationObserver>
      </Grid>
    </Grid>
  );
};
