import React from "react";
import { Container, Grid } from "@material-ui/core";
import "./style.scss";
import { HashLink } from "react-router-hash-link";
import { getLanguage } from "../../../../languages/getLanguage";

import MatchHeight from "../../../common/fix-height/MatchHeight";
import MutationObserver from 'react-mutation-observer';

const Footer = () => {
  return (
    <div className="footer">
      <MutationObserver
        onContentChange={() => MatchHeight.init()}
        onChildRemoval={() => MatchHeight.init()}
        onChildAddition={() => MatchHeight.init()}
      >
        <Container className="mb-5">
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={4} className="slot-1">
              <div className="content-logo">
                <img src={`/images/logo.png`} alt="logo-web" />
                <p className="email-logo">info@miningclub.org</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} className="slot-2">
              <label data-mh className="p-title">{getLanguage("title_footer_1")}</label>
              <p
                style={{
                  lineHeight: "2.5rem",
                  fontSize: 17,
                  paddingRight: 10,
                  paddingLef: 10,
                }}
              >
                {getLanguage("item_footer_1")}
              </p>
              <ul>
                <li>
                  <a
                    href="https://twitter.com/MiningClub01"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/icon-social/icon-item-1.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCUyk2K1bHEfpxFXRZymiNzg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/icon-social/icon-item-2.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/miningclub_community"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/icon-social/icon-item-3.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/miningclubglobal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/icon-social/icon-item-4.png" alt="" />
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} className="slot-3">
              <label data-mh className="p-title">{getLanguage("title_footer_2")}</label>
              <HashLink to="/terms-of-services">
                <p>{getLanguage("item_footer_2")}</p>
              </HashLink>
              <HashLink to="/privacy-policy">
                <p>{getLanguage("item_footer_3")}</p>
              </HashLink>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} className="slot-4">
              <label data-mh className="p-title">{getLanguage("title_footer_3")}</label>
              <a
                href={getLanguage("menu_footer_link_1")}
                target="_blank"
                rel="noreferrer"
              >
                <p>{getLanguage("item_footer_4")}</p>
              </a>
              <a
                href={getLanguage("menu_footer_link_2")}
                target="_blank"
                rel="noreferrer"
              >
                <p>{getLanguage("item_footer_5")}</p>
              </a>
            </Grid>
          </Grid>
        </Container>
      </MutationObserver>
      <hr />
      <Container>
        <p>{getLanguage("text_footer")}</p>
      </Container>
    </div>
  );
};

export default Footer;
