import { combineReducers } from "redux";
import { MiningReducer } from "./miningReducer";
import { SettingReducer } from "./settingReducer";
import { UserReducer } from "./userReducer";
import { WalletReducer } from "./walletReducer";

const rootReducer = combineReducers({
  setting: SettingReducer,
  user: UserReducer,
  mining: MiningReducer,
  wallet: WalletReducer,
});

export default rootReducer;
