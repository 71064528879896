import {
  DialogContent,
  Button,
  DialogActions,
  Grid,
  DialogTitle,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import {
  ACCESS_TOKEN_KEY,
  CustomSwal
} from "../../../settings";
import {
  checkEmpty,
  checkPassword,
} from "../../../settings/validate";
import { put } from "../../../utils/api";
import { ENDPOINT_PUT_CHANGE_PASSWORD } from "../../../settings/endpoint";
import { getLanguage } from "../../../languages/getLanguage";
import {
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { green } from '@material-ui/core/colors';
import { isMobileApp } from "../../../utils/auth";

function ChangePassword(props) {
  const classes = useStyles();
  const { close } = props;
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fieldError, setFieldError] = useState(null);

  const _handleChangePassword = (e) => {
    e.preventDefault();

    //#region validete
    let tempFieldError = [];

    if (checkEmpty(oldPassword)) {
      tempFieldError.push({
        field: "oldPassword",
        error: getLanguage("PLEASE_ENTER_OLD_PASSWORD"),
      });
    }

    if (checkEmpty(newPassword)) {
      tempFieldError.push({
        field: "newPassword",
        error: getLanguage("PLEASE_ENTER_NEW_PASSWORD"),
      });
    }

    if (checkPassword(oldPassword)) {
      tempFieldError.push({
        field: "oldPassword",
        error: getLanguage("INVALID_PASSWORD", { minChar: 8, maxChar: 32 }),
      });
    }

    if (checkPassword(newPassword)) {
      tempFieldError.push({
        field: "newPassword",
        error: getLanguage("INVALID_PASSWORD", { minChar: 8, maxChar: 32 }),
      });
    }

    if (checkEmpty(confirmPassword)) {
      tempFieldError.push({
        field: "confirmPassword",
        error: getLanguage("PLEASE_ENTER_CONFIRM_PASSWORD"),
      });
    }
    else {
      if (newPassword !== confirmPassword) {
        tempFieldError.push({
          field: "confirmPassword",
          error: getLanguage("INVALID_REPEAT_PASSWORD"),
        });
      };
    }
    //#endregion

    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setLoading(true);
      setFieldError(null);

      const param = {
        oldPassword,
        password: newPassword,
      }

      put(
        ENDPOINT_PUT_CHANGE_PASSWORD, param,
        () => {
          CustomSwal("success", getLanguage("CHANGE_PASSWORD_SUCCESS")).then(() => {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            if (isMobileApp()) {
              window.location.replace("/mobile-app/dashboard/login");
            } else {
              window.location.replace("/dashboard/login");
            }
          })
        },
        (error) => {
          setLoading(false);
          setFieldError([{ field: "all", error: getLanguage(error.code) }]);
        }
      );
    }
  };

  const _checkError = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const _getErrorContent = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return check.error
      } else {
        return null;
      };
    } else {
      return null;
    }
  };

  return (
    <form className={classes.form} onSubmit={_handleChangePassword} noValidate>
      <DialogTitle>{getLanguage("UPDATE_PASSWORD")}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              required
              fullWidth
              error={_checkError("oldPassword")}
            >
              <InputLabel>{getLanguage("OLD_PASSWORD")}</InputLabel>
              <Input
                type={showOldPassword ? "text" : "password"}
                onChange={(e) => setOldPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                _checkError("oldPassword") &&
                <FormHelperText error>
                  {_getErrorContent("oldPassword")}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              required
              fullWidth
              error={_checkError("newPassword")}
            >
              <InputLabel>{getLanguage("NEW_PASSWORD")}</InputLabel>
              <Input
                type={showNewPassword ? "text" : "password"}
                onChange={(e) => setNewPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                _checkError("newPassword") &&
                <FormHelperText error>
                  {_getErrorContent("newPassword")}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              required
              fullWidth
              error={_checkError("confirmPassword")}
            >
              <InputLabel>{getLanguage("CONFIRM_PASSWORD")}</InputLabel>
              <Input
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                _checkError("confirmPassword") &&
                <FormHelperText error>
                  {_getErrorContent("confirmPassword")}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          {
            _checkError("all") &&
            <Grid item xs={12}>
              <FormHelperText error>
                {_getErrorContent("all")}
              </FormHelperText>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Button
            color="secondary"
            variant="contained"
            onClick={close}
          >{getLanguage("CANCEL")}</Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {getLanguage("UPDATE")}
            </Button>
            {loading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
      </DialogActions>
    </form>
  );
}

export default ChangePassword;


//#region CSS
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
}));
//#endregion