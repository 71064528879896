import React from "react";
import { formatTime } from "../../../settings/format";
import DefaultMan from "../../assets/images/default-man.png";
import { getLanguage } from "../../../languages/getLanguage";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CustomToast } from "../../../settings";
import { get } from "../../../utils/api";
import { isMobileApp } from "../../../utils/auth";

function Info(props) {
  const { user } = props;
  const { information } = user;
  const hostname = window.location.hostname;

  return (
    <>
      <div className="profile-header">
        <div className="row">
          <div className="col-12 text-center">
            <img src={DefaultMan} className="avatar" alt="avatar" />
          </div>
        </div>
      </div>
      <div className="card profile-info">
        {information && (
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <b>ID:</b> #{information.id}
            </li>
            <li className="list-group-item">
              <b>{getLanguage("USERNAME")}:</b> {information.username}
            </li>
            <li className="list-group-item">
              <b>{getLanguage("EMAIL")}:</b> {information.email}
            </li>
            <li className="list-group-item">
              <b>{getLanguage("YOUR_SPONSOR")}:</b> {information.sponsorUsername}
            </li>
            <li className="list-group-item">
              <b>{getLanguage("Referral_Link")}:</b>
              <CopyToClipboard
                text={
                  isMobileApp() ?
                    `${hostname}/mobile-app/dashboard/sign-up?sponsor=${information.username}` :
                    `${hostname}/dashboard/sign-up?sponsor=${information.username}`
                }
                onCopy={() => CustomToast("success", getLanguage("COPIED"))}
              >
                <span
                  className="ml-2"
                  title="copy"
                  style={{ cursor: "pointer" }}
                >
                  {
                    isMobileApp() ?
                      `${window.location.origin.toString()}/mobile-app/dashboard/sign-up/${information && information.username}` :
                      `${window.location.origin.toString()}/dashboard/sign-up/${information && information.username}`
                  }
                  <FileCopyIcon className="ml-2" fontSize="small" />
                </span>
              </CopyToClipboard>
            </li>
          </ul>
        )}
      </div>
    </>
  );
}

export default Info;
