import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AppBar,
  Tabs,
  Tab,
  Container,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";
import { useSelector } from "react-redux";
import { _getBalance } from "../../../actions/miningActions";
import { S3_URL } from "../../../settings";
import Withdraw from "./Withdraw";
import Transfer from "./Transfer";
import TransferHistory from "./TransferHistory";
import WithdrawHistory from "./WithdrawHistory";
import CustomLoading from "../common/CustomLoading";
import { useHistory, useParams } from "react-router-dom";
import { formatAmount } from "../../../settings/format";
import { Link } from "react-router-dom";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";
import { CheckBox, CheckBoxSharp, CheckCircle } from "@material-ui/icons";

function Index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { mining, user } = useSelector((state) => state);
  const { balances } = mining;
  const [balance, setBalance] = useState(null);
  const { asset } = useParams();
  const history = useHistory();
  const { information } = user;
  const [showNotify, setShowNotify] = useState(false);

  //#region Tab
  const [valueTab, setValueTab] = useState(0);
  const panes = [
    {
      menuItem: getLanguage("TRANSFER"),
      render: (
        <Transfer balance={balance} _showWarning={() => setShowNotify(true)} />
      ),
      key: "TRANSFER",
    },
    {
      menuItem: getLanguage("WITHDRAWAL"),
      render: (
        <Withdraw balance={balance} _showWarning={() => setShowNotify(true)} />
      ),
      key: "WITHDRAW",
    },
  ];

  const _handleChangeSelectOption = (e) => {
    var asset = e.target.value;
    if (isMobileApp()) {
      history.push(`/mobile-app/dashboard/wallet/withdraw/${asset}`);
    } else {
      history.push(`/dashboard/wallet/withdraw/${asset}`);
    }
  };

  const _handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (balances) {
      const currentAsset = balances.filter((item) => item.asset === asset)[0];
      if (!currentAsset || !asset) {
        const temp = balances[0];
        if (isMobileApp()) {
          history.push(`/mobile-app/dashboard/wallet/withdraw/${temp.asset}`);
        } else {
          history.push(`/dashboard/wallet/withdraw/${temp.asset}`);
        }
      } else {
        setBalance(currentAsset);
      }
    }
  }, [asset, balances, history]);

  useEffect(() => {
    if (
      valueTab === 1 &&
      information.verifyLevel &&
      information.verifyLevel !== "VERIFIED"
    ) {
      setShowNotify(true);
    }
  }, [information.verifyLevel, valueTab]);

  return (
    <div className="withdraw">
      {balance ? (
        <>
          <Container className="mb-3" maxWidth={isMobileApp() ? false : "sm"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" className="title-page">
                  {getLanguage("TRANSFER_WITHDRAW")}
                </Typography>
                <div className="card mb-4">
                  <div className="card-body">
                    <FormControl fullWidth className="mb-3">
                      <InputLabel>{getLanguage("SELECT_ASSET")}</InputLabel>
                      <Select
                        value={balance.asset}
                        onChange={(e) => _handleChangeSelectOption(e)}
                      >
                        {balances.map((item, index) => (
                          <MenuItem value={item.asset} key={index}>
                            <img
                              src={`${S3_URL}/coins/${item.asset}.png`}
                              alt={item.asset}
                              width="20"
                              className="mr-2"
                            />
                            {item.asset}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText className="text-right mt-2">
                        {getLanguage("AVAILABLE")}:{" "}
                        {formatAmount(balance.amount)} {balance.asset}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <AppBar position="static" color="default">
                  <Tabs
                    value={valueTab}
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={_handleChangeTab}
                    scrollButtons="auto"
                    variant="fullWidth"
                  >
                    {panes.map((item, index) => (
                      <Tab
                        label={item.menuItem}
                        key={index}
                        value={item.index}
                      />
                    ))}
                  </Tabs>
                </AppBar>
                {panes[valueTab].render}
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={isMobileApp() ? false : "lg"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {panes[valueTab].key === "TRANSFER" ? (
                  <TransferHistory />
                ) : (
                  <WithdrawHistory />
                )}
              </Grid>
            </Grid>
          </Container>
          <Dialog
            open={showNotify}
            keepMounted
            onClose={() => setShowNotify(false)}
          >
            <DialogTitle>
              {getLanguage("USE_WITHDRAW_SERVER_YOU_NEED")}
            </DialogTitle>
            <Divider className="mb-3" />
            <DialogContent>
              <Grid container alignItems="center" spacing={1} className="mb-2">
                <Grid item xs="auto">
                  <CheckCircle
                    color={
                      information.verifyLevel === "VERIFIED"
                        ? "primary"
                        : "inherit"
                    }
                  />
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    color={
                      information.verifyLevel === "VERIFIED"
                        ? "primary"
                        : "inherit"
                    }
                  >
                    {getLanguage("VERIFY_ACCOUNT")}
                  </Typography>
                </Grid>
                {information.verifyLevel !== "VERIFIED" && (
                  <Grid
                    xs="auto"
                    item
                    className="pl-5"
                    style={{ flex: 1 }}
                    align="right"
                  >
                    <Button
                      variant="contained"
                      color={"primary"}
                      onClick={() => {
                        isMobileApp()
                          ? history.push("/mobile-app/dashboard/verify")
                          : history.push("/dashboard/verify");
                      }}
                    >
                      {getLanguage("VERIFY")}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs="auto">
                  <CheckCircle
                    color={information.gaEnable ? "primary" : "inherit"}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    color={information.gaEnable ? "primary" : "inherit"}
                  >
                    {getLanguage("ENABLE_GOOGLE_AUTHENTICATION")}
                  </Typography>
                </Grid>
                {!information.gaEnable && (
                  <Grid
                    xs="auto"
                    item
                    className="pl-5"
                    style={{ flex: 1 }}
                    align="right"
                  >
                    <Button
                      variant="contained"
                      color={"primary"}
                      onClick={() => {
                        isMobileApp()
                          ? history.push("/mobile-app/dashboard/my-profile")
                          : history.push("/dashboard/my-profile");
                      }}
                    >
                      {getLanguage("TURN_ON")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <Divider className="mt-3" />
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowNotify(false)}
              >
                {getLanguage("OK")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <CustomLoading />
      )}
    </div>
  );
}

export default Index;
