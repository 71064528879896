export const en = {
  ID: "ID",
  DASHBOARD: "Dashboard",
  GACODE_WRONG: "Google Authenticator code was wrong",
  ENGLISH: "English",
  VIETNAMESE: "Vietnamese",
  KOREAN: "Korean",
  SIGN_UP_1: "Don't have an account? Sign Up",
  NUMBER_GREATER: "{{param}} must be greater than {{number}}",
  SUCCESS: "Success",
  Search_username: "Search username",
  BEST_WAY_TO_MINING_FILECOIN: "Best way to Mining Filecoin",
  BEST_WAY_TO_MINING:
    "Best way to Mining <span class='text-nowrap'>[[0]] Filecoin</span>",
  PLANS: "Plans",
  BUY_PLANS: "Buy plans",
  MY_PLANS: "My plans",
  BINARY_TREE: "Binary tree",
  QUESTION: "Question",
  PLEASE_ENTER_ADDRESS: "Please enter address",
  MINING_CLUB: "Mining Club",
  TRANSACTION: "Transaction",
  USERNAME: "Username",
  CREATE_MEMBER: "Create member",
  YES: "Yes",
  OTP_WRONG: "OTP wrong",
  EDIT_PASSWORD: "Edit password",
  CONFIRM_PASSWORD: "Confirm password",
  UPDATE_PASSWORD: "Update password",
  NO: "No",
  INFO: "Info",
  EMAIL_OTP: "Email OTP",
  ACCOUNT_NOT_FOUND: "Account not found",
  ARR_YOU_SURE: "Are you sure?",
  OTP_AGAIN: "You did not receive the OTP code? try resend request.",
  SEND_OTP_AGAIN:
    "<div><p>Please check your email <span style='color:red'>{{email}}</span> to receive an OTP.</p><p>Countdown can claim return OTP: <b>{{time}}</b> seconds.</p></div>",
  SEND_MAIL_OTP: "Send mail OTP",
  COUNTDOWN_TIME_OTP:
    "Countdown time can request OTP code again: {{seconds}} seconds.",
  COUNTDOWN_TIME: "{{seconds}} seconds.",
  SPONSOR_NOT_FOUND: "Your Sponsor not found",
  USERNAME_EXIST: "Your register username already taken",
  HOMEPAGE_BANNER_HEADER: "The most trusted & secure crypto wallet",
  HOMEPAGE_SUB_BANNER_HEADER_1:
    "Storing, trading, digital assets and the simplest way ",
  HOMEPAGE_SUB_BANNER_HEADER_2: "to monitoring Crypto Currencies",
  HOMEPAGE_STRONG_HEADER: "You deserve easy access to Crypto Currencies",
  HOMEPAGE_STRONG_SUB_HEADER: "FAST WALLET advantages",
  HOMEPAGE_STRONG_1: "Storing BTC and Crypto currencies in minutes",
  HOMEPAGE_STRONG_2:
    "Withdrawing, receiving and save Crypto Currencies fast and and simple",
  HOMEPAGE_STRONG_3: "Swapping between crypto currencies without an exchanges",
  HOMEPAGE_STRONG_4: "P2P trading, rapid and comfy",
  HOMEPAGE_STRONG_5: "Make more profit when holding Digital Assets",
  HOMEPAGE_STRONG_6: "Purchasing Crypto Currencies through Bank Account",
  HOMEPAGE_APPLICATION_HEADER: "ANY TIME, ANY WHERE 24/7",
  HOMEPAGE_APPLICATION_SUB_HEADER:
    "FAST WALLET Platform and FAST WALLET official website is the best way to start trading and holding digital assets.",
  INFORMATION: "Information",
  COMMUNITY: "Community",
  TERM_OF_USE: "Term of service",
  SUPPORT: "Support",
  LOGIN: "Login",
  REGISTER: "Register",
  Sponsor_Username: "Sponsor username",
  PASSWORD: "Password",
  REPEAT_PASSWORD: "Repeat password",
  REFERRAL: "Referral",
  CONFIRM: "Confirm",
  EMAIL: "Email",
  ERROR: "Error",
  INVALID_AMOUNT: "Invalid amount",
  PASSWORD_WRONG: "Password are incorrect",
  PLEASE_ENTER_SPONSOR_ID: "Please enter your Sponsor Id",
  PLEASE_ENTER_EMAIL: "Please enter your email",
  PLEASE_ENTER_USERNAME: "Please enter your username",
  PLEASE_ENTER_PASSWORD: "Please enter your password",
  PLEASE_ENTER_REPEAT_PASSWORD: "Please enter your repeat password",
  INVALID_REPEAT_PASSWORD: "Repeated password is incorrect",
  PLEASE_ENTER_REFERRAL: "Please enter your referral",
  CREATE_NEW_ACCOUNT: "Create a new account",
  HAVE_A_ACCOUNT: "Already have an account",
  INVALID_EMAIL: "Email is invalid",
  INVALID_PASSWORD:
    "Password is invalid, password must contain between {{minChar}} and {{maxChar}} characters, uppercase, lowercase, numbers, special characters and no spaces",
  INVALID_USERNAME:
    "The Username field must be {{minChar}} characters long, and not more than {{maxChar}} characters",
  PASSWORD_TOO_SHORT: "Password should be more than 8 character",
  NEW_PASSWORD_TOO_SHORT: "New password should be more than 8 character",
  PASSWORD_TOO_LONG: "Password must be less than 32 characters",
  EMAIL_ALREADY_EXIST: "Your register email already taken",
  REFERRAL_NOT_EXIST: "Referral not exist",
  INVALID_TOKEN: "Token is invalid",
  TOKEN_ALREADY_USED: "Token is already used",
  WRONG_CREDENTIALS: "Email and password are incorrect",
  PLEASE_ENTER_EMAIL_AND_PASSWORD: "Please enter your email and password",
  UNVERIFIED_DEVICE: "Please check email to verify this device",
  OTP_WRONG_OR_USED: "OTP is wrong or already used",
  PLEASE_CHECK_EMAIL_FOR_RESET_PASSWORD:
    "Please check your email for reset password",
  RESET_PASSWORD_SUCCESSFUL: "Reset password successful",
  TOKEN_EXPIRED: "Token is expired",
  PLEASE_ENTER_WALLET_ADDRESS: "Please enter wallet address",
  INVALID_WALLET_ADDRESS: "Wallet address is incorrect",
  PLEASE_ENTER_AMOUNT_WITHDRAW: "Please enter your withdrawal amount",
  WITHDRAW_BELOW_MINIMUM: "Withdraw amount is below minimum withdraw",
  OLD_PASSWORD: "Old password",
  NEW_PASSWORD: "New password",
  CHANGE_PASSWORD: "Change password",
  PLEASER_ENTER_BASE_AMOUNT: "Please enter amount of swap",
  BASE_AMOUNT_GREATER_MIN: "Please enter amount greater than minimum amount",
  BASE_AMOUNT_LESS_MAX: "Please enter amount less than maximum amount",
  INVALID_PARAMETERS: "Parameters is invalid",
  INVALID_OPERATION: "Operation is invalid",
  NOTIFICATION: "Notification",
  SWAP_SUCCESS: "Swap was successful",
  CONFIRM_SUCCESS: "Device confirmation is successful",
  REGISTER_SUCCESS:
    "Register was successful. Please check email for active account",
  RESET_PASSWORD: "Reset password",
  SIGN_IN: "Sign in",
  SIGN_UP: "Sign up",
  ACTIVE_ACCOUNT: "The account has been activated. You can log in now",
  OTP_EXPIRED: "OTP is expired",
  KYC_SUCCESS:
    "Send your identity verification was successful. Please waiting admin confirm.",
  KYC_NOTIFICATION: "This account identity verification is pending or verified",
  WITHDRAW_SUCCESSFUL: "Withdraw success",
  CONFIRM_WITHDRAW_SUCCESS: "Confirm withdraw was successful",
  CANCEL_WITHDRAW: "Withdraw was cancelled",
  WAITING_CONFIRM: "Waiting for confirm email",
  PENDING: "Pending",
  CANCELLED: "Canceled",
  CANCELED: "Canceled",
  EXTERNAL: "Withdraw",
  INTERNAL: "Transfer",
  CANCEL_STAKING_SUCCESS: "Staking was cancelled",
  CREATE_STAKING_SUCCESS: "Create staking was successful",
  EXTERNAL_WITHDRAW_BLOCKED: "External withdraw was blocked",
  USER_WAS_BLOCKED: "User is blocked. Please contact us to support.",
  USER_NOT_ACTIVE: "User is inactive or blocked",
  MISSING_GACODE: "Google authenticator code is missing",
  WRONG_PASSWORD: "Password is wrong",
  COIN_NOT_SUPPORT: "Coin is not support",
  NETWORK_NOT_SUPPORT: "Network is not support",
  DEPOSIT_DISABLE: "Deposit is disabled",
  WITHDRAW_DISABLE: "Withdrawal has been disabled. Please try again later.",
  DISABLE_ACCOUNT_SUCCESS: "This account was disabled",
  PLEASE_ENTER_GA_CODE: "Please enter Google authenticator code",
  WRONG_GACODE: "Google authenticator code is incorrect",
  ENABLE_GA_CODE: "Google authenticator is enabled",
  DISABLE_GA_CODE: "Google authenticator is disabled",
  GACODE_REQUIRED: "Pease enter Google authenticator code",
  CONFIRMED: "Completed",
  PRICE_EXPIRED: "Price is expired",
  PLEASE_ENTER_AMOUNT_MORE_THAN: "Please enter amount greater than",
  UNAVAILABLE_BALANCE: "Unavailable balance",
  PLEASE_ENTER_AMOUNT_FOR_STAKING: "Please enter your staking amount",
  UNKNOWN: "Service unavailable",
  INVALID_ADDRESS: "Wallet address is invalid",
  PLEASE_ENTER_OLD_PASSWORD: "Please enter old password",
  PLEASE_ENTER_NEW_PASSWORD: "Please enter new password ",
  PLEASE_ENTER_CONFIRM_PASSWORD: "Please enter confirm password",
  CONFIRM_NEW_PASSWORD: "Confirm new password",
  CONFIRM_PASSWORD_VS_NEW_PASSWORD: "Confirm password not match new password",
  CHANGE_PASSWORD_SUCCESS: "Change password was successful",
  PORTFOLIO: "Portfolio",
  SWAP: "Swap",
  STAKING: "Staking",
  ACCOUNT: "Account",
  LOGOUT: "Log out",
  FORGOT_PASSWORD: "Forgot password",
  HOMEPAGE: "Home page",
  BACK: "Back",
  PLEASE_ENTER_OTP: "Please enter your OTP from email",
  TOTAL: "Total",
  COIN: "Coin",
  PRICE: "Price",
  BALANCE: "Balance",
  BALANCE_COIN: "Balance {{coin}}",
  VALUE: "Value",
  DEPOSIT: "Deposit",
  TRANSFER: "Transfer",
  WITHDRAW: "Withdraw",
  WITHDRAWAL: "Withdrawal",
  ESTIMATED_BALANCE: "Estimated Balance",
  EXCHANGE: "Exchange",
  AMOUNT: "Amount",
  PLEASE_ENTER_AMOUNT: "Please enter amount",
  MIN: "Min",
  HALF: "Half",
  ALL: "All",
  RECEIVE: "Receive",
  SWAP_HISTORY: "Swap history",
  TIME: "Time",
  BASE_AMOUNT: "Base amount",
  QUOTE_AMOUNT: "Quote amount",
  DURATION: "Duration",
  DAYS: "days",
  MINIMUM_AMOUNT: "Minimum amount",
  HISTORY: "History",
  REDEEMS: "Redeems",
  TYPE: "Type",
  STATUS: "Status",
  STAKE: "Stake",
  LOCK: "Lock",
  MINIMUM_LOCKED_AMOUNT: "Minimum locked Amount",
  MAXIMUM_LOCKED_AMOUNT: "Maximum locked Amount",
  REDEMPTION_PERIOD: "Redemption period",
  LOCK_AMOUNT: "Lock amount",
  CONFIRM_PURCHASE: "Confirm purchase",
  CANCEL: "Cancel",
  GO_TO_DEPOSIT: "Go to deposit",
  NETWORK: "Network",
  ADDRESS: "Address",
  GO_TO_WITHDRAW: "Go to withdraw",
  MINIMUM_WITHDRAW_AMOUNT: "Minimum withdraw amount",
  TRANSACTION_FEE: "Transaction fee",
  YOU_WILL_GET: "You will get",
  PROFILE: "Profile",
  SETTING: "Setting",
  DEVICE_LIST: "Device list",
  LOGIN_ACTIVITY: "Login activity",
  TURN_OFF: "Turn off",
  TURN_ON: "Turn on",
  DISABLE: "Disable",
  IDENTITY_VERIFICATION: "Identity verification",
  ENABLE: "Enable",
  LOGIN_PASSWORD: "Login password",
  CHANGE: "Change",
  INVITED_FRIEND: "Invite Friends, Earn Crypto",
  INVITED_TIP:
    "Use your unique link to invite your friends over message or email. Your default invitation code can also be shared in real life or as a screenshot",
  COPIED: "Copied",
  REFERRAL_NUMBER: "Referral number",
  F1_REFERRAL_NUMBER: "F1 referral number",
  COMMISSION: "Commission",
  YOUR_REFERRAL_ACCOUNT: "Your F1 members",
  YOUR_COMMISSION_HISTORY: "Your commission history",
  AIRDROP: "Airdrop",
  AFFILIATE: "Affiliate",
  PHOTOS: "Photos",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  MIDDLE_NAME: "Middle name",
  DOB: "Date of birth",
  COUNTRY: "Country",
  SELECT_COUNTRY: "Select country",
  CITY: "City",
  POSTAL_CODE: "Postal code",
  NEXT: "Next",
  PREVIOUS: "Previous",
  UPLOAD: "Upload",
  FRONT_PAGE: "Front of card ID/Passport/Driver license",
  BACK_PAGE: "Back of card ID/Passport/Driver license",
  UPLOAD_SELFIE_PHOTO: "Upload selfie photo",
  FULL_NAME: "Full name",
  VERIFY: "Verify",
  LANGUAGE: "Language",
  DRIVER_LICENSE: "Driver license",
  PASSPORT: "Passport",
  USER_NOT_VERIFIED: "Please verify user to make the transaction",
  PLEASE_ENABLE_GA:
    "Please enable Google authenticator to make the transaction",
  REFERRAL_PARENT: "Referral",
  REFERRAL_ID: "Referral ID",
  ID_TYPE: "Choose document type",
  ID_CODE: "Document number",
  ID_CARD: "ID Card",
  IMAGE_CORRECT: "Image is not correct",
  IMAGE_LARGE: "Image too large",
  PRIVACY_AND_POLICY: "Privacy and Policy",
  AGREE_TOS: "I have read and agree to the",
  fastwallet_TOS: "FastWallet's Terms",
  PLEASER_CHECK_TOS: "Please read and agree to the MiningClub Term",
  WISH_YOU_LUCK: "Wish you luck next time",
  CONGRATULATION: "Congratulation",
  PRIZE_VALUE: "Prize value",
  LAST_LOGIN: "Last login time",
  CONTINUE: "Continue",
  INFO_BASIC: "INFO BASIC",
  VERIFY_REASON: "Why verify your identity",
  VERIFY_REASON_1: "To unlock the withdrawal function.",
  VERIFY_REASON_2: "To unlock the internal money transfer function.",
  VERIFY_REASON_3: "To ensure the security of your account.",
  VERIFY_NOTE:
    "This information is used for identity verification only, and will be kept secure by FastWallet",
  SELFIE: "Selfie photo",
  ARE_YOU_SURE: "Are you sure ?",
  GIFT: "Gift",
  NO_RECORDS_FOUND: "No records found",
  MEMBER: "Member",
  EMAIL_NOT_FOUND: "Email not found",
  MINIMUM_SWAP_AMOUNT: "Swap minimum amount",
  EST_APY: "Est. APY",
  GA_CODE: "Google authenticator code",
  GA: "Google authenticator",
  SECURITY: "Security",
  PLEASE_ENTER_YOUR_FULL_INFORMATION: "Please enter your full information",
  COIN_TOKEN: "Coin/Token",
  CHANGE_24H: "24h Change",
  VOLUME: "Volume",
  NOTE_UPLOAD_PHOTO:
    "We are only accept .png, .jpg, .jpeg, or .HEIC and not exceeding 5Mb",
  UPLOAD_PHOTO: "Upload photo",
  BLOCKED: "Blocked",
  LINK: "Link",
  UPLOAD_PHOTO_WITH: "Upload photo with",
  UPLOAD_PHOTO_NOTE: "Attach memo on your card.",
  UPLOAD_PHOTO_NOTE_1:
    "Write a memo including with the follow phrases that KYC Request, email, a Date of request, KYC for FastWallet, and sign.",
  UPLOAD_PHOTO_NOTE_2:
    "If it is not a valid or can't attack a memo, it can be reject.",
  KYC_REQUEST: "KYC request",
  SIGN: "Sign",
  WELL_DONE: "Well done!",
  I_AGREE_TO_THE: "I agree to the",
  AND: "and",
  AGREE_AFTER: "Purpose of Collecting Personal Information",
  KYC_CONFIRM_NOTE:
    "Purpose of Collecting Personal Information: identification, increase Verification level",
  KYC_CONFIRM_NOTE_1:
    "Required personal information: name, date of birth, Nationality, ID card | passport | driver license photo",
  KYC_CONFIRM_NOTE_2:
    "Retention Period: The time set by the Act when the member is withdrawn or until the contact is terminated",
  KYC_CONFIRM_NOTE_3:
    "KYC process will be done manually, so this process may takes time to be completed. 24h is the duration for this process in general, but if the amount of users increase rapidly, it's may takes more time. We really appreciate for your waiting. Please follow your Email to have more result information.",
  VERIFIED: "Verified",
  OPEN: "Open",
  CLOSED: "Closed",
  DEPOSIT_HISTORY: "Deposit history",
  WITHDRAW_HISTORY: "Withdraw history",
  SWAP_CONFIRM: "Swap confirm",
  RATIO: "Ratio",
  SWAP_FEE: "Swap fee",
  DEPOSIT_TIP_0_BEGIN: "Only send",
  DEPOSIT_TIP_0_END: "to this deposit address.",
  DEPOSIT_TIP_1:
    "Sending coins or tokens other than the one you choose to this address may risk losing money.",
  DEPOSIT_TIP_2:
    "If you have made a deposit, please pay attention to the text messages, website notifications and the email we sent you.",
  DEPOSIT_TIP_3: "Coins will be deposited after confirmed by the network.",
  DEPOSIT_TIP_4:
    "We are not responsible for assets for wrong wallet address or network platform when you mistakenly transfer.",
  WITHDRAW_TIP_0:
    "Do not withdraw directly to an ICO address or crowdfunding, because your account will not be credited with tokens from this activity.",
  WITHDRAW_TIP_1:
    "When transferring to a user in the FastWallet platform, the service will be processed at no cost.",
  WITHDRAW_TIP_2:
    "We waived liability for wrong transfer of wallet addresses or different blockchain platforms.  FastWallet does not yet support cross-chain.",
  SWAP_TIP_0:
    "The final price and amount is determined by the amount of tokens available in the pool at the time of your swap.",
  SWAP_TIP_1:
    "Swap transactions are only executed when the price slippage is within the allowed range.",
  SWAP_TIP_2:
    "Adding or withdrawing coins will not be restricted, but will depend on the number of tokens in the pool.",
  SWAP_TIP_3:
    "Swap is just a form of conversion between different asset classes but is still based on the market price and the amount of tokens in the pool.  FastWallet disclaims liability when the token slips price during swaps.",
  CHOOSE_NETWORK: "Choose network",
  WITHDRAW_CONFIRM: "Withdraw confirm",
  WALLET_ADDRESS: "Wallet address",
  WITHDRAW_FEE: "Withdraw fee",
  STAKING_CONFIRM: "Staking confirm",
  AGREE_TO: "I have read and agree to the",
  STAKING_POLICY: "Staking policy",
  PLEASER_CHECK_STAKING_POLICY:
    "Please read and agree to the FastWallet Term and Staking Policy",
  STAKING_POLICY_0:
    "Annual rate of return based on estimated historical market sales. Actual revenue depends on actual receipt.",
  STAKING_POLICY_1: "The minimum staking amount is 100 mUSD.",
  STAKING_POLICY_2:
    "During staking, the staking asset cannot be traded on the liquid market, and cannot be unlocked directly (Except for the Flexible Staking Package).",
  STAKING_POLICY_3:
    "When the length of your staking period meets the staking interval requirement, the staking package will be canceled automatically, the revenue and the staking principal amount will be transferred to your storage wallet.",
  STAKING_POLICY_4:
    "The staking amount will be refunded after 1-2 working days after your chosen deadline, the staking reward with the mined POL is paid interest on a daily basis",
  STAKING_POLICY_5:
    "Staking policy is not changed, but FastWallet is not responsible for property slippage in the market while customers are staking.",
  STAKING_POLICY_6:
    "Annual interest rate (APY), only to help members better understand the value of the profit.",
  STAKING_TIME: "Staking time",
  STAKING_START_DATE: "Staking start date",
  STAKING_END_DATE: "Staking end date",
  ESTIMATED_APY: "Estimated APY",
  START_TIME: "Start time",
  END_TIME: "End time",
  LOCKED: "Locked",
  YOUR_TOTAL_AFFILIATE: "Total affiliate",
  YOUR_TOTAL_AFFILIATE_F1: "Total affiliate 1",
  YOUR_AFFILIATE_F1: "Your affiliate 1",
  MAXIMUM: "Maximum",
  MINIMUM: "Minimum",
  USE_MAX_AMOUNT: "Use max amount",
  STAKING_TIP_0: "Make cryptocurrency anytime with FastWallet Staking",
  STAKING_TIP_1:
    "Generate passive income with FastWallet Staking by keeping cryptocurrencies in your wallet. Select the crypto you want to keep from FastWallet's cryptocurrency (mUSD). Take your crypto investment potential to new level and enjoy low fees with FastWallet.",
  DATE_REQUIRE: "Date require",
  KYC_FOR: "KYC for FastWallet",
  DOB_INVALID: "Invalid birthday",
  WITHDRAW_AMOUNT_TOO_SMALL: "Withdraw amount is too low",
  DISABLE_ACCOUNT: "Disable account",
  CLOSE: "Close",
  SCAN_QR_CODE: "Scan the QR code of the wallet address",
  DISABLE_ACCOUNT_NOTE:
    "This action will disable your FastWallet account. Please contact to FastWallet support to reactivate your account.",
  PLEASE_ENTER_PASSWORD_MORE_THAN:
    "Please enter a password more than {{number}} characters",
  PLEASE_ENTER_PASSWORD_LESS_THAN:
    "Please enter a password less than {{number}} characters",
  PLEASE_ENTER_NEW_PASSWORD_MORE_THAN:
    "Please enter a new password more than {{number}} characters",
  PLEASE_ENTER_NEW_PASSWORD_LESS_THAN:
    "Please enter a new password less than {{number}} characters",
  FAILED: "Failed",
  SEARCH_COIN_TOKEN: "Search Coin/Token",
  SP_ACCOUNT_TITLE: "Support FastWallet.io account login.",
  SP_ACCOUNT:
    "FastWallet.org support log in with your FastWallet.io account. With your authorization, you can log in both FastWallet.io exchange and your FastWallet.org with one account and enjoy a one-stop solution from storage, transfer to exchange.",
  EASY_BUY: "Easy buy",
  YOU_HAVE: "You have",
  BUY: "Buy",
  AVAILABLE_BALANCE: "Available balance",
  COMPLETED: "Completed",
  SESSION_SUPPLY: "Session supply",
  SOLD: "Sold",
  BY: "by",
  START_DATE: "Start date",
  END_DATE: "End date",
  WEBSITE: "Website",
  WHITEPAPER: "Whitepaper",
  BUY_TOKEN_SUCCESS: "Buy token successful",
  FEE: "Fee",
  BUY_TOKEN_CONFIRM: "Buy token confirm",
  PROJECT_INTRODUCTION: "Project introduction",
  EASY_BUY_SLOGAN: `Easy exchange with "Easy-Buy"`,
  EASY_BUY_SUB_SLOGAN:
    "Support instant exchange of popular cryptocurrencies around the world",
  FAST: "Fast",
  EFFICIENT: "Efficient",
  ZERO_CHANGES: "0 Changes",
  USER_OVERVIEW_MEMBER: "Members of the FastWallet",
  USER_OVERVIEW_ASSETS: "Variety Digital Assets/Crypto management",
  USER_OVERVIEW_SOCIAL: "Social media followers",
  FastWallet_KYC_NOTE:
    "using this KYC document for FastWallet and also FastWallet.",
  YOU_PAY: "You pay",
  OPTION: "Option",
  MEMO_NOTE:
    "Please confirm if the receiving address requires a MEMO/ Tag. If it is not filled or filled incorrectly, the asset will be lost. Other exchanges or wallets also call Tag names Memo, digital ID, label, and notes.",
  TRANSACTION_HASH: "Transaction hash",
  EXCHANGE_DETAIL: "Exchange detail",
  TO: "to",
  FROM: "from",
  SEND: "Send",
  HOURS_AGO: "hours ago",
  MINUTES_AGO: "minutes ago",
  TRANSACTION_HISTORY: "Transaction history",
  PAY: "Pay",
  GET: "Get",
  STAKING_REFERRAL: "Commission",
  PRESALE_PRODUCT_OUT_OF_SUPPLY:
    "The purchase amount has exceeded the supply. Please try again.",
  PRESALE_PRODUCT_NOT_OPEN: "Coin/Token has not been opened for sale.",
  PRESALE_PRODUCT_ENDED: "Coin/Token has timed out to purchase",
  EASY_BUY_HISTORY: "Easy buy history",
  SELECT_COIN: "Select Coin/Token",
  COMING_SOON: "Upcoming",
  SUMMARY: "Summary",
  LOCK_AMOUNT_LIMITATION: "Lock amount limitation",
  VALUE_DATE: "Value date",
  INTEREST_PERIOD: "Interest period",
  INTEREST_END_DATE: "Interest end date",
  REDEMPTION_DATE: "Redemption date",
  ESTIMATE_INTEREST: "Estimate interest",
  INSUFFICIENT_FUNDS: "Unavailable balance",
  AMOUNT_TOO_LARGE: "Your amount of staking is too large",
  AMOUNT_TOO_LOW: "Your amount of staking is too low",
  STAKING_PRODUCT_NOT_OPEN: "Staking product is not open",
  STAKING_PRODUCT_ENDED: "Staking product is ended",
  PLEASE_TRY_AGAIN: "Please try again",
  STAKING_PRODUCT_OUT_OF_SUPPLY: "Your staking amount out of remain",
  REDEEM: "Redeem",
  STAKING_SUCCESS: "Staking success",
  PROFIT: "Profit",
  YEAR: "year",
  MONTH: "month",
  CONFIRM_STAKING: "Confirming for staking",
  PARTNER: "Partner",
  DISPLAY_NAME: "Display name",
  UPDATE: "Update",
  ITEM_ALREADY_EXIST: "Display name was existed",
  PLEASE_ENTER_YOUR_DISPLAY_NAME: "Please enter your display name",
  DISPLAY_NAME_WAS_UPDATED: "Display name was updated",
  MARCH_PROMOTION: "Promotion March",
  APRIL_REWARD: "April reward",
  ORDER_DETAIL: "Order detail",
  RECEIVER_ADDRESS: "Receiver's address",
  PHONE: "Phone",
  CHECKOUT_METHOD: "Checkout method",
  CHECKOUT_BY_LUS: "Payment by LuS on FastWallet (Free of charge).",
  PAYMENT_SUCCESS: "Payment success",
  DISCOUNT: "Discount",
  PRODUCT: "Product",
  SUBTOTAL: "Subtotal",
  BACK_TO_ORDER: "Back to my orders",
  MY_ORDERS: "My orders",
  PRODUCTS: "Products",
  SAVE: "Save",
  AVAILABLE: "Available",
  BUY_NOW: "Buy now",
  PRODUCT_DETAIL: "Product detail",
  LEARN_MORE: "learn more",
  SHOPPING_PRICE_NOTE:
    "Price is inclusive of duties and taxes for all US bound orders",
  SHOPPING_NOTE_1: "Verified & authenticated by our experts",
  SHOPPING_NOTE_2: "Delivery time : 2-4 business days",
  SHOPPING_NOTE_3: "30 days easy return",
  ORDER: "Order",
  SHIPPING_INFORMATION: "Shipping information",
  CHOSE_ADDRESS: "Chose address",
  ADD_NEW_ADDRESS: "Add new address",
  CHECKOUT: "Checkout",
  CONFIRM_INFORMATION: "Confirm information",
  SWAP_FROM: "From",
  CREATE_ORDER_DATE: "Created order date",
  BACK_TO_fastwallet: "Back to FastWallet",
  NAME: "Name",
  NEW: "New",
  SHIPPING: "Shipping",
  INPROGRESS: "In progress",
  CANCEL_ORDER: "Cancel order",
  ARE_YOU_SURE_CANCEL_ORDER: "Are you sure you want cancel this order ?",
  ORDER_ID: "Order ID",
  SELECT_A_TOKEN: "Select a token",
  CANCEL_ORDER_SUCCESS: "Cancel order success",
  EDIT_PROFILE: "Edit profile",
  EDIT_NICKNAME_NOTE:
    "We accept names from 3 to 32 characters in length, without special characters and does not begin with a number.",
  PLEASE_TELL_US_YOUR_NICKNAME: "Please tell us your nickname",
  YOUR_NICKNAME: "Your nickname",
  PLEASE_CHECK_NICKNAME_AGAIN: "Please check your nickname again.",
  PLEASE_SELECT_COUNTRY: "Please select your country",
  UPDATE_COUNTRY_SUCCESS: "Update country success",
  UPDATE_NICKNAME_SUCCESS: "Update nickname success",
  CONFIRM_CANCEL_ORDER_ADDRESS: "Confirming for cancel order address",
  SHOPPING_ORDER_OUT_OF_STOCK: "Your order products out of remain",
  SOLD_OUT: "Sold out",
  RANKING_BONUS: "Bonus",
  UNBLOCK: "Unlock",
  VERIFICATION: "Verification",
  CODE_WILL_BE_SENT_TO: "Code will be sent to",
  EMAIL_VERIFICATION_CODE: "Email verification code",
  ENTER_GA_CODE: "Enter Google verification code",
  SUBMIT: "Submit",
  SEND_CODE: "Send code",
  SP_INTERNAL: "Internal transfer",
  OR: "or",
  INTERNAL_WITHDRAW: "Internal withdraw",
  EXTERNAL_WITHDRAW: "External withdraw",
  Referral_Link: "Referral link",
  Front_ID_card: "Front ID card",
  Back_ID_card: "Back ID card",
  Address_verify: "Address verify",
  CREATE_MEMBER_SUCCESS: "Create member success",
  ACCOUNT_EXIST: "Account exist",



  //fast wallet
  ASSETS: "Assets",
  MY_PROFILE: "My profile",
  MY_AFFILIATE: "My affiliate",
  AIRDROP_HISTORY: "Airdrop History",
  COMMISSION_HISTORY: "Commission History",
  GIFT_HISTORY: "Receive Gift History",
  STAKING_COMMISSION: "Staking Commission",
  SHOW_MORE: "Show more",
  SHOW_LESS: "Show less",
  WITHDRAW_TO: "Withdraw to",
  WITHDRAW_AMOUNT: "Withdraw amount",
  RECEIVE_AMOUNT: "Receive amount",
  WALLET: "Wallet",
  LOGIN_FOR: "Login for",
  BACK_TO_HOMEPAGE: "Back to homepage",
  FORGOT_PASSWORD_SUCCESS: "Please check your email to reset your password.",
  EMAIL_ADDRESS: "Email address",
  PlansMining: "Plans mining",
  //fast wallet

  //mining
  LEFT: "Left",
  RIGHT: "Right",
  PACKAGE: "Package",
  NO_RANK: "Membership",
  STARTER: "Starter",
  QUARTER: "Quarter",
  MAIN: "Main",
  SPECIAL: "Special",
  RANK: "Rank",
  DIRECT_COMMISSION: "Direct commission",
  BINARY_COMMISSION: "Binary commission",
  MINE: "Mine",
  MATCHING: "Matching",
  BLOCK: "Block",
  MINE_TIME: "Mine time",
  UNBLOCK_TIME: "Unlock time",
  SPONSOR: "Sponsor",
  DETAIL: "Detail",
  CONFIRMING_FOR_BUY_PLAN: "Confirming for purchase package",
  MAX_BINARY_COMMISSION: "Max binary commission",
  PRIMARY: "Primary",
  LAST_MINED: "Last mined",
  TOTAL_MINED: "Total mined",
  NEXT_PAYOUT: "Next payout",
  RANK_INFO: "Rank info",
  ACHIEVEMENT: "Achievement",
  MY_PACKAGE: "My package",
  PAYOUT_FROM_REFERRAL_PLAN: "Next payout",
  GENERAL_BALANCE: "General balance",
  ACHIEVEMENTS: "Achievements",
  MAINTAIN: "Protected rank",
  MONTHLY: "1 month",
  QUARTERLY: "3 month",
  MEMBERS: "Members",
  TRANSACTIONS: "Transactions",
  MATCHING_COMMISSION: "Matching commission",
  GO_TO_DASHBOARD: "Go to dashboard",
  MINED_BALANCE: "Mined",
  RELEASED: "Released",
  BLOCKING: "Blocking",
  SELECT_ASSET: "Select asset",
  YOU_HAVE_SUCCESSFULLY_PURCHASED_THE: "You have successfully purchased the",
  PURCHASE_NOW: "Purchase now",
  MY: "My",
  SELECT_RANK: "Select rank",
  SELECT_PACKAGE: "Select package",
  SUPPORT_CENTER: "Support center",
  ANNOUNCEMENTS: "Announcements",
  KNOWLEDGE: "Knowledge",
  MINING_BALANCE: "Mining balance",
  MINING_BALANCE_COIN: "Mining balance {{coin}}",
  CREATED_TIME: "Created time",
  MINING_HISTORY: "Mining history",
  FREE: "Free",
  PAYOUT: "Payout",
  ADD_BRANCH_DETAIL: "Add branch detail",
  POSITION: "Position",
  NO_FREE_MEMBERS: "No free members",
  PAYMENT: "Payment",
  ASSET: "Asset",
  YOU_ARE_NOT_IN_BINARY_TREE:
    "You are not in binary tree. Please contact your sponsor to be added.",
  YOUR_SPONSOR: "Your sponsor",
  TRANSFER_WITHDRAW: "Transfer / Withdraw",
  HOME: "Home",
  WHY_MINING: "Why mining",
  ABOUT_US: "About us",
  HOW_TO: "How to?",
  WHAT_IS_FIL_COIN: "What is Filecoin",
  SEND_OTP: "Send OTP",
  TRANSFER_HISTORY: "Transfer history",
  TRANSFER_DISABLE: "Transfer has been disabled. Please try again later.",
  YOUR_AMOUNT_TOO_LOW: "Your amount too low. Minimum: {{num}} {{coin}}",
  WHAT_IS_MINING: "What is mining?",
  MEDIA: "Media",
  FAQ: "FAQ",
  Enroll_new_member: "Enroll new member",
  SignIn_Already_have_an_account: "Already have an account? Sign in",
  CheckBox_PrivacyPolicy: "I agree with provisions of the [[0]] | [[1]].",
  Terms_and_Conditions: "Terms and Conditions",
  Privacy_Policy: "Privacy Policy",


  Lasts_for_days: "Lasts for {{number}} days",
  Start_after_days: "Start after {{number}} days",
  Days_of_mining: "{{number}} Days of mining",
  No_profit_from_mining: "No profit from mining",
  Reservation: "Reservation",

  Registered_successfully: "Registered successfully",
  ACCOUNT_EXPIRED: "Account expired",
  POWER: "Power",











  home_banner_text:
    "We are mining the next-generation internet protocol, Filecoin. We build highly efficient crypto currency mining environment and provide a streamlined system for you.",
  introHome_title_1: "Your hardware is already running",
  introHome_title_2: "Mine alternative cryptocurrencies",
  introHome_title_3: "Get your first mining output today",
  introHome_text_1:
    "Don’t wrestle with rig assembly and hot, noisy miners at home. we have the fastest filecoin mining hardware running for you already!",
  introHome_text_2:
    "You can mine any crypto currency available in our catalogue! switch your mining power on the fly for all the coins using our cloud mining website.",
  introHome_text_3:
    "You will get periodic mining outputs to your designated wallet. try our cloud mining platform now!",
  why_mining_club: "Why MINING CLUB?",
  why_mining_title: "We are making mining accessible to everyone",
  why_mining_text:
    "We are uniting all key aspects of running an efficient crypto currency mining operation. from building highly efficient data centers to providing a streamlined mining system for our users.",
  why_mining_title_card_1: "We make mining competitive",
  why_mining_title_card_2: "A vibrant mining community supporting each other",
  why_mining_title_card_3: "Protect data while protecting the planet",
  why_mining_text_card_1:
    "To mine effectively today, you need to invest significant resources, time and effort into your home mining setup. Our Mining Club platform has connected with the most efficient mining systems to do the work for you. this way you can fully focus on monitoring the market and stay competitive with your mining rewards.",
  why_mining_text_card_2:
    "From small mining rigs to advanced data-center operations, Filecoin has a vibrant mining community of dedicated entrepreneurs and businesses helping each other make their operations more efficient, reliable, and profitable.",
  why_mining_text_card_3:
    "Filecoin relies on useful storage to secure the network, not many other blockchains use wasted proof of work. This makes Filecoin one of the first eco-friendly blockchains.",
  features_title: "Mining Club Features",
  features_card_title_1: "Cloud mining",
  features_card_title_2: "Filecoin mining",
  features_card_title_3: "Secure & private",
  features_card_title_4: "Competitive mining technology",
  features_card_title_5: "Visual dashboard",
  features_card_title_6: "Daily mining distribution",
  features_card_text_1:
    "Is the process of mining cryptocurrencies using a remote data center where customers simply register and purchase mining contracts with shared processing power. This type of cloud mining allows users to mine cryptocurrencies without managing hardware.",
  features_card_text_2:
    "The Filecoin network achieves staggering economies of scale by allowing anyone to participate as a storage provider and monetize their open hard drive space.",
  features_card_text_3:
    "Mining club will mine crypto currency for you with a globally competitive rate and at maximum efficiency, ensuring that you receive the most competitive mining rewards possible!",
  features_card_text_4:
    "Use highly efficient mining equipment professionally designed for crypto currency mining",
  features_card_text_5:
    "All the crypto mining data and charts you need, you can easily monitor your mining portfolio every day.",
  features_card_text_6:
    "Automatically distribute mining results to your account every day and can withdraw onchain at any time.",
  tutorial_title: "How Too Join The Mining Club Community In Four Easy Steps",
  tutorial_card_title_1: "Create An Account",
  tutorial_card_title_2: "Buy A Mining Plan",
  tutorial_card_title_3: "Let Us Mine Crypto For You",
  tutorial_card_title_4: "Earn Crypto And Build Your Future",
  tutorial_card_text_1:
    "Sign up by simply providing your referrer's username, and then entering your desired username and contact details! it's that easy",
  tutorial_card_text_2:
    "Choose the most suitable mining plan for you with no membership fees or hidden costs! what are you waiting for? let us help you join the crypto currency world!",
  tutorial_card_text_3:
    "Mining Club will mine crypto currency for you with a globally competitive rate and at maximum efficiency, ensuring that you receive the most competitive mining rewards possible!",
  tutorial_card_text_4:
    "We distribute mining rewards as well as referral rewards on a regular basis. check your mining potential based on your mining plan!",
  //filecoinpage
  banner_title: "use filecoin to store and retrieve data",
  banner_text:
    "a powerful and dynamic distributed cloud storage network for your data.",
  decentralize_title: "the decentralized cloud is here",
  decentralize_card_title_1: "a superior network",
  decentralize_card_title_2: "store at hypercompetitive prices",
  decentralize_card_title_3: "provable security and authenticity",
  decentralize_card_text_1:
    "Filecoin combines with IPFS, a peer-to-peer distributed network protocol, to make a more efficient and secure web free from corporate control.",
  decentralize_card_text_2:
    "The Filecoin network achieves staggering economies of scale by allowing anyone to participate as a storage provider and compete for your business.",
  decentralize_card_text_3:
    "Content addressing and cryptographic storage proofs verify data is being stored correctly and securely over time.",
  flexible_title: "flexibility and scale without compromise",
  flexible_text:
    "the filecoin network is made up of a large number of diverse storage providers and developers. This creates a robust and reliable service.",
  flexible_card_title_1: "global open-source storage",
  flexible_card_title_2: "customized to your specific needs",
  flexible_card_title_3: "a developer's playground",
  flexible_card_text_1: "a network that’s built and owned by everyone.",
  flexible_card_text_2:
    "tune strategies to suit your needs in redundancy, retrieval speed, and cost.",
  flexible_card_text_3:
    "a rapidly growing ecosystem of tools, libraries, integrations, and providers.",
  sectors_title: "data stored reliably and securely",
  sectors_text:
    "cryptographic proofs guarantee your data remains available and unchanged over time",
  sectors_card_title_1: "proof-of-SpaceTime",
  sectors_card_title_2: "proof-of-Replication",
  sectors_card_title_3: "verifiable action",
  sectors_card_text_1:
    "verifies the entirety of a file is being stored, unaltered, over the agreed-upon duration.",
  sectors_card_text_2:
    "verifies the agreed-upon number of copies of a file are being stored.",
  sectors_card_text_3:
    "the global Filecoin blockchain verifies proofs and automatically takes corrective action when needed.",
  reliable_title: "supply and demand, not corporate pricing departments",
  reliable_text_1: "a hypercompetitive marketplace for storage",
  reliable_text_2:
    "filecoin reduces the barriers to entry for storage providers and turns cloud storage into a commodity. This allows new entrants to easily compete with entrenched players.",
  reliable_text_3:
    "storage providers compete vigorously for your business, to store your data, and provide flexible options at the best prices.",
  reliable_text_4: "and it’s all done on a transparent, global marketplace.",
  reliable_text_5: "a storage market visible and fair to everyone.",
  reliable_text_6:
    "Filecoin creates an incentivized market to put extra storage to work. With significantly more supply, we meet growing demand while lowering the price of storage.",
  ipfs_title: "The missing incentive layer for IPFS",
  ipfs_text:
    "Filecoin adds incentivized, persistent storage to IPFS. IPFS users are able to reliably store their data on Filecoin right from the IPFS network — opening the network up to a world of applications and use-cases.",
  community_title: "Filecoin Community",
  community_text:
    "The Filecoin network is a large community of developers, miners and users all coming together to collaborate and build something greater than themselves.",

  title_footer_1: "Follow us",
  title_footer_2: "Terms & Policy",
  title_footer_3: "Knowledge",
  item_footer_1: "Stay up date with Mining Club activities.",
  item_footer_2: "Terms of Services",
  item_footer_3: "Privacy Policy",
  item_footer_4: "What is mining?",
  item_footer_5: "What is Filecoin?",
  text_footer:
    "The information on this website does not convey an offer of any type and is not intended to be, and should not be construed as, an offer to sell, or the solicitation of an offer to buy, any securities, commodities, or other financial products. In addition, the information on this website does not constitute the provision of investment advice.No assurances can be made that any aims, assumptions, expectations, strategies, and/or goals expressed or implied herein were or will be realized or that the activities or any performance described did or will continue at all or in the same manner as is described on this website.",

  blog_title: "Blog filecoin",
  blog_read_more_button: "Read more",

  partner_title:
    "We work with a community of people and organizations building a better future through technology.",

  menu_header_link_1:
    "https://knowledge.miningclub.org/what-is-mining/what-is-mining",
  menu_header_link_2:
    "https://knowledge.miningclub.org/what-is-filecoin/what-is-filecoin",
  menu_footer_link_1:
    "https://knowledge.miningclub.org/what-is-mining/what-is-mining",
  menu_footer_link_2:
    "https://knowledge.miningclub.org/what-is-filecoin/what-is-filecoin",

  // identity verification
  PLEASE_ENTER_FIRST_NAME: `Please enter your first name`,
  PLEASE_ENTER_LAST_NAME: `Please enter your last name`,
  PLEASE_ENTER_DOB: `Please enter your date of birth`,
  PLEASE_CHOOSE_DOCUMENT_TYPE: "Please choose document type",
  PLEASE_ENTER_DOCUMENT_NUMBER: `Please enter your document number`,
  PLEASE_ENTER_COUNTRY: `Please enter choose your country`,
  PLEASE_ENTER_CITY: `Please enter your city`,
  PLEASE_ENTER_POSTAL_CODE: `Please enter your postal code`,
  PLEASE_ENTER_YOUR_ADDRESS: `Please enter your address`,
  PERSONAL_INFORMATION: `Personal information`,
  PERSONAL_DOCUMENTS: `Personal documents`,
  PROMOTION: "Promotion",
  YOU_HAVE_SUCCESSFULLY_REQUEST_BUY: "You have successfully requested to buy",

  db_left_menu_link_1: "https://support.miningclub.org",
  db_left_menu_link_2: "https://faq.miningclub.org",
  db_left_menu_link_3: "https://announcements.miningclub.org",
  db_left_menu_link_4: "https://knowledge.miningclub.org",
  db_left_menu_link_5: "https://filecoin-news.miningclub.org",

  Filecoin_News: "Filecoin news",

  NO_PACKAGE: "No package",
  VERIFY_ACCOUNT: "Verify account",

  db_slide_banner_1: "https://support.miningclub.org/buy-plans/member-reservation-150usdt",
  db_slide_banner_2: "/",
  db_slide_banner_3: "https://announcements.miningclub.org/new-activities/welcome-launch-promotion",
  db_slide_banner_4: "https://announcements.miningclub.org/new-activities/welcome-launch-promotion",

  verify_tab_description_1: "Enter personal information",
  verify_tab_description_2: "ID/Passport/Driver license",
  verify_tab_description_3: "Take a selfie",
  verify_tab_description_4: "Check the information again",
  verify_tab_menuItem_1: "Information",
  verify_tab_menuItem_2: "Front photo",
  verify_tab_menuItem_3: "Back photo",
  verify_tab_menuItem_4: "Selfie photo",

  HI_USERNAME: `Hi {{username}},`,
  WARNING_WITHDRAW: `You need verify your account before withdraw.`,
  TOP: "Top",

  Dark_Mode: "Dark Mode",
  Light_Mode: "Light mode",
  Purple_Mode: "Purple mode",
  Orange_Mode: "Orange mode",

  SWAP_CPU_BELOW_MINIMUM: "Swap CPU below minimum {{num}} CPU",

  YOU_NEED_ENABLE_GA: "You need enable google authenticator",
  JUST_TRANSFER_100_DOLLAR_WHEN_NO_VERIFICATION: "Just transfer $100 when no verification",
  USE_TRANSFER_SERVICE: "Use transfer service",
  USE_WITHDRAW_SERVER_YOU_NEED: "Use withdraw server you need",
  ENABLE_GOOGLE_AUTHENTICATION: "Enable google authentication",

  I_send: "I send",
  I_receive: "I receive",
  ENTER_AMOUNT: "Enter amount",
  USER_NO_PACKAGE: "User no package",
  TRANSFER_LIMIT: "Transfer limit",
  PURCHASE: "Purchase",


};
