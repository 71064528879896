import React, { useState } from "react";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import { isMobileApp } from "../../../utils/auth";
import { CustomToast, S3_URL } from "../../../settings";
import Countdown from "../../common/Countdown";
import { getLanguage } from "../../../languages/getLanguage";
import { NO_PACKAGE, ranks } from "../../../constants";
import {
  formatTextShorten,
  formatAddress,
} from "../../../settings/format";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  Paper,
  Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import NewMember from "../member-page/NewMember";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FileCopyIcon from "@material-ui/icons/FileCopy";

function HomeInfo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { user } = useSelector((state) => state);
  const { information } = user;
  const [showNewMember, setShowNewMember] = useState(false);

  const referralLink = `${window.location.origin.toString()}/sign-up/${information && information.username}`;

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2} className="pb-2 pt-2">
          <Grid item xs={isMobileApp() ? 6 : "auto"}>
            <Typography variant="body2">
              {
                isMobileApp() ?
                  formatTextShorten(getLanguage("INVITED_FRIEND")) :
                  getLanguage("INVITED_FRIEND")
              }
            </Typography>
            <Button
              size={isMobileApp() ? "small" : "medium"}
              variant="outlined"
              color="primary"
              fullWidth={isMobileApp() ? true : false}
              startIcon={<PersonAddIcon />}
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => setShowNewMember(true)}
              className="mt-2"
            >
              {
                isMobileApp() ?
                  formatTextShorten(getLanguage("Enroll_new_member")) :
                  getLanguage("Enroll_new_member")
              }
            </Button>
            <Dialog
              fullScreen={fullScreen}
              open={showNewMember}
              onClose={() => setShowNewMember(false)}
              scroll="body"
            >
              <NewMember
                user={user}
                open={showNewMember}
                close={() => setShowNewMember(false)}
              />
            </Dialog>
          </Grid>
          <Grid item xs={isMobileApp() ? 6 : "auto"}>
            <Typography variant="body2">{getLanguage("Referral_Link")}</Typography>
            <Button
              size={isMobileApp() ? "small" : "medium"}
              fullWidth={isMobileApp() ? true : fullScreen}
              onClick={() => {
                navigator.clipboard.writeText(referralLink);
                CustomToast("success", getLanguage("COPIED"));
              }}
              style={{
                textTransform: "lowercase",
              }}
              variant="outlined"
              color="primary"
              endIcon={<FileCopyIcon />}
              className="mt-2"
            >
              {
                isMobileApp() ?
                  formatTextShorten(referralLink, 15) :
                  formatAddress(referralLink, 20)
              }
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {
        (isMobileApp() || isMobile) ?
          <Grid xs={12} item>
            <Paper className={isMobileApp() ? "p-2" : "p-3"} variant="outlined">
              <Grid container>
                <Grid item xs={6} className="text-center border-right">
                  <div className="opacity-06">{getLanguage("RANK")}</div>
                  <img
                    src={
                      information && ranks[information.rank]
                        ? `${S3_URL}${ranks[information.rank].image}`
                        : `${S3_URL}${ranks["PRO"].image}`
                    }
                    className="mr-auto ml-auto mt-2 mb-2"
                    style={{
                      width: 70,
                      height: 70,
                      filter: `grayscale(${information && ranks[information.rank] ? 0 : 100
                        }%)`,
                    }}
                    alt=""
                  />
                  <div>{information && getLanguage(information.rank)}</div>
                </Grid>
                <Grid item xs={6} className="text-center">
                  <div className="opacity-06">{getLanguage("MY_PACKAGE")}</div>
                  {information && information.package !== NO_PACKAGE ? (
                    <>
                      <Avatar
                        src={
                          information &&
                          `${S3_URL}packages/${information.package}_2.png`
                        }
                        className="mr-auto ml-auto mt-2 mb-2"
                        style={{
                          width: 70,
                          height: 70,
                        }}
                      />
                      <div>
                        {information &&
                          getLanguage(information.package).toLocaleUpperCase()}
                      </div>
                    </>
                  ) : (
                    <>
                      <br />
                      <Link to={isMobileApp() ? "/mobile-app/dashboard/plans/buy" : "/dashboard/plans/buy"}>
                        <Button variant="contained" color="primary">
                          {getLanguage("PURCHASE_NOW")}
                        </Button>
                      </Link>
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          :
          <>
            <Grid xs={12} md={6} item>
              <Paper className={isMobileApp() ? "p-2" : "p-3"} variant="outlined">
                <Grid container>
                  <Grid item xs={5} md={6} className="text-center border-right">
                    <div className="opacity-06">{getLanguage("RANK")}</div>
                    <img
                      src={
                        information && ranks[information.rank]
                          ? `${S3_URL}${ranks[information.rank].image}`
                          : `${S3_URL}${ranks["PRO"].image}`
                      }
                      className="mr-auto ml-auto mt-2 mb-2"
                      style={{
                        width: 70,
                        height: 70,
                        filter: `grayscale(${information && ranks[information.rank] ? 0 : 100
                          }%)`,
                      }}
                      alt=""
                    />
                    <div>{information && getLanguage(information.rank)}</div>
                  </Grid>
                  <Grid item xs={7} md={6} className="text-center">
                    <div className="">
                      <div className="opacity-06">{getLanguage("RANK_INFO")}</div>
                      <br />
                      <div>
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/achievement" : "/dashboard/achievement"}>
                          <Button variant="contained" color="primary">
                            {getLanguage("ACHIEVEMENT")}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid xs={12} md={6} item>
              <Paper variant="outlined" className={isMobileApp() ? "p-2" : "p-3"} >
                <Grid container>
                  <Grid item xs={5} md={6} className="text-center border-right">
                    <div className="opacity-06">{getLanguage("MY_PACKAGE")}</div>
                    {information && information.package !== NO_PACKAGE ? (
                      <>
                        <Avatar
                          src={
                            information &&
                            `${S3_URL}packages/${information.package}_2.png`
                          }
                          className="mr-auto ml-auto mt-2 mb-2"
                          style={{
                            width: 70,
                            height: 70,
                          }}
                        />
                        <div>
                          {information &&
                            getLanguage(information.package).toLocaleUpperCase()}
                        </div>
                      </>
                    ) : (
                      <>
                        <br />
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/plans/buy" : "/dashboard/plans/buy"}>
                          <Button variant="contained" color="primary">
                            {getLanguage("PURCHASE_NOW")}
                          </Button>
                        </Link>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={7} md={6} className="text-center">
                    <div className="opacity-06">
                      {getLanguage("PAYOUT_FROM_REFERRAL_PLAN")}
                    </div>
                    <br />
                    <div>
                      <Button variant="outlined" color="primary" size="small">
                        <Countdown />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
      }
    </>
  );
}
export default HomeInfo;
