import {
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  useMediaQuery,
  Dialog,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _getF1Member, _getPackageList } from "../../../actions/miningActions";
import { getLanguage } from "../../../languages/getLanguage";
import SearchIcon from "@material-ui/icons/Search";
import "./style.scss";
import { formatUSD } from "../../../settings/format";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useTheme } from "@material-ui/core/styles";
import NewMember from "./NewMember";
import AddTree from "./AddTree";
import { isMobileApp } from "../../../utils/auth";

function Index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { mining } = useSelector((state) => state);
  const { user } = useSelector((state) => state);
  const { member, packageList } = mining;
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [username, setUsername] = useState("");
  const [checked, setChecked] = useState(false);
  const [rank, setRank] = useState("");
  const [searchPackage, setSearchPackage] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showNewMember, setShowNewMember] = useState(false);
  const [selectUser, setSelectedUser] = useState(null);
  const [isReload, setIsReload] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (checked) {
      dispatch(
        _getF1Member(page, pageSize, {
          isInBinaryTree: false,
          username,
          rank,
          package: searchPackage,
        })
      );
    } else {
      dispatch(
        _getF1Member(page, pageSize, { username, rank, package: searchPackage })
      );
    }
  }, [
    dispatch,
    checked,
    page,
    pageSize,
    username,
    rank,
    searchPackage,
    isReload,
  ]);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const _handleSearchUsername = (e) => {
    e.preventDefault();
    setUsername(e.target.inputUsername.value);
    setPage(1);
  };

  const _handleCheck = (e) => {
    setChecked(e.target.checked);
    setPage(1);
  };

  useEffect(() => dispatch(_getPackageList()), [dispatch]);

  const _resetFilter = () => {
    document.getElementById("filer-form").reset();
    setUsername("");
    setRank("");
    setSearchPackage("");
    setPage(1);
    setChecked(false);
    dispatch(
      _getF1Member(1, pageSize, { username, rank, package: searchPackage })
    );
  };

  return (
    <div className="member">
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-page">
              {getLanguage("MEMBERS")}
            </Typography>
            <div className="text-right">
              <Button
                variant="contained"
                color="primary"
                startIcon={<PersonAddIcon />}
                style={{
                  textTransform: "capitalize",
                }}
                onClick={() => setShowNewMember(true)}
              >
                {getLanguage("CREATE_MEMBER")}
              </Button>
              <Dialog
                fullScreen={fullScreen}
                open={showNewMember}
                onClose={() => setShowNewMember(false)}
                scroll="body"
              >
                <NewMember
                  user={user}
                  open={showNewMember}
                  close={() => setShowNewMember(false)}
                  _handleSuccess={() => setIsReload(!isReload)}
                />
              </Dialog>
            </div>

            {
              (isMobile || isMobileApp()) &&
              <div className="mb-3">
                <Grid container>
                  <Grid item xs={12} className="text-right">
                    <IconButton
                      onClick={() => {
                        setShowFilter(!showFilter);
                        _resetFilter();
                      }}
                    >
                      {showFilter ? <CloseIcon /> : <FilterListIcon />}
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={showFilter}>
                      <Grid container>
                        <Grid item xs={6} className="pr-1">
                          <FormControl variant="outlined" size="small" fullWidth={true}>
                            <Select
                              value={rank}
                              onChange={(e) => {
                                setRank(e.target.value);
                                setPage(1);
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="" disabled>
                                {getLanguage("SELECT_RANK")}
                              </MenuItem>
                              {[
                                // "BASIC",
                                "PRO",
                                "MASTER",
                                "COACH",
                                "SUPERVISOR",
                                "FOUNDER",
                              ].map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} className="pl-1">
                          <FormControl variant="outlined" size="small" fullWidth={true}>
                            <Select
                              value={searchPackage}
                              onChange={(e) => {
                                setSearchPackage(e.target.value);
                                setPage(1);
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="" disabled>
                                {getLanguage("SELECT_PACKAGE")}
                              </MenuItem>
                              {packageList &&
                                packageList.map((item, index) => (
                                  <MenuItem key={index} value={item.package}>
                                    {item.package}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper
                            variant="outlined"
                            size="small"
                            component="form"
                            onSubmit={_handleSearchUsername}
                            className="input-border mt-2 w-100"
                            id="filer-form"
                          >
                            <Grid
                              container
                              alignItems="center"
                              justify="space-between"
                              className="pl-3 pr-2"
                            >
                              <Grid item style={{ flex: 1 }}>
                                <InputBase
                                  placeholder={getLanguage("Search_username")}
                                  inputProps={{ "aria-label": "search google maps" }}
                                  id="inputUsername"
                                  autoComplete="off"
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item>
                                <IconButton type="submit" aria-label="search">
                                  <SearchIcon color="primary" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </div>
            }

            {
              (!isMobile && !isMobileApp()) &&
              <Paper
                component="form"
                onSubmit={_handleSearchUsername}
                style={{ width: "100%" }}
                className="mt-3 mb-3 filter-bar hide-xs"
                variant="outlined"
                id="filer-form"
              >
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <FormControl variant="filled" size="small">
                      <Select
                        value={rank}
                        onChange={(e) => {
                          setRank(e.target.value);
                          setPage(1);
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          {getLanguage("SELECT_RANK")}
                        </MenuItem>
                        {[
                          // "BASIC",
                          "PRO",
                          "MASTER",
                          "COACH",
                          "SUPERVISOR",
                          "FOUNDER",
                        ].map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item>
                    <FormControl variant="filled" size="small">
                      <Select
                        value={searchPackage}
                        onChange={(e) => {
                          setSearchPackage(e.target.value);
                          setPage(1);
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          {getLanguage("SELECT_PACKAGE")}
                        </MenuItem>
                        {packageList &&
                          packageList.map((item, index) => (
                            <MenuItem key={index} value={item.package}>
                              {item.package}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item style={{ flex: 1 }}>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      className="pl-3 pr-2"
                    >
                      <Grid item style={{ flex: 1 }}>
                        <InputBase
                          placeholder={getLanguage("Search_username")}
                          inputProps={{ "aria-label": "search google maps" }}
                          id="inputUsername"
                          fullWidth
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item>
                        <IconButton type="submit" aria-label="search">
                          <SearchIcon color="primary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" flexItem className="mr-2" />
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={_handleCheck}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={getLanguage("FREE")}
                    />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item>
                    <IconButton aria-label="refresh" onClick={_resetFilter}>
                      <Refresh color="action" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            }

            {
              (isMobile || isMobileApp()) ?
                <TableMobile
                  member={member}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                  setSelectedUser={setSelectedUser}
                /> :
                <TableDeskTop
                  member={member}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                  setSelectedUser={setSelectedUser}
                />
            }

            <AddTree
              data={selectUser}
              _handleClose={() => setSelectedUser(null)}
              _handleSubmit={() => setIsReload(!isReload)}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Index;


const TableMobile = (props) => {
  const {
    member,
    pageSize,
    page,
    _handleChangePage,
    setSelectedUser
  } = props;
  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableBody>
          {member &&
            member.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Grid container>
                    <Grid item xs={6}>
                      #{row.id}
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      {row.username}
                    </Grid>
                    <Grid item xs={12}>
                      <div className="mt-2 mb-2 opacity-06" />
                    </Grid>
                    <Grid item xs={6}>
                      <small className="opacity-06">
                        {getLanguage("RANK")}
                      </small>
                      <div>{getLanguage(row.rank).toUpperCase()}</div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <small className="opacity-06">
                        {getLanguage("PACKAGE")}
                      </small>
                      <div>{getLanguage(row.package).toUpperCase()}</div>
                    </Grid>
                    <Grid item xs={6}>
                      <small className="opacity-06">
                        {getLanguage("LEFT")}
                      </small>
                      <div>
                        {!row.isInBinaryTree &&
                          row.package !== "NO_PACKAGE" ? (
                          <Button
                            size="small"
                            onClick={() =>
                              setSelectedUser({
                                user: row,
                                side: "left",
                              })
                            }
                            className="add-position"
                          >
                            {getLanguage("ADD")} {getLanguage("left")}
                          </Button>
                        ) : (
                          formatUSD(row.leftPv)
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <small className="opacity-06">
                        {getLanguage("RIGHT")}
                      </small>
                      <div>
                        {!row.isInBinaryTree &&
                          row.package !== "NO_PACKAGE" ? (
                          <Button
                            size="small"
                            onClick={() =>
                              setSelectedUser({
                                user: row,
                                side: "RIGHT",
                              })
                            }
                            className="add-position"
                          >
                            {getLanguage("ADD")} {getLanguage("RIGHT")}
                          </Button>
                        ) : (
                          formatUSD(row.rightPv)
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          {member && member.itemCount === 0 && (
            <TableRow>
              <TableCell align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!member && (
            <TableRow>
              <TableCell align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {member && member.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={member && member.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}

const TableDeskTop = (props) => {
  const {
    member,
    pageSize,
    page,
    _handleChangePage,
    setSelectedUser
  } = props;
  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{getLanguage("USERNAME")}</TableCell>
            <TableCell>{getLanguage("RANK")}</TableCell>
            <TableCell>{getLanguage("PACKAGE")}</TableCell>
            <TableCell align="right">{getLanguage("LEFT")} PV</TableCell>
            <TableCell align="right">{getLanguage("RIGHT")} PV</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {member &&
            member.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>#{row.id}</TableCell>
                <TableCell component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell>{getLanguage(row.rank).toUpperCase()}</TableCell>
                <TableCell>
                  {getLanguage(row.package).toUpperCase()}
                </TableCell>
                <TableCell align="right">
                  {!row.isInBinaryTree && row.package !== "NO_PACKAGE" ? (
                    <Button
                      size="small"
                      onClick={() =>
                        setSelectedUser({
                          user: row,
                          side: "LEFT",
                        })
                      }
                      className="add-position"
                    >
                      {getLanguage("ADD")} {getLanguage("LEFT")}
                    </Button>
                  ) : (
                    formatUSD(row.leftPv)
                  )}
                </TableCell>
                <TableCell align="right">
                  {!row.isInBinaryTree && row.package !== "NO_PACKAGE" ? (
                    <Button
                      size="small"
                      onClick={() =>
                        setSelectedUser({
                          user: row,
                          side: "RIGHT",
                        })
                      }
                      className="add-position"
                    >
                      {getLanguage("ADD")} {getLanguage("RIGHT")}
                    </Button>
                  ) : (
                    formatUSD(row.rightPv)
                  )}
                </TableCell>
              </TableRow>
            ))}
          {member && member.itemCount === 0 && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!member && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {member && member.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={member && member.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}