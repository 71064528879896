import {
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
  InputLabel,
  TextField,
  CardHeader,
  Paper,
  Divider,
  Grid,
  Container,
  IconButton,
} from "@material-ui/core";
import { Close, CloudUpload, Update } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import clsx from "clsx";
import { getLanguage } from "../../../languages/getLanguage";
import { API, CustomToast } from "../../../settings";
import { ENDPOINT_POST_KYC_UPLOAD } from "../../../settings/endpoint";
import { getAccessToken } from "../../../utils/auth";

function isFileImage(file) {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
  return file && acceptedImageTypes.includes(file["type"]);
}

export default function VerificationPhoto({
  _handleComplete,
  title,
  type,
  errors,
}) {
  const [image, setImage] = useState(null);

  const _handleSelectImage = (e) => {
    _handleComplete(null);
    if (e.target.files.length > 0) {
      if (isFileImage(e.target.files[0])) {
        if (e.target.files[0].size < 5000000) {
          var fd = new FormData();
          fd.append("image", e.target.files[0]);
          fetch(`${API}${ENDPOINT_POST_KYC_UPLOAD}`, {
            headers: {
              Authorization: "bearer " + getAccessToken(),
            },
            method: "POST",
            body: fd,
          })
            .then((res) => res.json())
            .then((json) => {
              if (!json.success) alert(json.data.msg);
              else _handleComplete(json.data.name);
            })
            .catch((err) => console.log(err));
          setImage(URL.createObjectURL(e.target.files[0]));
        } else {
          CustomToast("error", getLanguage("IMAGE_LARGE"));
        }
      } else {
        CustomToast("error", getLanguage("IMAGE_CORRECT"));
      }
    }
  };

  return (
    <div
      className={clsx(errors.includes(type) && "error", "upload-photo")}
    >
      <IconButton
        onClick={() => {
          _handleComplete();
          setImage(null);
        }}
        className="close-button"
      >
        <Close />
      </IconButton>
      <label className="upload">
        {image ? (
          <img src={image} alt="" />
        ) : (
          <div className="link" align="center">
            <CloudUpload color="primary" />
            <Typography color="primary">
              {getLanguage("UPLOAD_PHOTO")}
            </Typography>
          </div>
        )}
        <input
          type="file"
          onChange={_handleSelectImage}
          accept="image/x-png,image/jpeg"
          hidden
        />
      </label>
      <div className="title">
        <Divider />
        <Typography align="center" style={{ padding: 10 }}>
          {title}
        </Typography>
      </div>
    </div>
  );
}
