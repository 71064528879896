import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { Link } from "react-router-dom";
import BtnLang from "../../client-areas/common/BtnLang"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logout } from "../../../utils/auth";

function Header() {

  return (
    <AppBar>
      <Toolbar className="position-relative">
        <div className="position-absolute" style={{ left: 0 }}>
          <BtnLang />
        </div>
        <Link to="/mobile-app/dashboard" className="w-100 text-center">
          <img src="/images/logo.png" alt="Logo" height={40} />
        </Link>
        <div className="position-absolute" style={{ right: 0 }}>
          <IconButton className="text-primary" onClick={() => logout()}>
            <ExitToAppIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default Header;