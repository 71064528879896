import React, { useEffect, useState } from "react";
import Img404 from "../../assets/images/img404.svg";

Index.defaultProps = {
  text: "NO DATA",
  image: true
}

function Index(props) {

  const { text, image, height } = props;
  const [textData, setTextData] = useState(text);
  const [imageData, setImageData] = useState(image);

  useEffect(() => {
    const fetchData = () => {
      if (text) {
        setTextData(text)
      }
      if (image) {
        setImageData(image)
      }
    };
    fetchData();
  }, [text, image]);

  return (
    <div className="card no-data">
      {
        imageData ? <img src={Img404} alt="Logo" className="img404" style={{ height: height ? height : "auto" }} /> : null
      }
      <div className="card-img-overlay">
        <p className="card-text text-center">{textData}</p>
      </div>
    </div>
  )
}
export default Index;
