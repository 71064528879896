import { Container, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import LazyLoad from "react-lazyload";
import backgroundImage from "../../assets/images/bg-features.png";
import { getLanguage } from "../../../languages/getLanguage";

const Features = () => {
  const cards = [
    {
      title: `${getLanguage("features_card_title_1")}`,
      text: `${getLanguage("features_card_text_1")}`,
      urlImg: "/images/homepage/features_mining-cloud.png",
    },
    {
      title: `${getLanguage("features_card_title_2")}`,
      text: `${getLanguage("features_card_text_2")}`,
      urlImg: "/images/homepage/features_mining-filecoin.png",
    },
    {
      title: `${getLanguage("features_card_title_3")}`,
      text: `${getLanguage("features_card_text_3")}`,
      urlImg: "/images/homepage/features_secure.png",
    },
    {
      title: `${getLanguage("features_card_title_4")}`,
      text: `${getLanguage("features_card_text_4")}`,
      urlImg: "/images/homepage/features_competitive.png",
    },
    {
      title: `${getLanguage("features_card_title_5")}`,
      text: `${getLanguage("features_card_text_5")}`,
      urlImg: "/images/homepage/features_dashboard.png",
    },
    {
      title: `${getLanguage("features_card_title_6")}`,
      text: `${getLanguage("features_card_text_6")}`,
      urlImg: "/images/homepage/features_daily.png",
    },
  ];

  return (
    <div
      className="features"
      id="features"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="content">
        <Container>
          <div className="mb-5 text-center features-video">
            <div className="content-video">
              <iframe
                src="https://www.youtube.com/embed/PHt-WQvWhrg?controls=1&rel=0"
                title="Mining Club | CEO Henri Cardone introduces about MiningClub"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </div>
          </div>
        </Container>
        <Container>
          <Typography
            variant="h4"
            className="text-center text-white pb-5 mb-5"
          >
            {getLanguage("features_title")}
          </Typography>
          <Grid container>
            {cards &&
              cards.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="text-center mb-5"
                  key={index}
                >
                  <div className="p-2">
                    <img src={item.urlImg} alt="feature-img-item" />
                    <Typography
                      variant="subtitle1"
                      className="mt-3 mb-2 text-white"
                    >
                      {item.title.toUpperCase()}
                    </Typography>
                    <Typography className="text-white opacity-06">
                      {item.text}
                    </Typography>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Features;
