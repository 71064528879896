import {
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Container,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { _getBinaryTransaction } from "../../../actions/miningActions";
import { getLanguage } from "../../../languages/getLanguage";
import { formatAmount } from "../../../settings/format";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

export default function BinaryTx() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const { mining, user } = useSelector((state) => state);
  const { binaryTransaction } = mining;
  const { information } = user;

  useEffect(() => {
    dispatch(_getBinaryTransaction(page, pageSize));
  }, [dispatch, page, pageSize]);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <div>
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant="outlined" className="text-center mb-5 p-3">
              <Grid container>
                <Grid item xs={12} sm={isMobileApp() ? 12 : 6}>
                  <Typography className="mb-2">{getLanguage("LEFT")} pv</Typography>
                  <Typography variant={isMobile ? "h6" : (isMobileApp() ? "h6" : "h5")} className="mb-3">
                    <span className="text-primary">
                      {information && formatAmount(information.leftPv)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={isMobileApp() ? 12 : 6}>
                  <Typography className="mb-2">{getLanguage("RIGHT")} pv</Typography>
                  <Typography variant={isMobile ? "h6" : (isMobileApp() ? "h6" : "h5")} className="mb-3">
                    <span className="text-primary">
                      {information && formatAmount(information.rightPv)}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Typography variant="h5" className="title-page">
              {getLanguage("BINARY_COMMISSION")}
            </Typography>
            {
              isMobile || isMobileApp() ?
                <TableMobile
                  binaryTransaction={binaryTransaction}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                /> :
                <TableDeskTop
                  binaryTransaction={binaryTransaction}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                />
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const TableMobile = (props) => {
  const {
    binaryTransaction,
    _handleChangePage,
    page,
    pageSize
  } = props;

  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableBody>
          {binaryTransaction &&
            binaryTransaction.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Grid container>
                    <Grid xs={6} className="mb-2">
                      #{row.id}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="text-right mb-2"
                    >
                      {formatAmount(row.amount)} {row.asset}
                    </Grid>
                    <Grid item xs={6} className="text-left">
                      <small className="opacity-06">
                        {getLanguage("PV")}
                      </small>
                      <div>{formatAmount(row.pv)}</div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <small className="opacity-06">
                        {getLanguage("TIME")}
                      </small>
                      <div>
                        {moment(row.createdTime).format("yyyy-MM-DD")}
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          {binaryTransaction && binaryTransaction.itemCount === 0 && (
            <TableRow>
              <TableCell align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!binaryTransaction && (
            <TableRow>
              <TableCell align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell align="right">
              {getLanguage("TOTAL")}:{" "}
              <span>
                {formatAmount(
                  binaryTransaction ? binaryTransaction.meta.total : 0
                )}{" "}
                USDT
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {binaryTransaction && binaryTransaction.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={binaryTransaction && binaryTransaction.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}

const TableDeskTop = (props) => {
  const {
    binaryTransaction,
    _handleChangePage,
    page,
    pageSize
  } = props;
  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">{getLanguage("PV")}</TableCell>
            <TableCell align="right">{getLanguage("AMOUNT")}</TableCell>
            <TableCell align="right">{getLanguage("TIME")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {binaryTransaction &&
            binaryTransaction.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>#{row.id}</TableCell>
                <TableCell align="right">{formatAmount(row.pv)}</TableCell>
                <TableCell align="right">
                  {formatAmount(row.amount)} {row.asset}
                </TableCell>
                <TableCell align="right">
                  {moment(row.createdTime).format("yyyy-MM-DD")}
                </TableCell>
              </TableRow>
            ))}
          {binaryTransaction && binaryTransaction.itemCount === 0 && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!binaryTransaction && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={3} align="right">
              {getLanguage("TOTAL")}:{" "}
              <span>
                {formatAmount(
                  binaryTransaction ? binaryTransaction.meta.total : 0
                )}{" "}
                USDT
              </span>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
      {binaryTransaction && binaryTransaction.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={binaryTransaction && binaryTransaction.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}