import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";

const Intro = () => {
  const cards = [
    {
      title: `${getLanguage("introHome_title_1")}`,
      text: `${getLanguage("introHome_text_1")}`,
      urlImg: "/images/homepage/your-hardware.png",
    },
    {
      title: `${getLanguage("introHome_title_2")}`,
      text: `${getLanguage("introHome_text_2")}`,
      urlImg: "/images/homepage/mine-alternative.png",
    },
    {
      title: `${getLanguage("introHome_title_3")}`,
      text: `${getLanguage("introHome_text_3")}`,
      urlImg: "/images/homepage/mine-output.png",
    },
  ];
  return (
    <div
      className="intro pt-5 pb-5 text-center"
      style={{ backgroundColor: "#fff", minHeight: "auto" }}
    >
      <Container>
        <Grid container>
          {cards &&
            cards.map((item, index) => (
              <Grid item xs={12} md={4} lg={4} key={index} className="p-2">
                <img src={item.urlImg} alt="" />
                <Typography
                  variant="h6"
                  className="text-bolder mb-2"
                  style={{ color: "#0076BA" }}
                >
                  {item.title}
                </Typography>
                <p>{item.text}</p>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Intro;
