import React from "react";
import PinInput from "react-pin-input";
import "./otp-confirm.scss"

function OtpConfirm({ _handleComplete }) {
    return (
        <PinInput
            length={6}
            type="numeric"
            inputMode="number"
            onComplete={(value) => {
                _handleComplete(value);
            }}
            autoSelect={true}
            focus={true}
        />
    );
}

export default OtpConfirm;