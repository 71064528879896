import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";
import { useEffect } from "react";
import { _getMyPackageList } from "../../../actions/miningActions";
import moment from "moment";
import "./style.scss";
import { formatAmount } from "../../../settings/format";
import Pickaxe from "./Pickaxe";
import { S3_URL } from "../../../settings";
import { useTheme } from "@material-ui/core/styles";
import { isMobileApp } from "../../../utils/auth";

function Index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { mining } = useSelector((state) => state);
  const { myPackageList } = mining;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_getMyPackageList());
    console.log(myPackageList);
  }, [dispatch]);

  return (
    <div className="my-plans">
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-page">
              {getLanguage("MY_PLANS")}
            </Typography>
          </Grid>
        </Grid>
        {
          isMobile || isMobileApp() ?
            <Mobile myPackageList={myPackageList} /> :
            <Desktop myPackageList={myPackageList} />
        }
      </Container>
    </div>
  );
}

export default Index;


const Desktop = (props) => {
  const { myPackageList } = props;
  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{getLanguage("PACKAGE")}</TableCell>
            <TableCell align="right">{getLanguage("POWER")}</TableCell>
            <TableCell align="right">{getLanguage("TOTAL_MINED")}</TableCell>
            <TableCell align="right">{getLanguage("START_TIME")}</TableCell>
            <TableCell align="right">{getLanguage("END_TIME")}</TableCell>
            <TableCell align="right">{getLanguage("LAST_MINED")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {myPackageList &&
            myPackageList.map((item, index) => (
              <TableRow key={index}>
                <TableCell>#{item.id}</TableCell>
                <TableCell translate="no">
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={`${S3_URL}packages/${item.package}_2.png`}
                      alt=""
                      style={{
                        height: 35,
                        width: 35,
                        borderRadius: "50%",
                      }}
                    />
                    <Pickaxe status={item.status} />
                  </div>
                  <span className="ml-2"> {getLanguage(item.package)}</span>
                </TableCell>
                <TableCell align="right">
                  {
                    item.package === "STARTER" ? "0.5 TiB" :
                      item.package === "QUARTER" ? "1 TiB" :
                        item.package === "HALF" ? "2 TiB" :
                          item.package === "MAIN" ? "4 TiB" :
                            item.package === "SPECIAL" ? "6 TiB" : "--/--"
                  }
                </TableCell>
                <TableCell align="right">
                  {item.package === "MEMBER"
                    ? "--/--"
                    : formatAmount(item.totalMined) + " " + item.coin}
                </TableCell>
                <TableCell align="right">
                  {
                    item.package === "MEMBER" ? "--/--" :
                      moment(item.startTime).format("yyyy-MM-DD")
                  }
                </TableCell>
                <TableCell align="right">
                  {
                    item.package === "MEMBER" ? "--/--" :
                      moment(item.endTime).format("yyyy-MM-DD")
                  }
                </TableCell>
                <TableCell align="right">
                  {item && moment().utc() - item.lastMined > 0
                    ? moment(item.lastMined).format("yyyy-MM-DD")
                    : "--/--"}
                </TableCell>
              </TableRow>
            ))}
          {myPackageList && myPackageList.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!myPackageList && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Mobile = (props) => {
  const { myPackageList } = props;
  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableBody>
          {myPackageList &&
            myPackageList.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <div>#{item.id}</div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <div>
                        <span className="mr-2">
                          {getLanguage(item.package)}
                        </span>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={`${S3_URL}packages/${item.package}_2.png`}
                            alt=""
                            style={{
                              height: 35,
                              width: 35,
                              borderRadius: "50%",
                            }}
                          />
                          <Pickaxe status={item.status} />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="mt-2 mb-2 opacity-06" />
                    </Grid>
                    <Grid item xs={6}>
                      <small className="opacity-06">
                        {getLanguage("TOTAL_MINED")}
                      </small>
                      <div>
                        {item.package === "MEMBER"
                          ? "--/--"
                          : formatAmount(item.totalMined) +
                          " " +
                          item.coin}
                      </div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <small className="opacity-06">
                        {getLanguage("START_TIME")}
                      </small>
                      <div>
                        {
                          item.package === "MEMBER" ? "--/--" :
                            moment(item.startTime).format("yyyy-MM-DD")
                        }
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <small className="opacity-06">
                        {getLanguage("LAST_MINED")}
                      </small>
                      <div>
                        {moment(item.lastMined).format("yyyy-MM-DD")}
                      </div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <small className="opacity-06">
                        {getLanguage("END_TIME")}
                      </small>
                      <div>
                        {
                          item.package === "MEMBER" ? "--/--" :
                            moment(item.endTime).format("yyyy-MM-DD")
                        }
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          {myPackageList && myPackageList.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!myPackageList && (
            <TableRow>
              <TableCell colSpan={1} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
