import React from "react";
import "./style.scss";
import { Typography, Container } from "@material-ui/core";

function index(props) {
  const { title } = props;
  return (
    <div className="title-page">
      <Container maxWidth="lg">
        <div className="content-title-page">
          <Typography variant="h3">{title}</Typography>
        </div>
      </Container>
    </div>
  );
}

export default index;
