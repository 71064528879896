import { Check, Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import { getLanguage } from "../languages/getLanguage";
import { Typography } from "@material-ui/core";
import { formatAddress } from "./format";
export const ACCESS_TOKEN_KEY = "CBt3gpbqeMdWPNG1";
export const DEVICE_KEY = "uU5tEUmAgvBWArsv";
export const SCOPES_KEY = "AhBcmvr1EkMdPnL5";
export const S3_URL = "/images/";

export const APIs = {
  localhost: "https://miningapi.spdev.co/api",
  "mining.spdev.co": "https://miningapi.spdev.co/api",
  "beta.miningclub.org": "https://api.miningclub.org/api",
  "miningclub.org": "https://api.miningclub.org/api",
  "www.miningclub.org": "https://api.miningclub.org/api",
};
export const API = APIs[window.location.hostname];

export const makeID = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const deviceInfo = () => {
  var navUserAgent = navigator.userAgent;
  var browserName = navigator.appName;
  var browserVersion = "" + parseFloat(navigator.appVersion);
  // var majorVersion = parseInt(navigator.appVersion, 10);
  var tempNameOffset, tempVersionOffset, tempVersion;

  if ((tempVersionOffset = navUserAgent.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    browserVersion = navUserAgent.substring(tempVersionOffset + 6);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    browserVersion = navUserAgent.substring(tempVersionOffset + 5);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if (
    (tempNameOffset = navUserAgent.lastIndexOf(" ") + 1) <
    (tempVersionOffset = navUserAgent.lastIndexOf("/"))
  ) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
    browserVersion = navUserAgent.substring(tempVersionOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // trim version
  if ((tempVersion = browserVersion.indexOf(";")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);
  if ((tempVersion = browserVersion.indexOf(" ")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);

  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

  return browserName + " V" + browserVersion + " (" + OSName + ")";
};

export const CustomToast = (type, content) => {
  if (type === "error") {
    toast.error(
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}
      >
        <Close className="mr-2" color="secondary" />
        <Typography variant="body2">{content}</Typography>
      </div>
    );
  } else {
    toast.success(
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}
      >
        <Check className="mr-2" color="primary" />
        <Typography variant="body2">{content}.</Typography>
      </div>
    );
  }
};

export const CustomSwal = (type, html = null) => {
  switch (type) {
    case "success":
      return Swal.fire({
        title: getLanguage("SUCCESS"),
        html: html,
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: getLanguage("YES"),
      });
    case "warning":
      return Swal.fire({
        title: getLanguage("ARR_YOU_SURE"),
        html: html,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: getLanguage("YES"),
        cancelButtonText: getLanguage("NO"),
      });
    case "error":
      return Swal.fire({
        title: getLanguage("ERROR"),
        html: html,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: getLanguage("YES"),
      });
    case "info":
      return Swal.fire({
        title: getLanguage("INFO"),
        html: html,
        icon: "info",
        confirmButtonColor: "#3085d6",
        confirmButtonText: getLanguage("YES"),
      });
    case "question":
      return Swal.fire({
        title: getLanguage("QUESTION"),
        html: html,
        icon: "question",
        confirmButtonColor: "#3085d6",
        confirmButtonText: getLanguage("YES"),
      });
    default:
      return Swal.fire({
        html: html,
        confirmButtonColor: "#3085d6",
        confirmButtonText: getLanguage("YES"),
      });
  }
};

export const handleScrollToElementId = (id) => {
  var el = document.getElementById(id);
  if (el) {
    var offset = el.offsetTop;
    console.log("đấ", offset);
    $("html, body").animate(
      {
        scrollTop: offset,
      },
      800
    );
  }
};

export function getLinkHash(data) {
  if (data) {
    const { type, network, txId } = data;
    if (txId) {
      if (type === "EXTERNAL") {
        let address = null;
        if (network === "TRC20" || network === "TRX") {
          address = `https://tronscan.org/#/transaction/${txId}`;
        }
        if (network === "ERC20" || network === "ETH") {
          address = `https://etherscan.io/tx/${txId}`;
        }
        if (network === "EOS") {
          address = `https://bloks.io/transaction/${txId}`;
        }
        if (network === "BTC") {
          address = `https://www.blockchain.com/btc/tx/${txId}`;
        }
        if (network === "FIL") {
          address = `https://filfox.info/zh/message/${txId}`;
        }
        return (
          <a href={address} target="_blank" rel="noreferrer">
            {formatAddress(data.txId)}
          </a>
        );
      } else {
        return formatAddress(txId);
      }
    } else return null;
  } else {
    return null;
  }
}

export const getModesTemplate = () => {
  var mode = localStorage.getItem('mode-template');
  if (mode) {
    return mode;
  } else {
    localStorage.setItem('mode-template', "dark");
    return "dark";
  }
}

export const getCountryFlags = (countryCode, size) => {
  return `https://www.countryflags.io/${countryCode}/flat/${size}.png`
}