/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Close } from "@material-ui/icons";
import { formatAmount } from "../../../settings/format";
import { getLanguage } from "../../../languages/getLanguage";

export default function TransactionConfirm({
  open,
  data,
  title,
  _onCancel,
  _onConfirm,
  type,
  youWillGet,
}) {
  return (
    <Drawer anchor="left" open={open} className={"custom-modal-vk"}>
      <Container maxWidth="md" style={{ padding: 0 }}>
        <div
          style={{
            textAlign: "center",
            paddingTop: 20,
            paddingBottom: 20,
            width: 300,
            height: "100%",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            onClick={_onCancel}
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className="mb-3">
            {title}
          </Typography>
          <Typography variant="caption">
            {type === "WITHDRAW"
              ? getLanguage("ADDRESS")
              : getLanguage("USERNAME")}
          </Typography>
          <Typography variant="subtitle2">
            {data.username}
          </Typography>
          <Divider className="mt-2 mb-2" />
          <Typography variant="caption">{getLanguage("AMOUNT")}</Typography>
          <Typography variant="subtitle2">
            {formatAmount(data.amount)} {data.asset}
          </Typography>
          <Divider className="mt-2 mb-2" />
          <Typography variant="caption">{getLanguage("FEE")}</Typography>
          <Typography variant="subtitle2">
            {formatAmount(data.fee)} {data.asset}
          </Typography>
          <Divider className="mt-2 mb-2" />
          <Typography variant="caption">
            {getLanguage("YOU_WILL_GET")}
          </Typography>
          <Typography variant="subtitle2">
            {formatAmount(data.youWillGet)} {data.asset}
          </Typography>
          <Divider className="mt-2 mb-2" />
          <Typography variant="caption">{getLanguage("TIME")}</Typography>
          <Typography variant="subtitle2">
            {moment().format("YYYY-MM-DD HH-mm-ss")}
          </Typography>
          <div className="pt-5 pr-3 pl-3">
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={_onConfirm}
            >
              {getLanguage("CONFIRM")}
            </Button>
          </div>
        </div>
      </Container>
    </Drawer>
  );
}
