import React from "react";
import { _getLoginActivity, _getProfile } from "../../../actions/userActions";
import { getLanguage } from "../../../languages/getLanguage";
import { S3_URL } from "../../../settings";
import { formatAmount, formatUSD } from "../../../settings/format";
import {
  Typography,
  Paper,
} from "@material-ui/core";

function Info(props) {
  const { balance } = props;

  return (
    <div className="coin text-center card">
      <div className="card-body">
        <div className="mb-3">
          <img src={`${S3_URL}/coins/${balance.asset}.png`} alt={balance.asset} width="100" />
        </div>
        <Typography variant="h3" className="mb-2">{formatAmount(balance.amount)} {balance.asset}</Typography>
        <Typography variant="h5">~${formatUSD(balance.amount * balance.usdPrice)}</Typography>
      </div>
    </div>
  );
}

export default Info;
