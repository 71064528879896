import React from "react";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getLanguage } from "../../../languages/getLanguage";
import { isMobileApp } from "../../../utils/auth";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
} from "@material-ui/core";

var responsive;
if (isMobileApp()) {
  responsive = {
    mobile: {
      breakpoint: { max: 99999, min: 0 },
      items: 1,
    },
  };
} else {
  responsive = {
    superLargeDesktop: {
      breakpoint: { max: 99999, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1280, min: 960 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 960, min: 0 },
      items: 1,
    },
  };
}

function Carousel() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  var data = [
    {
      image: "/images/dashboard-banner/Member150.png",
      title: "slide-1",
      link: getLanguage("db_slide_banner_1")
    },
    {
      image: "/images/dashboard-banner/ranking.png",
      title: "slide-2",
      link: getLanguage("db_slide_banner_2")
    },
    {
      image: "/images/dashboard-banner/LaunchPromo.png",
      title: "slide-2",
      link: getLanguage("db_slide_banner_3")
    }
  ];

  return (
    <div className="carousel">
      <MultiCarousel responsive={responsive} infinite={true} autoPlay={true}>
        {data.map((item, index) => (
          <div className={clsx("content-slide", (isMobileApp() || isMobile) ? "" : "p-2")} key={index}>
            <a target="_blank" href={item.link}>
              <img src={item.image} alt="" className="w-100" />
            </a>
          </div>
        ))}
      </MultiCarousel>
    </div>
  );
}
export default Carousel;
