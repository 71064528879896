import React, { useEffect, useState } from "react";
import { _getLoginActivity, _getProfile } from "../../../actions/userActions";
import { getLanguage } from "../../../languages/getLanguage";
import {
    FormControl,
    Button,
    AppBar,
    Tabs,
    Tab,
    CircularProgress,
    Grid,
    InputLabel,
    Input,
    Typography,
} from "@material-ui/core";
import { _getBalance } from "../../../actions/miningActions";
import ValidateFormatError, { validateFormatErrorInput } from "../common/ValidateFormatError";
import { makeStyles } from "@material-ui/core/styles";
import { green } from '@material-ui/core/colors'; import {
    checkEmpty,
    checkNumber,
} from "../../../settings/validate";
import { post, get } from "../../../utils/api";
import {
    ENDPOINT_POST_WITHDRAW_TRANSFER,
} from "../../../settings/endpoint";
import { formatAmount } from "../../../settings/format";
import NumberFormat from 'react-number-format';
import { CustomSwal } from "../../../settings";


function Transfer(props) {
    const { balance } = props;
    const { networks } = balance;

    const [valueTab, setValueTab] = useState(0);
    const [panesTab, setPanesTab] = useState([{
        menuItem: "",
        render: null,
    }]);

    const _handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    useEffect(() => {
        var panes = [];
        for (const item of networks) {
            var tab = {
                menuItem: item.network,
                render: <TabNetworkList balance={balance} network={item} />,
            }
            panes.push(tab);
        }
        setPanesTab(panes);
    }, [balance]);

    return (
        <div className="card">
            <AppBar position="static" color="default" className="mb-4">
                <Tabs
                    value={valueTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={_handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {panesTab.map((item, index) => (
                        <Tab label={item.menuItem} key={index} value={item.index} />
                    ))}
                </Tabs>
            </AppBar>
            <div className="card-body">
                {panesTab[valueTab].render}
            </div>
        </div>
    );
}

export default Transfer;

function TabNetworkList(props) {
    const { balance, network } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [error, setError] = useState(null);
    const [username, setUsername] = useState("");
    const [amount, setAmount] = useState("");
    const [disabledConfirm, setDisabledConfirm] = useState(true);
    const [checkUsername, setCheckUsername] = useState(false);
    const [checkAmount, setCheckAmount] = useState(false);
    const [showGA, setShowGA] = useState(false);
    const [gaCode, setGaCode] = useState("");
    const [youWillGet, setYouWillGet] = useState(0);


    useEffect(() => {
        setError(null);
        setUsername("");
        setAmount("");
    }, [balance, network]);

    const _handleSubmit = (e) => {
        e.preventDefault();

        //#region validete
        var textError = [];
        setError(null);
        validateFormatErrorInput(e);

        if (checkEmpty(username)) {
            textError.push(getLanguage("PLEASE_ENTER_USERNAME"));
            validateFormatErrorInput(e, "username");
        }
        if (checkEmpty(amount)) {
            textError.push(getLanguage("PLEASE_ENTER_AMOUNT"));
        } else {
            if (checkNumber(amount)) {
                textError.push(getLanguage("INVALID_AMOUNT"));
                validateFormatErrorInput(e, "amount");
            }
        };
        if (showGA) {
            if (checkEmpty(gaCode)) {
                textError.push(getLanguage("PLEASE_ENTER_GA_CODE"));
                validateFormatErrorInput(e, "gaCode");
            }
        }
        //#endregion

        if (textError.length > 0) {
            setError(<ValidateFormatError error={textError} />);
        } else {
            setLoading(true);

            var param;
            if (showGA) {
                param = {
                    "type": "TRANSFER",
                    "asset": balance.asset,
                    "network": network.network,
                    amount,
                    username,
                    gaCode,
                    "addressTag": "",
                }
            } else {
                param = {
                    "type": "WITHDRAW",
                    "asset": balance.asset,
                    "network": network.network,
                    amount,
                    username,
                    "addressTag": "",
                }
            }

            post(
                ENDPOINT_POST_WITHDRAW_TRANSFER, param,
                (data) => {
                    CustomSwal("success").then(() => { });
                },
                (error) => {
                    setLoading(false);
                    textError = [getLanguage(error.code)];
                    setError(<ValidateFormatError error={textError} isToast={false} />);
                    if (error.code === "GACODE_REQUIRED") {
                        setShowGA(true)
                    }
                }
            );
        }
    }

    const _handleOpenConfirm = (e) => {
        var value = e.target.value;
        if (value > 0 && value >= network.minimumWithdraw && checkUsername) {
            setDisabledConfirm(false);
            setCheckAmount(true);
        } else if (value > 0 && value >= network.minimumWithdraw) {
            setCheckAmount(true);
        } else {
            setDisabledConfirm(true);
        }
    }

    const _checkUsername = (e) => {
        var value = e.target.value;
        var form = e.target.closest("form");
        //#region validete
        var textError = [];
        setError(null);

        if (checkEmpty(value)) {
            textError.push(getLanguage("PLEASE_ENTER_USERNAME"));
            validateFormatErrorInput(form, "username");
        }
        //#endregion

        if (textError.length > 0) {
            setError(<ValidateFormatError error={textError} />);
            setDisabledConfirm(true);
        } else {
            setCheckUsername(true);
            if (checkAmount) {
                setDisabledConfirm(false);
            } else {
                setDisabledConfirm(true);
            }
        }
    }

    return (
        <form className="w-100" onSubmit={_handleSubmit} noValidate>
            <Grid container spacing={2}>
                {
                    loadingForm ?
                        <Grid item xs={12} className="text-center">
                            <CircularProgress size={100} />
                        </Grid> :
                        <>
                            <Grid item xs={12} className="text-right">
                                <Typography>Minimum withdraw amount {network.minimumWithdraw} {balance.asset}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    required
                                    fullWidth
                                >
                                    <InputLabel>{getLanguage("USERNAME")}</InputLabel>
                                    <Input
                                        name="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        onBlur={(e) => _checkUsername(e)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    required
                                    fullWidth
                                >
                                    <InputLabel>{getLanguage("AMOUNT")}</InputLabel>
                                    <NumberFormat
                                        thousandSeparator={true}
                                        inputMode="numeric"
                                        customInput={Input}
                                        name="amount"
                                        value={amount}
                                        onChange={(e) => {
                                            _handleOpenConfirm(e);

                                            var value = e.target.value;
                                            setAmount(value);
                                            if (value > 0 && value >= network.minimumWithdraw) {
                                                setYouWillGet(e.target.value - network.withdrawFee)
                                            } else {
                                                setYouWillGet(0)
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="text-right">
                                <Typography>{getLanguage("AMOUNT")}: {amount === "" ? 0 : amount} {balance.asset}</Typography>
                                <Typography>{getLanguage("TRANSACTION_FEE")}: {network.withdrawFee} {balance.asset}</Typography>
                                <Typography>{getLanguage("YOU_WILL_GET")}: {youWillGet} {balance.asset}</Typography>
                            </Grid>
                            {
                                showGA &&
                                <Grid item xs={12}>
                                    <FormControl
                                        required
                                        fullWidth
                                    >
                                        <InputLabel>{getLanguage("GA_CODE")}</InputLabel>
                                        <Input
                                            name="gaCode"
                                            onChange={(e) => setGaCode(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                            }
                            {
                                error &&
                                <Grid item xs={12}>
                                    <FormControl error>{error}</FormControl>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <div className={classes.wrapper}>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        disabled={(loading || disabledConfirm) ? true : false}
                                    >
                                        {getLanguage("CONFIRM")}
                                    </Button>
                                    {loading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </>
                }
            </Grid>
        </form>
    )
}


//#region CSS
const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
    },
}));
  //#endregion