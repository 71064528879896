import React, { useState } from "react";
import {
  Grid,
  Drawer,
  Container,
  Button,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import PricingComponent from "../common/PricingComponent/Index";
import { useDispatch, useSelector } from "react-redux";
import { formatAmount, formatUSD } from "../../../settings/format";
import { post } from "../../../utils/api";
import { ENDPOINT_GET_POST_LOT } from "../../../settings/endpoint";
import { CustomToast, S3_URL } from "../../../settings";
import { getLanguage } from "../../../languages/getLanguage";
import { _getBalance, _getPackageList } from "../../../actions/miningActions";
import { useEffect } from "react";
import CustomLoading from "../common/CustomLoading";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from "@material-ui/core/styles";
import { isMobileApp } from "../../../utils/auth";
import MutationObserver from 'react-mutation-observer';
import MatchHeight from "../../common/fix-height/MatchHeight";

import moment from "moment";
import {
  MINING_END_AFTER_DATE,
  MINING_END_AFTER_DATE_MEMBER,
  MINING_START_AFTER_DATE,
  MINING_START_AFTER_DATE_MEMBER
} from "../../../constants";

function Bitcoin() {
  const { mining, setting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { packageList, balances } = mining;
  const [selectedPackage, setSelectedPackage] = useState();
  const { library } = setting;
  const [asset, setAsset] = useState("USDT");
  const [confirming, setConfirming] = useState(false);
  const [checked, setChecked] = useState(true);
  const [warningLayout, setWarningLayout] = useState(false);

  const plans = {
    MEMBER: {
      light: "#929292",
      dark: "#5E5E5E",
      note: [
        getLanguage("Reservation"),
        // getLanguage("Lasts_for_days", { number: 90 }),
        getLanguage("No_profit_from_mining")
      ],
    },
    STARTER: {
      light: "#00A2FF",
      dark: "#223F6A",
      note: [
        "0.5 TiB",
        getLanguage("Days_of_mining", { number: 1000 }),
        getLanguage("Start_after_days", { number: 15 })
      ],
    },
    QUARTER: {
      light: "#87ECBD",
      dark: "#65AF8C",
      note: [
        "1 TiB",
        getLanguage("Days_of_mining", { number: 1000 }),
        getLanguage("Start_after_days", { number: 15 })
      ],
    },
    HALF: {
      light: "#8ACEE2",
      dark: "#6DA1B0",
      note: [
        "2 TiB",
        getLanguage("Days_of_mining", { number: 1000 }),
        getLanguage("Start_after_days", { number: 15 })
      ],
    },
    MAIN: {
      light: "#FFCD7A",
      dark: "#DCB269",
      note: [
        "4 TiB",
        getLanguage("Days_of_mining", { number: 1000 }),
        getLanguage("Start_after_days", { number: 15 })
      ],
    },
    SPECIAL: {
      light: "#FF7B78",
      dark: "#C35E5A",
      note: [
        "6 TiB",
        getLanguage("Days_of_mining", { number: 1000 }),
        getLanguage("Start_after_days", { number: 15 })
      ],
    },
  };

  useEffect(() => {
    dispatch(_getPackageList());
  }, [dispatch]);

  const _handleConfirmBuy = (e) => {
    setSelectedPackage(e);
  };

  const _handleCancel = () => {
    setSelectedPackage(null);
    setConfirming(false);
  };

  const _handleBuy = () => {
    const packageName = selectedPackage.package;
    if (checked) {
      setSelectedPackage(null);
      setConfirming(false);
      CustomToast(
        "success",
        getLanguage("CONGRATULATION") +
        "!!! " +
        getLanguage("YOU_HAVE_SUCCESSFULLY_REQUEST_BUY") +
        " " +
        packageName +
        " " +
        getLanguage("PACKAGE").toLowerCase()
      );
      post(
        ENDPOINT_GET_POST_LOT,
        {
          package: selectedPackage.package,
          payAsset: asset,
        },
        () => {
          dispatch(_getBalance());
          CustomToast(
            "success",
            getLanguage("CONGRATULATION") +
            "!!! " +
            getLanguage("YOU_HAVE_SUCCESSFULLY_PURCHASED_THE") +
            " " +
            packageName +
            " " +
            getLanguage("PACKAGE").toLowerCase()
          );
        },
        (error) => {
          CustomToast(
            "error",
            library[error.code] ? library[error.code] : error.code
          );
        }
      );
    } else {
      CustomToast("error", getLanguage("PLEASER_CHECK_TOS"));
    }
  };

  const currentAsset =
    balances && balances.filter((item) => item.asset === asset)[0];
  const amountByAsset =
    currentAsset && selectedPackage
      ? selectedPackage.usdPrice / currentAsset.usdPrice
      : 0;

  return (
    <div className="bitcoin">
      <MutationObserver
        onContentChange={() => MatchHeight.init()}
        onChildRemoval={() => MatchHeight.init()}
        onChildAddition={() => MatchHeight.init()}
      >
        <Grid container spacing={2}>
          {packageList &&
            packageList.map((item, index) => (
              <Grid item xs={12} sm={isMobileApp() ? 12 : 6} md={isMobileApp() ? 12 : 4} key={index}>
                <PricingComponent
                  title={getLanguage("PRIMARY") + "FIL"}
                  onClick={() => _handleConfirmBuy(item)}
                  item={item}
                  colorSet={plans[item.package]}
                >
                  <Typography variant="body1">
                    {getLanguage(item.package)}
                  </Typography>
                  <Typography variant="h5" className="mb-1 mt-1">
                    <small>$</small>
                    {formatAmount(item.usdPrice)}
                  </Typography>
                  <div data-mh className="mb-3">
                    {plans[item.package].note.map((item, index) => (
                      <Typography variant="body2" key={index}>
                        {item}
                      </Typography>
                    ))}
                  </div>
                </PricingComponent>
              </Grid>
            ))}
        </Grid>
      </MutationObserver>
      {!packageList && <CustomLoading />}
      <Drawer
        anchor={"left"}
        open={selectedPackage != null}
        className={"custom-modal-vk"}
      >
        {confirming ? (
          <Container maxWidth="md" className="modal-custom">
            <Typography variant="subtitle1">
              {getLanguage("CONFIRMING_FOR_BUY_PLAN").toLocaleUpperCase()}
            </Typography>
            <Divider className="mb-3 mt-3" />
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" className="mb-2">
                  {selectedPackage && selectedPackage.package}
                </Typography>
                <Typography>
                  <span className="opacity-06">{getLanguage("PRICE")}</span>:{" "}
                  {selectedPackage && formatUSD(selectedPackage.usdPrice)}
                  <small>$</small>
                </Typography>
                {
                  selectedPackage.package === "MEMBER" ? null :
                    <Typography>
                      <span className="opacity-06">
                        {getLanguage("START_DATE")}
                      </span>
                      :{" "}
                      {selectedPackage &&
                        moment()
                          .add("day", selectedPackage.package === "MEMBER" ? MINING_START_AFTER_DATE_MEMBER : MINING_START_AFTER_DATE)
                          .format("YYYY-MM-DD")}
                    </Typography>
                }
                <Typography>
                  <span className="opacity-06 mr-2">{getLanguage("END_DATE")}:</span>
                  {
                    selectedPackage.package === "MEMBER" ? "--/--" :
                      (selectedPackage &&
                        moment()
                          .add(
                            "day",
                            selectedPackage.package === "MEMBER" ?
                              MINING_START_AFTER_DATE_MEMBER + MINING_END_AFTER_DATE_MEMBER :
                              MINING_START_AFTER_DATE + MINING_END_AFTER_DATE
                          )
                          .format("YYYY-MM-DD"))
                  }
                </Typography>
              </Grid>
              <Grid item>
                {selectedPackage && (
                  <img
                    src={`${S3_URL}packages/${selectedPackage.package}_2.png`}
                    style={{
                      width: 80,
                      height: 80,
                      margin: "auto",
                      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                      borderRadius: "50%",
                    }}
                    alt={selectedPackage.package}
                  />
                )}
              </Grid>
            </Grid>
            <Divider className="mb-3 mt-3" />
            <Typography variant="h6" className="mb-2">
              {getLanguage("PAYMENT")}
            </Typography>
            <Typography>
              <span className="opacity-06">{getLanguage("AMOUNT")}</span>:{" "}
              {formatAmount(amountByAsset)}
            </Typography>
            <Typography>
              <span className="opacity-06">{getLanguage("ASSET")}</span>:{" "}
              {asset}
            </Typography>
            <Divider className="mb-3 mt-3" />
            <Grid container alignItems="center">
              <Grid item>
                <Checkbox
                  value="allowPrivacyPolicy"
                  color="primary"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
              </Grid>
              <Grid item>
                <PrivacyPolicy />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={_handleCancel}
                >
                  {getLanguage("CANCEL")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={_handleBuy}
                >
                  {getLanguage("CONFIRM")}
                </Button>
              </Grid>
            </Grid>
            <Divider className="mb-3 mt-3" />
            <Grid container spacing={2} className="mt-3">
              <Grid item xs={12}>
                <Typography component="p" className="risk-warning">
                  {getLanguage("Risk warnings")}
                  <span className="icon-down-up" onClick={() => setWarningLayout(!warningLayout)}>
                    {!warningLayout && <ExpandMoreIcon />}
                  </span>
                </Typography>
                {
                  warningLayout &&
                  <div className="pt-3 pb-3">
                    <ol>
                      <li className="mb-3">Filecoin will have price fluctuations, and the difficulty of mining will be adjusted from time to time. Fluctuations in currency prices or adjustments in mining difficulty may cause changes in the profits of a single T. This contract does not make a refund commitment. Users must carefully assess their risk born ability and invest in digital currency mining within an acceptable risk control range. The contract issuer reserves all rights to interpret the terms of this contract.</li>
                      <li className="mb-3">This contract does not involve digital asset transactions. If users themselves participate in third-party digital asset transactions, they should bear their own responsibilities and risks.</li>
                      <li>When the mining farms cannot continue to operate due to force majeure such as legal policies, wars, earthquakes, fires, and power failures, the contract will be terminated early, Mining Club is not liable for compensation</li>
                    </ol>
                    <Typography component="p" className="risk-warning">
                      <span className="icon-down-up" onClick={() => setWarningLayout(!warningLayout)}>
                        {warningLayout && <ExpandLessIcon />}
                      </span>
                    </Typography>
                  </div>
                }
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container maxWidth="md">
            <Typography variant="subtitle1">
              {getLanguage("CONFIRMING_FOR_BUY_PLAN").toLocaleUpperCase()}
            </Typography>
            <Divider className="mb-3 mt-3" />
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5">
                  {selectedPackage && selectedPackage.package}
                </Typography>
                <Typography>
                  {getLanguage("PRICE")}:{" "}
                  {selectedPackage && formatUSD(selectedPackage.usdPrice)}
                  <small>$</small> ~ {formatAmount(amountByAsset)} {asset}
                </Typography>
              </Grid>
              <Grid item>
                {selectedPackage && (
                  <img
                    src={`${S3_URL}packages/${selectedPackage.package}_2.png`}
                    style={{
                      width: 80,
                      height: 80,
                      margin: "auto",
                      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                      borderRadius: "50%",
                    }}
                    alt={selectedPackage.package}
                  />
                )}
              </Grid>
            </Grid>
            <div className="mb-5 mt-3">
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-label">
                  {getLanguage("SELECT_ASSET")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={asset}
                  onChange={(e) => setAsset(e.target.value)}
                  fullWidth
                  label={getLanguage("SELECT_ASSET")}
                >
                  {balances &&
                    balances.map(
                      (item, index) =>
                        item.asset !== "FIL" && (
                          <MenuItem value={item.asset} key={index}>
                            {item.asset} - {formatAmount(item.amount)}
                          </MenuItem>
                        )
                    )}
                </Select>
              </FormControl>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={_handleCancel}
                >
                  {getLanguage("CANCEL")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setConfirming(true)}
                >
                  {getLanguage("PURCHASE")}
                </Button>
              </Grid>
            </Grid>
          </Container>
        )}
      </Drawer>
    </div>
  );
}

export default Bitcoin;

function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <Typography variant="body2">
      {getLanguage("CheckBox_PrivacyPolicy", null, [
        <a className={classes.link + " text-primary"} href="/terms-of-services" target="_blank">
          {getLanguage("Terms_and_Conditions")}
        </a>,
        <a className={classes.link + " text-primary"} href="/privacy-policy" target="_blank">
          {getLanguage("Privacy_Policy")}
        </a>])}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))