import React from "react";

export default function Pickaxe({ status }) {
  return (
    <div
      style={{
        backgroundColor: status === "ACTIVE" ? "#37c0ca" : "#b0b3b8",
        borderRadius: "50%",
        height: 10,
        width: 10,
        position: "absolute",
        bottom: 0,
        right: 0,
        border: "1px solid rgba(0, 0, 0, 1)",
      }}
    ></div>
  );
}
