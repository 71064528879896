import React from "react";
import {
    Grid,
    Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getLanguage, getCode } from "../../../languages/getLanguage";
import {
    formatTextShorten,
} from "../../../settings/format";
import { isMobileApp } from "../../../utils/auth";

function Menu() {
    return (
        <Grid item xs={12}>
            <Grid container justify="center" spacing={1} className="pb-2 pt-2 menu-home-page">
                <Grid item xs={false} className="text-center menu-item">
                    <Link to={isMobileApp() ? "/mobile-app/dashboard" : "/dashboard"}>
                        <img src="/images/app-icon/Home.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {
                                getCode() === "en" ?
                                    formatTextShorten(getLanguage("DASHBOARD"), 5) :
                                    getLanguage("DASHBOARD")
                            }
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <Link to={isMobileApp() ? "/mobile-app/dashboard/member" : "/dashboard/member"}>
                        <img src="/images/app-icon/Members.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {
                                getCode() === "en" ?
                                    formatTextShorten(getLanguage("MEMBERS"), 5) :
                                    getLanguage("MEMBERS")
                            }
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <Link to={isMobileApp() ? "/mobile-app/dashboard/binary-tree" : "/dashboard/binary-tree"}>
                        <img src="/images/app-icon/Binarytree.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {getLanguage("BINARY_TREE")}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <Link to={isMobileApp() ? "/mobile-app/dashboard/achievement" : "/dashboard/achievement"}>
                        <img src="/images/app-icon/Achievement.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {
                                getCode() === "en" ?
                                    formatTextShorten(getLanguage("ACHIEVEMENT"), 5) :
                                    getLanguage("ACHIEVEMENT")
                            }
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <Link to={isMobileApp() ? "/mobile-app/dashboard/my-profile" : "/dashboard/my-profile"}>
                        <img src="/images/app-icon/Account.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {getLanguage("ACCOUNT")}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <a target="_blank" href={getLanguage("db_left_menu_link_5")}>
                        <img src="/images/app-icon/BlogFilecoin.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {getLanguage("Filecoin_News")}
                        </Typography>
                    </a>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <a target="_blank" href={getLanguage("db_left_menu_link_3")}>
                        <img src="/images/app-icon/Announcements.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {
                                getCode() === "en" ?
                                    formatTextShorten(getLanguage("ANNOUNCEMENTS"), 5) :
                                    getLanguage("ANNOUNCEMENTS")
                            }
                        </Typography>
                    </a>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <a target="_blank" href={getLanguage("db_left_menu_link_4")}>
                        <img src="/images/app-icon/Knowledge.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {
                                getCode() === "en" ?
                                    formatTextShorten(getLanguage("KNOWLEDGE"), 5) :
                                    getLanguage("KNOWLEDGE")
                            }
                        </Typography>
                    </a>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <a target="_blank" href={getLanguage("db_left_menu_link_1")}>
                        <img src="/images/app-icon/Supportcenter.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {getLanguage("SUPPORT_CENTER")}
                        </Typography>
                    </a>
                </Grid>
                <Grid item xs={false} className="text-center menu-item">
                    <a target="_blank" href={getLanguage("db_left_menu_link_2")}>
                        <img src="/images/app-icon/FAQ.png" alt="" className="mb-2 icon-app" />
                        <Typography component="p">
                            {getLanguage("FAQ")}
                        </Typography>
                    </a>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Menu;