import React, { useState } from "react";
import Navbar from "./common/Navbar";
import Sidebar from "./common/sidebar/Sidebar";
import { Switch, Route } from "react-router-dom";

//#region
import PageNotFound from "../common/page-not-found/Index";
import Client_HomePage from "./home-page/Index";
//import Client_FileCoinPage from "./file-coin-page/Index";
import Client_TermsOfServicesPage from "./terms-of-services-page/Index";
import Client_PrivacyPolicy from "./privacy-policy-page/Index";
import Footer from "./common/footer/Footer";
//import TopNavbar from "./common/TopNavbar";
//#endregion

import "./scss/custom.scss";

function Main() {
  return (
    <div className="homepage">
      {/* <TopNavbar /> */}
      <Sidebar />
      <Navbar />
      <Switch>
        <Route exact path="/terms-of-services" component={Client_TermsOfServicesPage} />
        <Route exact path="/privacy-policy" component={Client_PrivacyPolicy} />
        <Route exact path="/" component={Client_HomePage} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </div>
  );
}

export default Main;
