export const FETCH_USER = "FETCH_USER";
export const GET_AIRDROP = "GET_AIRDROP";
export const GET_STAKING_REFERRAL_REWARDS = "GET_STAKING_REFERRAL_REWARDS";
export const GET_GIFT_REWARDS = "GET_GIFT_REWARDS";
export const CHECK_GA_STATUS = "CHECK_GA_STATUS";
export const FETCH_DEVICE = "FETCH_DEVICE";
export const FETCH_LOGIN_ACTIVITY = "FETCH_LOGIN_ACTIVITY";
export const SET_INFO_VERIFICATION = "SET_INFO_VERIFICATION";
export const SET_LOCAL_IMAGE = "SET_LOCAL_IMAGE";
export const FETCH_FUND_LIST = "FETCH_FUND_LIST";
export const GET_DATA_FOR_COIN_CHART = "GET_DATA_FOR_COIN_CHART";
export const FETCH_TRANSACTION_HISTORY = "FETCH_TRANSACTION_HISTORY";
export const FETCH_SWAP_HISTORY = "FETCH_SWAP_HISTORY";
export const FETCH_WITHDRAW_HISTORY = "FETCH_WITHDRAW_HISTORY";
export const FETCH_DEPOSIT_HISTORY = "FETCH_DEPOSIT_HISTORY";
export const FETCH_SWAP_PRODUCT_LIST = "FETCH_SWAP_PRODUCT_LIST";
export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST";
export const GET_BALANCE = "GET_BALANCE";
export const GET_MY_PACKAGE_LIST = "GET_MY_PACKAGE_LIST";
export const GET_F1_MEMBER = "GET_F1_MEMBER";
export const GET_F1_MEMBER_NOT_IN_BINARY_TREE = `GET_F1_MEMBER_NOT_IN_BINARY_TREE`;
export const GET_F1_MEMBER_IN_BINARY_TREE = `GET_F1_MEMBER_IN_BINARY_TREE`;
export const GET_TRANSACTION_MINE = `GET_TRANSACTION_MINE`;
export const GET_TRANSACTION_MATCHING = `GET_TRANSACTION_MATCHING`;
export const GET_TRANSACTION_DIRECT = `GET_TRANSACTION_DIRECT`;
export const GET_TRANSACTION_BINARY = `GET_TRANSACTION_BINARY`;
export const GET_ACHIEVEMENT = `GET_ACHIEVEMENT`;
export const GET_MINED_BALANCE = `GET_MINED_BALANCE`;
export const FETCH_TRANSFER_HISTORY = `FETCH_TRANSFER_HISTORY`;

export const ranks = {
  NO_RANK: {
    image: `/ranks/Membership.png`,
  },
  MEMBERSHIP: {
    image: `/ranks/Membership.png`,
  },
  PRO: {
    image: `/ranks/Pro.png`,
  },
  MASTER: {
    image: `/ranks/Master.png`,
  },
  COACH: {
    image: `/ranks/Coach.png`,
  },
  SUPERVISOR: {
    image: `/ranks/Supervisor.png`,
  },
  FOUNDER: {
    image: `/ranks/Founder.png`,
  },
};

export const NO_PACKAGE = "NO_PACKAGE";
export const NO_RANK = "NO_RANK";
export const MINING_END_AFTER_DATE = 1000;
export const MINING_END_AFTER_DATE_MEMBER = 90;
export const MINING_START_AFTER_DATE = 15;
export const MINING_START_AFTER_DATE_MEMBER = 0;
