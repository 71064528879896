import React, { useEffect, useState } from "react";
import { _getLoginActivity, _getProfile } from "../../../actions/userActions";
import { getLanguage } from "../../../languages/getLanguage";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import NoData from "../../common/no-data/Index";
import { ENDPOINT_GET_DEPOSIT } from "../../../settings/endpoint";
import { post } from "../../../utils/api";
import ValidateFormatError, {
  validateFormatErrorInput,
} from "../common/ValidateFormatError";
import {
  formatTime,
  formatAddress,
  formatAmount,
} from "../../../settings/format";

function History(props) {
  const { balance } = props;

  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [data, setData] = useState({
    pageCount: 0,
    itemCount: 0,
    page: 0,
    pageSize: 0,
    items: [],
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var textError = [];
    setError(null);
    setLoading(true);

    const param = {
      page: page,
      pageSize: pageSize,
      search: "",
      orderBy: "",
      responseMeta: true,
      filters: {},
    };
    post(
      ENDPOINT_GET_DEPOSIT,
      param,
      (data) => {
        setData(data);
        setLoading(false);
      },
      (error) => {
        textError = [getLanguage(error.code)];
        setError(<ValidateFormatError error={textError} />);
      }
    );
  }, [page, pageSize]);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <div className="card">
      <div className="card-header">
        <b>{getLanguage("History")}</b>
      </div>
      {loading ? (
        <div className="card-body text-center">
          <CircularProgress size={30} />
        </div>
      ) : (
        <TableContainer component={Paper} className="pb-5">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{getLanguage("TIME")}</TableCell>
                <TableCell>{getLanguage("TYPE")}</TableCell>
                <TableCell>{getLanguage("AMOUNT")}</TableCell>
                <TableCell>{getLanguage("ADDRESS")}</TableCell>
                <TableCell>{getLanguage("TRANSACTION_HASH")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.length > 0 ? (
                data.items.map((item, index) => (
                  <TableRow key={index} className="text-nowrap">
                    <TableCell>{formatTime(item.createdTime)}</TableCell>
                    <TableCell>{getLanguage(item.type)}</TableCell>
                    <TableCell>
                      {formatAmount(item.amount)} {item.asset}
                    </TableCell>
                    <TableCell>
                      {item.type === "TRANSFER" ? item.sender : "--/--"}
                    </TableCell>
                    <TableCell>{formatAddress(item.txId)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    {getLanguage("NO_RECORDS_FOUND")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {data.itemCount > pageSize && (
            <TablePagination
              component="div"
              count={data.itemCount}
              page={page - 1}
              rowsPerPage={pageSize}
              onChangePage={_handleChangePage}
              rowsPerPageOptions={[]}
            />
          )}
        </TableContainer>
      )}
    </div>
  );
}

export default History;
