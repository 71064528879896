import React, { useEffect, useState } from "react";
import { getLanguage } from "../../../languages/getLanguage";
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Input,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { checkEmpty, checkPassword } from "../../../settings/validate";
import { get, put } from "../../../utils/api";
import { ENDPOINT_GET_POST_GA_CODE } from "../../../settings/endpoint";
import { CustomSwal } from "../../../settings";
import { _checkEnableGA } from "../../../actions/userActions";
import { useDispatch } from "react-redux";
import ValidateFormatError, {
  validateFormatErrorInput,
} from "../common/ValidateFormatError";
import { _getNewProfile } from "../../../actions/miningActions";
import OtpConfirm from "../../common/otp-confirm/OtpConfirm";

function OnGA(props) {
  const { user, close } = props;
  const { information } = user;
  const { gaEnable } = information;
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [loadedGA, setLoadedGA] = useState(true);
  const [manualEntryKey, setManualEntryKey] = useState("");
  const [qrCodeSetupImageUrl, setQrCodeSetupImageUrl] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [gaCode, setGaCode] = useState("");

  const enableGA = (e) => {
    e.preventDefault();

    //#region validete
    var textError = [];
    setError(null);
    validateFormatErrorInput(e);

    if (checkEmpty(gaCode)) {
      textError.push(getLanguage("PLEASE_ENTER_GA_CODE"));
      validateFormatErrorInput(e, "gaCode");
    }
    if (checkEmpty(password)) {
      textError.push(getLanguage("PLEASE_ENTER_PASSWORD"));
      validateFormatErrorInput(e, "password");
    } else if (checkPassword(password)) {
      textError.push(
        getLanguage("INVALID_PASSWORD", { minChar: 8, maxChar: 32 })
      );
      validateFormatErrorInput(e, "password");
    }
    //#endregion

    if (textError.length > 0) {
      setError(<ValidateFormatError error={textError} />);
    } else {
      setLoading(true);

      const param = {
        gaCode,
        password,
        enable: true,
      };

      put(
        ENDPOINT_GET_POST_GA_CODE,
        param,
        () => {
          CustomSwal("success").then(() => {
            dispatch(_getNewProfile());
            dispatch(_checkEnableGA());
            close();
          });
        },
        (error) => {
          setLoading(false);
          textError = [getLanguage(error.code)];
          setError(<ValidateFormatError error={textError} />);
        }
      );
    }
  };

  useEffect(() => {
    var textError = [];
    get(
      ENDPOINT_GET_POST_GA_CODE,
      (data) => {
        setManualEntryKey(data.manualEntryKey);
        setQrCodeSetupImageUrl(data.qrCodeSetupImageUrl);
        setLoadedGA(false);
      },
      (error) => {
        textError = [getLanguage(error.code)];
        setError(<ValidateFormatError error={textError} />);
      }
    );
  }, []);

  return (
    !gaEnable && (
      <form className={classes.form} onSubmit={(e) => enableGA(e)} noValidate>
        <DialogTitle>
          {getLanguage("TURN_ON")} {getLanguage("GA")}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {loadedGA ? (
              <Grid item xs={12} className="text-center">
                <CircularProgress size={200} />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} className="text-center">
                  <img
                    className={classes.qrImage}
                    src={qrCodeSetupImageUrl}
                    alt="qrcode"
                  />
                </Grid>
                <Grid item xs={12} className="text-center">
                  <Typography variant="h5">{manualEntryKey}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <FormControl required fullWidth>
                <InputLabel>{getLanguage("PASSWORD")}</InputLabel>
                <Input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" className="mb-2"><small>{getLanguage("GA_CODE")}</small></Typography>
              <OtpConfirm
                _handleComplete={(e) => {
                  setGaCode(e);
                }} />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <FormControl error>{error}</FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Button color="secondary" variant="contained" onClick={close}>
              {getLanguage("CANCEL")}
            </Button>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={loading}
              >
                {getLanguage("CONFIRM")}
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </DialogActions>
      </form>
    )
  );
}

export default OnGA;

//#region CSS
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  qrImage: {
    maxWidth: "300px",
    width: "100%",
  },
}));
//#endregion
