import React from "react";
import {
    Grid,
    Typography,
    Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getLanguage, getCode } from "../../../languages/getLanguage";
import {
    formatTextShorten,
} from "../../../settings/format";
import { isMobileApp } from "../../../utils/auth";

function Transactions() {
    return (
        <Grid item xs={12}>
            <Typography variant="h5" className="text-center mb-2">{getLanguage("TRANSACTIONS")}</Typography>
            <Paper variant="outlined" className="p-2 transaction-home-page bg-transparent">
                <Grid container spacing={1} className="pb-2 pt-2">
                    <Grid item xs={false} className="text-center transaction-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/transaction/mining-history" : "/dashboard/transaction/mining-history"}>
                            <img src="/images/app-icon/Mining_history.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {getLanguage("MINING_HISTORY")}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={false} className="text-center transaction-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/transaction/direct-commission" : "/dashboard/transaction/direct-commission"}>
                            <img src="/images/app-icon/Derect_comm.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {
                                    getCode() === "en" ?
                                        formatTextShorten(getLanguage("DIRECT_COMMISSION")) :
                                        getLanguage("DIRECT_COMMISSION")
                                }
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={false} className="text-center transaction-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/transaction/binary-commission" : "/dashboard/transaction/binary-commission"}>
                            <img src="/images/app-icon/binary_comm.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {
                                    getCode() === "en" ?
                                        formatTextShorten(getLanguage("BINARY_COMMISSION")) :
                                        getLanguage("BINARY_COMMISSION")
                                }
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={false} className="text-center transaction-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/transaction/matching-commission" : "/dashboard/transaction/matching-commission"}>
                            <img src="/images/app-icon/Matching_comm.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {
                                    getCode() === "en" ?
                                        formatTextShorten(getLanguage("MATCHING_COMMISSION"), 5) :
                                        getLanguage("MATCHING_COMMISSION")
                                }
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={false} className="text-center transaction-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/transaction/promotion" : "/dashboard/transaction/promotion"}>
                            <img src="/images/app-icon/Promotion.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {
                                    getCode() === "en" ?
                                        formatTextShorten(getLanguage("PROMOTION"), 5) :
                                        getLanguage("PROMOTION")
                                }
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
export default Transactions;