import {
  Grid,
  Container,
  Tab,
  Tabs,
  Typography,
  AppBar,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import SwapForm from "./NewSwapForm";
import SwapHistory from "./SwapHistory";
import { getLanguage } from "../../../languages/getLanguage";
import "./swap.scss";
import { isMobileApp } from "../../../utils/auth";

function Swap() {
  const [value, setValue] = useState(0);
  const tabs = [
    {
      title: getLanguage("SWAP"),
      component: <SwapForm />,
    },
    {
      title: getLanguage("SWAP_HISTORY"),
      component: <SwapHistory />,
    },
  ];

  const _handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="swap">
      <Container maxWidth={isMobileApp() ? false : "md"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-page">
              {getLanguage("SWAP")}
            </Typography>
            <Typography variant="h3" className="mt-5 mb-5 text-center">Comming soon</Typography>
            {/* <div className="card">
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={_handleChange}
                  scrollButtons="auto"
                  className="swap-tabs"
                >
                  {tabs.map((item, index) => (
                    <Tab label={item.title} key={index} value={index} />
                  ))}
                </Tabs>
              </AppBar>
              <div className="card-body p-0">{tabs[value].component}</div>
            </div> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Swap;
