import React, { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { getLanguage } from "../../../languages/getLanguage";
import { Button, Divider, IconButton } from "@material-ui/core";
import LogoImg from "../../assets/images/logo.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  S3_URL,
  getCountryFlags
} from "../../../settings";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { logout } from "../../../utils/auth";
import { Menu, MenuItem } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import i18next from "../../../languages/getLanguage";
import Grid from '@material-ui/core/Grid';

function LeftMenu(props) {
  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down("sm"));

  const { openLeftMenu, toggleDrawer, handleDrawerToggleLeftMenu } = props;
  const classes = useStyles();
  const [isDesktop, setIsDesktop] = useState(true);

  useLayoutEffect(() => {
    setIsDesktop(window.innerWidth > 960);
    window.addEventListener("resize", () => {
      setIsDesktop(window.innerWidth > 960);
    });
  }, []);

  const dataMenu = [
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Dashboard.png`}
          alt=""
        />
      ),
      name: getLanguage("DASHBOARD"),
      to: "/dashboard",
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/PlansMining.png`}
          alt=""
        />
      ),
      name: getLanguage("PLANS"),
      to: "/dashboard/plans",
      children: [
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/BuyPlans_1.png`}
              alt=""
            />
          ),
          name: getLanguage("BUY_PLANS"),
          to: "/dashboard/plans/buy",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/MyPlans.png`}
              alt=""
            />
          ),
          name: getLanguage("MY_PLANS"),
          to: "/dashboard/plans/my",
        },
      ],
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Transactions.png`}
          alt=""
        />
      ),
      name: getLanguage("TRANSACTIONS"),
      to: "/dashboard/transaction",
      children: [
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/MiningHistory.png`}
              alt=""
            />
          ),
          name: getLanguage("MINING_HISTORY"),
          to: "/dashboard/transaction/mining-history",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/DirectCom.png`}
              alt=""
            />
          ),
          name: getLanguage("DIRECT_COMMISSION"),
          to: "/dashboard/transaction/direct-commission",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/BinaryCom.png`}
              alt=""
            />
          ),
          name: getLanguage("BINARY_COMMISSION"),
          to: "/dashboard/transaction/binary-commission",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/MatchingCom.png`}
              alt=""
            />
          ),
          name: getLanguage("MATCHING_COMMISSION"),
          to: "/dashboard/transaction/matching-commission",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/Promotion.png`}
              alt=""
            />
          ),
          name: getLanguage("PROMOTION"),
          to: "/dashboard/transaction/promotion",
        },
      ],
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Members_1.png`}
          alt=""
        />
      ),
      name: getLanguage("MEMBERS"),
      to: "/dashboard/member",
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/BinaryTree.png`}
          alt=""
        />
      ),
      name: getLanguage("BINARY_TREE"),
      to: "/dashboard/binary-tree",
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Achievement.png`}
          alt=""
        />
      ),
      name: getLanguage("ACHIEVEMENT"),
      to: "/dashboard/achievement",
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Account.png`}
          alt=""
        />
      ),
      name: getLanguage("ACCOUNT"),
      to: "/dashboard/my-profile",
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Wallet.png`}
          alt=""
        />
      ),
      name: getLanguage("WALLET"),
      to: "/dashboard/wallet",
      children: [
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/Balance.png`}
              alt=""
            />
          ),
          name: getLanguage("BALANCE"),
          to: "/dashboard/wallet/balance",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/Deposit.png`}
              alt=""
            />
          ),
          name: getLanguage("DEPOSIT"),
          to: "/dashboard/wallet/deposit",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/Withdraw.png`}
              alt=""
            />
          ),
          name: getLanguage("TRANSFER_WITHDRAW"),
          to: "/dashboard/wallet/withdraw",
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/swap.png`}
              alt=""
            />
          ),
          name: getLanguage("SWAP"),
          to: "/dashboard/wallet/swap",
        },
      ],
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/filecoin_news.png`}
          alt=""
        />
      ),
      name: getLanguage("Filecoin_News"),
      to: getLanguage("db_left_menu_link_5"),
      outside: true,
      isBreak: true,
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Knowledge.png`}
          alt=""
        />
      ),
      name: getLanguage("KNOWLEDGE"),
      to: getLanguage("db_left_menu_link_4"),
      outside: true,
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Support_1.png`}
          alt=""
        />
      ),
      name: getLanguage("SUPPORT"),
      children: [
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/SupportCenter.png`}
              alt=""
            />
          ),
          name: getLanguage("SUPPORT_CENTER"),
          to: getLanguage("db_left_menu_link_1"),
          outside: true,
        },
        {
          icon: (
            <img
              className="menu-icon"
              src={`${S3_URL}menu-icon/FAQ.png`}
              alt=""
            />
          ),
          name: getLanguage("FAQ"),
          to: getLanguage("db_left_menu_link_2"),
          outside: true,
        },
      ],
    },
    {
      icon: (
        <img
          className="menu-icon"
          src={`${S3_URL}menu-icon/Announcements.png`}
          alt=""
        />
      ),
      name: getLanguage("ANNOUNCEMENTS"),
      to: getLanguage("db_left_menu_link_3"),
      outside: true,
    },
  ];

  //#region lang
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleLanguageMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (lang) => {
    setAnchorEl(null);
    if (
      lang === "en" ||
      lang === "vi" ||
      lang === "kr" ||
      lang === "de" ||
      lang === "es" ||
      lang === "ru"
    ) {
      i18next.changeLanguage(lang);
    }
  };

  const renderMenuLanguage = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem onClick={() => handleLanguageMenuClose("en")}><img className="mr-2" src={getCountryFlags("gb", 24)} />English</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("vi")}><img className="mr-2" src={getCountryFlags("vn", 24)} />Vietnamese</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("kr")}><img className="mr-2" src={getCountryFlags("kr", 24)} />Korean</MenuItem>
      {/* <MenuItem onClick={() => handleLanguageMenuClose("de")}><img className="mr-2" src={getCountryFlags("de", 24)} />German</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("es")}><img className="mr-2" src={getCountryFlags("es", 24)} />Spanish</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("ru")}><img className="mr-2" src={getCountryFlags("ru", 24)} />Russian</MenuItem> */}
    </Menu>
  );
  //#endregion

  return (
    <Drawer
      variant={isDesktop ? "persistent" : "temporary"}
      anchor="left"
      open={isDesktop ? true : openLeftMenu}
      className={
        isDesktop
          ? clsx(classes.drawer, {
            [classes.drawerOpen]: openLeftMenu,
            [classes.drawerClose]: !openLeftMenu,
          })
          : ""
      }
      classes={
        isDesktop
          ? {
            paper: clsx({
              [classes.drawerOpen]: openLeftMenu,
              [classes.drawerClose]: !openLeftMenu,
            }),
          }
          : {}
      }
      onClose={handleDrawerToggleLeftMenu}
    >
      <Toolbar
        style={{
          borderBottom: "3px solid #37c0ca",
        }}
      >
        <Link to="/dashboard">
          <img
            src={LogoImg}
            alt="Logo"
            className={classes.logo}
            style={{ width: 180, marginBottom: 6, marginTop: 6 }}
          />
        </Link>
      </Toolbar>
      <div className={clsx(classes.drawerContainer, "left-menu-db")}>
        <List component="nav" className={clsx(classes.root, isDesktop ? "" : classes.socialDb)}>
          <IconButton
            onClick={handleDrawerToggleLeftMenu}
            className="icon-open-closed"
            size="small"
          >
            {openLeftMenu ? (
              <ArrowBackIosIcon
                color="primary"
                fontSize="small"
                className="opacity-06"
              />
            ) : (
              <ArrowForwardIosIcon
                color="primary"
                fontSize="small"
                className="opacity-06"
              />
            )}
          </IconButton>
          {dataMenu.map((item, index) => (
            <LeftMenuItem
              key={index}
              toggleDrawer={toggleDrawer}
              item={item}
              handleDrawerToggleLeftMenu={handleDrawerToggleLeftMenu}
              openLeftMenu={openLeftMenu}
            />
          ))}
          {screen && (
            <ListItem component="div" className="mt-5">
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4}>
                  <IconButton onClick={handleLanguageMenuOpen}>
                    <LanguageIcon color="primary" className="ml-2" />
                  </IconButton>
                  {renderMenuLanguage}
                </Grid>
                <Grid item xs={8}>
                  <Button
                    onClick={logout}
                    variant="outlined"
                    color="primary"
                    className="m-auto"
                    fullWidth
                  >
                    {getLanguage("LOGOUT")}
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          )}
        </List>
        {
          openLeftMenu &&
          <div className={clsx(classes.socialDb, "social-db bg-box border-top")}>
            <ul>
              <li>
                <a
                  href="https://twitter.com/MiningClub01"
                  target="_blank"
                >
                  <img src="/images/icon-social/icon-item-1.png" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCUyk2K1bHEfpxFXRZymiNzg"
                  target="_blank"
                >
                  <img src="/images/icon-social/icon-item-2.png" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/miningclub_community"
                  target="_blank"
                >
                  <img src="/images/icon-social/icon-item-3.png" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/miningclubglobal"
                  target="_blank"
                >
                  <img src="/images/icon-social/icon-item-4.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        }
      </div>
    </Drawer>
  );
}

export default LeftMenu;

function LeftMenuItem(props) {
  const { item, toggleDrawer, handleDrawerToggleLeftMenu, openLeftMenu } =
    props;
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = React.useState(false);
  const [activeClass, setActiveClass] = React.useState("");
  const history = useHistory();

  useEffect(() => {
    if (!openLeftMenu) setOpen(false);
  }, [openLeftMenu]);

  const handleClick = () => {
    setOpen(!open);
    if (!openLeftMenu) {
      handleDrawerToggleLeftMenu();
    }
  };

  async function activeMenu() {
    //#region open children
    if (item.children) {
      item.children.forEach((el) => {
        const to = `${el.to}`;
        if (pathname === to && openLeftMenu) {
          setOpen(true);
        }
      });
    }
    //#endregion

    const link = `${item.to}`;
    //#region active menu
    if (pathname === link) {
      setActiveClass("active");
    } else {
      setActiveClass("");
    }
    //#endregion
  }

  useEffect(() => {
    activeMenu();
  });

  return item.children ? (
    <div className={item.isBreak && "mt-5"}>
      <ListItem button onClick={handleClick} className={clsx(classes.item)}>
        <ListItemIcon className={clsx(classes.icon)}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={clsx(classes.line)}>
          {item.children.map((item, index) => (
            <LeftMenuItem
              item={item}
              key={index}
              isChildren={true}
              toggleDrawer={toggleDrawer}
            />
          ))}
        </List>
      </Collapse>
    </div>
  ) : item.outside ? (
    <a
      className={clsx(classes.linkButton, activeClass, item.isBreak && "mt-5")}
      onClick={() => toggleDrawer()}
      href={item.to}
      target="_blank"
    >
      <ListItem
        button
        className={clsx(
          classes.item,
          props.isChildren ? classes.nested : "",
          props.isChildren ? classes.lineItem : ""
        )}
      >
        <ListItemIcon className={clsx(classes.icon)}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    </a>
  ) : (
    <Link
      className={clsx(classes.linkButton, activeClass, item.isBreak && "mt-5")}
      onClick={() => toggleDrawer()}
      to={item.to}
    >
      <ListItem
        button
        className={clsx(
          classes.item,
          props.isChildren ? classes.nested : "",
          props.isChildren ? classes.lineItem : ""
        )}
      >
        <ListItemIcon className={clsx(classes.icon)}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    </Link>
  );
}

const drawerWidth = 310;
const useStyles = makeStyles((theme) => ({
  socialDb: {
    width: `${drawerWidth}px !important`,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    border: "none",
    backgroundColor: "#17222d",
    color: "#fff",
  },
  drawerContainer: {
    backgroundColor: "transparent",
  },
  root: {
    width: "100%",
    maxWidth: drawerWidth,
    backgroundColor: "transparent",
    paddingTop: "50px",
    paddingBottom: "80px",
    overflowX: "hidden",
  },
  icon: {
    minWidth: "inherit",
    marginRight: 25,
    color: "rgba(255,255,255,0.87)",
    wordBreak: "keep-all",
  },
  item: {
    padding: "5px 20px",
    outline: "none",
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  linkButton: {
    textDecoration: "none",
    display: "block",
    "&:hover": {
      textDecoration: "none",
      fontWeight: "bold",
    },
    "&.active": {
    },
  },
}));
