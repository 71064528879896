import React from "react";
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Button,
  Container,
  useMediaQuery,
} from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";
import { S3_URL } from "../../../settings";
import { formatAmount, formatUSD } from "../../../settings/format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomLoading from "../common/CustomLoading";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

export default function BalancePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { mining } = useSelector((state) => state);
  const { balances, minedBalances } = mining;

  return balances && minedBalances ? (
    <Container maxWidth={isMobileApp() ? false : "sm"}>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography variant="h5" className="title-page">
            {getLanguage("BALANCE")}
          </Typography>
          {minedBalances &&
            minedBalances.map((item, index) => (
              <Paper key={index} variant="outlined" className="p-3 mb-3">
                <Grid
                  container
                  alignItems="center"
                  className="mb-3"
                  justify="space-between"
                >
                  <Grid item>
                    <Typography variant="h5" style={{ fontWeight: 300 }}>
                      {getLanguage("MINING_BALANCE_COIN", {
                        coin: item.asset,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item className="text-right">
                    <img
                      src={`${S3_URL}/coins/${item.asset}.png`}
                      style={{ width: 30 }}
                      alt={item.asset}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="flex-start">
                  <Grid item xs={(isMobileApp() || isMobile) ? 12 : 6}>
                    <Typography variant="h6">
                      {formatAmount(item.totalMined)}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      <small>$</small>
                      {formatUSD(item.totalMined * item.usdPrice)}
                    </Typography>
                  </Grid>
                  {
                    (isMobileApp() || isMobile) &&
                    <Grid xs={12} item className="mt-2 mb-2">
                      <Divider />
                    </Grid>
                  }
                  <Grid item xs={(isMobileApp() || isMobile) ? 6 : 3} className="text-center">
                    <small>{getLanguage("RELEASED")}</small>
                    <Typography variant="subtitle1" color="primary">
                      {formatAmount(item.totalRelease)}
                    </Typography>
                  </Grid>
                  <Grid item xs={(isMobileApp() || isMobile) ? 6 : 3} className="text-center">
                    <small>{getLanguage("BLOCKED")}</small>
                    <Typography variant="subtitle1" color="primary">
                      {formatAmount(item.blockAmount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          {balances &&
            balances.map((item, index) => (
              <Paper key={index} variant="outlined" className="p-3 mb-3">
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 300 }}
                      className="text-nowrap"
                    >
                      <img
                        src={`${S3_URL}/coins/${item.asset}.png`}
                        style={{ width: 50 }}
                        alt={item.asset}
                        className="mr-3"
                      />
                      {item.asset}
                    </Typography>
                    <Typography
                      variant="h6"
                      className="mt-3 mb-0"
                    >
                      {formatAmount(item.amount)}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      <small>$</small>
                      {formatUSD(item.amount * item.usdPrice)}
                    </Typography>
                  </Grid>
                  <Grid item align="right" xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Link
                          to={
                            isMobileApp() ?
                              `/mobile-app/dashboard/wallet/deposit/${item.asset}` :
                              `/dashboard/wallet/deposit/${item.asset}`
                          }
                        >
                          <Button variant="contained" color="primary" style={{ width: '140px' }}>
                            {getLanguage("DEPOSIT")}
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <Link
                          to={
                            isMobileApp() ?
                              `/mobile-app/dashboard/wallet/withdraw/${item.asset}` :
                              `/dashboard/wallet/withdraw/${item.asset}`
                          }
                        >
                          <Button variant="outlined" color="primary" style={{ width: '140px' }}>
                            {getLanguage("WITHDRAWAL")}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </Grid>
      </Grid>
    </Container>
  ) : (
    <CustomLoading />
  );
}
