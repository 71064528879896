export const vi = {
  ID: "ID",
  DASHBOARD: "Bảng điều khiển",
  ANNOUNCEMENTS: "Thông báo",
  SUPPORT: "Hỗ trợ",
  ENGLISH: "Tiếng Anh",
  VIETNAMESE: "Tiếng Việt",
  KOREAN: "Tiếng Hàn",
  HOME: "Trang chủ",
  ABOUT_US: "Về chúng tôi",
  WHY_MINING: "Tại sao lại là mining?",
  WHAT_IS_FIL_COIN: "Filecoin là gì?",
  HOW_TO: "Cách tham gia",
  KNOWLEDGE: "Kiến thức",
  SIGN_UP: "Đăng ký",
  LOGIN: "Đăng nhập",
  LOGOUT: "Đăng xuất",
  USERNAME: "Tên đăng nhập",
  PASSWORD: "Mật khẩu",
  SIGN_IN: "Đăng nhập",
  FORGOT_PASSWORD: "Quên mật khẩu",
  SIGN_UP_1: "Không có tài khoản? Đăng ký",
  MINING_CLUB: "Mining Club",
  MEDIA: "Phương tiện truyền thông",
  PARTNER: "Cộng sự",
  ACHIEVEMENT: "Thành tựu",
  PLANS: "Kế hoạch khai thác",
  BEST_WAY_TO_MINING: `Cách tốt nhất để khai thác <span class='text-nowrap'>[[0]] Filecoin</span>`,
  BUY_PLANS: "Mua kế hoạch khai thác",
  MY_PLANS: "Kế hoạch khai thác của tôi",
  TRANSACTIONS: "Báo cáo",
  ACCOUNT: "Tài khoản",
  MEMBERS: "Danh sách thành viên",
  BINARY_TREE: "Cây cộng đồng",
  MINING_HISTORY: "Lịch sử khai thác",
  MATCHING_COMMISSION: "Hoa hồng cộng đồng",
  DIRECT_COMMISSION: "Hoa hồng môi giới",
  BINARY_COMMISSION: "Hoa hồng doanh số",
  WALLET: "Ví",
  DEPOSIT: "Nạp tiền",
  TRANSFER: "Chuyển",
  WITHDRAWAL: "Rút tiền",
  AVAILABLE: "Có sẵn",
  SUPPORT_CENTER: "Trung tâm hỗ trợ",
  FAQ: "Câu hỏi thường gặp",
  WHAT_IS_MINING: "Mining là gì?",
  NEXT_PAYOUT: "Lần chi trả tiếp theo",
  INVITED_FRIEND: "Mời bạn bè, nhận tiền mã hoá",
  Referral_Link: "Liên kết mã giới thiệu",
  PROMOTION: "Ưu đãi",
  BALANCE: "Số dư chung",
  BALANCE_COIN: "Số dư {{coin}}",
  TRANSFER_WITHDRAW: "Chuyển/ Rút tiền mã hoá",
  RANK: "Hạng",
  RANK_INFO: "Thông tin hạng",
  MY_PACKAGE: "Gói khai thác của tôi",
  PAYOUT_FROM_REFERRAL_PLAN: "Chi trả phần thưởng khai thác",
  MINING_BALANCE: "Số dư khai thác",
  MINING_BALANCE_COIN: "Số dư khai thác {{coin}}",
  CONFIRMING_FOR_BUY_PLAN: "Xác nhận mua gói khai thác",
  RELEASED: "Phát hành",
  Enroll_new_member: "Đăng ký thành viên mới",
  PERSONAL_INFORMATION: `Thông tin cá nhân`,
  PERSONAL_DOCUMENTS: `Tài liệu cá nhân`,
  LEFT: "Trái",
  RIGHT: "Phải",
  PACKAGE: "Gói khai thác",
  TOTAL_MINED: "Tổng số đã khai thác",
  START_TIME: "Thời gian bắt đầu",
  END_TIME: "Thời gian kết thúc",
  FREE: "Tự do",
  CREATE_MEMBER: "Tạo thành viên",
  SECURITY: "Bảo mật",
  PROFILE: "Hồ sơ",
  SPONSOR: "Bảo trợ",
  YOUR_SPONSOR: "Người bảo trợ của bạn",
  IDENTITY_VERIFICATION: "Xác minh danh tính",
  GA: "Xác thực Google",
  LOGIN_PASSWORD: "Mật khẩu đăng nhập",
  VERIFY: "Xác minh",
  TURN_ON: "Bật",
  CHANGE: "Thay đổi",
  AMOUNT: "Số tiền",
  BLOCK: "Khóa",
  CREATED_TIME: "Thời gian tạo",
  MINE_TIME: "Thời gian khai thác",
  UNBLOCK_TIME: "Thời gian mở khoá",
  TIME: "Thời gian",
  LAST_LOGIN: "Lần đăng nhập cuối",
  NETWORK: "Mạng lưới",
  HISTORY: "Lịch sử",
  TRANSACTION_FEE: "Phí giao dịch",
  YOU_WILL_GET: "Bạn sẽ nhận được",
  CONTINUE: "Tiếp tục",
  ASSET: "Tài sản",
  CONFIRM: "Xác nhận",
  FIRST_NAME: "Tên",
  LAST_NAME: "Họ",
  MIDDLE_NAME: "Tên đệm",
  DOB: "Ngày sinh",
  COUNTRY: "Quốc gia",
  SELECT_COUNTRY: "Chọn quốc gia",
  CITY: "Thành phố",
  POSTAL_CODE: "Mã bưu điện",
  START_DATE: "Ngày bắt đầu",
  END_DATE: "Ngày kết thúc",
  PRICE: "Giá",
  PAYMENT: "Thanh toán",
  SignIn_Already_have_an_account: "Đã có tài khoản? Đăng nhập",
  CheckBox_PrivacyPolicy: "Tôi đồng ý với các quy định của [[0]] | [[1]].",
  Terms_and_Conditions: "Điều khoản và Điều kiện",
  Privacy_Policy: "Chính sách bảo mật",
  PURCHASE_NOW: "Mua ngay",
  LAST_MINED: "Lần khai thác cuối cùng",
  MINIMUM_WITHDRAW_AMOUNT: "Số tiền mã hoá rút tối thiểu",
  PLEASER_CHECK_TOS: "Vui lòng đọc và đồng ý với Điều khoản MiningClub",
  REGISTER: "Đăng ký",
  Sponsor_Username: "Tên người bảo trợ",
  SELECT_ASSET: "Chọn nội dung",

  Lasts_for_days: "Kéo dài trong {{number}} ngày",
  Start_after_days: "Bắt đầu sau {{number}} ngày",
  Days_of_mining: "{{number}} ngày khai thác",
  No_profit_from_mining: "Không có lợi nhuận từ khai thác",
  Reservation: "Giữ chỗ",
  EMAIL_ADDRESS: "Địa chỉ email",
  PlansMining: "Plans mining",


  NO_PACKAGE: "Không có",
  ADDRESS: "Địa chỉ",
  ID_CODE: "Số của giấy tờ tuỳ thân",
  ID_TYPE: "Chọn loại giấy tờ tuỳ thân",
  SWAP: "Chuyển đổi",
  SWAP_HISTORY: "Lịch sử chuyển đổi",
  PLEASER_ENTER_BASE_AMOUNT: "Vui lòng nhập số tiền chuyển đổi",
  FEE: "Phí",
  SEND: "Gửi",
  RECEIVE: "Nhận",


  UPLOAD_PHOTO: "Tải ảnh lên",
  Front_ID_card: "Mặt trước",
  Back_ID_card: "Mặt sau",
  Address_verify: "Xác minh địa chỉ",
  PASSPORT: "Hộ chiếu",
  ID_CARD: "Thẻ ID",
  CREATE_MEMBER_SUCCESS: "Đăng kí thành công",
  MINIMUM: "Tối thiểu",



  PASSWORD_WRONG: "Mật khẩu không chính xác",
  PLEASE_ENTER_SPONSOR_ID: "Vui lòng nhập ID người bảo trợ của bạn",
  PLEASE_ENTER_EMAIL: "Vui lòng nhập địa chỉ email",
  PLEASE_ENTER_USERNAME: "Vui lòng điền tên đăng nhập",
  PLEASE_ENTER_LAST_NAME: `Vui lòng nhập họ của bạn`,
  PLEASE_ENTER_FIRST_NAME: `Vui lòng nhập tên của bạn`,
  PLEASE_ENTER_PASSWORD: "Vui lòng nhập mật khẩu",
  PLEASE_ENTER_REPEAT_PASSWORD: "Vui lòng nhập lại mật khẩu",
  INVALID_REPEAT_PASSWORD: "Mật khẩu lặp lại không chính xác",
  PLEASE_ENTER_REFERRAL: "Vui lòng nhập mã giới thiệu",
  CREATE_NEW_ACCOUNT: "Đăng kí tài khoản mới",
  HAVE_A_ACCOUNT: "Đã có tài khoản",
  INVALID_EMAIL: "Email không hợp lệ",
  INVALID_PASSWORD: "Mật khẩu không hợp lệ, mật khẩu phải chứa từ {{minChar}} đến {{maxChar}} ký tự, chữ hoa, chữ thường, số, ký tự đặc biệt và không có dấu cách",
  INVALID_USERNAME: "Tên người dùng phải dài {{minChar}} ký tự và không quá {{maxChar}} ký tự",

  RESET_PASSWORD: "Đặt lại mật khẩu",
  SEND_OTP: "Gửi OTP",
  PLEASE_ENTER_OLD_PASSWORD: "Vui lòng nhập mật khẩu cũ",
  PLEASE_ENTER_NEW_PASSWORD: "Vui lòng nhập mật khẩu mới",
  PLEASE_ENTER_CONFIRM_PASSWORD: "Vui lòng xác nhận mật khẩu",
  CONFIRM_NEW_PASSWORD: "Xác nhận mật khẩu mới",
  UPDATE_PASSWORD: "Cập nhật mật khẩu",
  EMAIL_OTP: "Email OTP",
  PLEASE_ENTER_OTP: "Vui lòng nhập mã OTP của bạn từ email",
  REPEAT_PASSWORD: "Lặp lại mật khẩu",
  PLEASE_ENTER_DOB: `Vui lòng nhập ngày sinh`,
  PLEASE_CHOOSE_DOCUMENT_TYPE: "Vui lòng chọn loại giấy tờ tuỳ thân",
  PLEASE_ENTER_DOCUMENT_NUMBER: `Vui lòng nhập số của giấy tờ tuỳ thân`,
  PLEASE_ENTER_YOUR_ADDRESS: `Vui lòng nhập địa chỉ`,
  PLEASE_ENTER_COUNTRY: `Vui lòng chọn Thành Phố`,
  PLEASE_ENTER_CITY: `Vui lòng nhập Thành Phố`,
  PLEASE_ENTER_POSTAL_CODE: `Vui lòng nhập mã bưu điện`,
  GA_CODE: "Mã xác thực Google",
  PLEASE_ENTER_GA_CODE: "Vui lòng nhập mã xác thực của Google",
  CANCEL: "Huỷ",
  TURN_OFF: "Tắt",
  SUCCESS: "Thành công",
  Search_username: "Tìm kiếm tên người dùng",
  ACCOUNT_EXIST: "Tài khoản đã tồn tại",
  HI_USERNAME: `Chào {{username}},`,
  WARNING_WITHDRAW: `Bạn cần xác minh tài khoản của mình trước khi rút tiền.`,
  CLOSE: "Đóng",
  VERIFY_ACCOUNT: "Xác nhận tài khoản",
  BLOCKED: "Khoá",
  Registered_successfully: "Đã đăng ký thành công",
  ACCOUNT_EXPIRED: "Tài khoản hết hạn",
  POWER: "Sức mạnh",










  BEST_WAY_TO_MINING_FILECOIN: "Cách tốt nhất để khai thác Filecoin",
  home_banner_text: "Chúng tôi đang khai thác giao thức internet thế hệ tiếp theo, Filecoin. Chúng tôi xây dựng nền tảng kết nối khai thác tiền mã hoá đơn giản, dễ sử dụng và hiệu quả cao cho bạn.",
  introHome_title_1: "Phần cứng của bạn đang chạy",
  introHome_title_2: "Khai thác các loại tiền mã hoá thay thế",
  introHome_title_3: "Nhận khai thác của bạn ngay hôm nay",
  introHome_text_1: "Đừng vật lộn với việc lắp ráp máy đào với những chi phí phát sinh và tiêu hao năng lượng tại nhà. chúng tôi có phần cứng khai thác Filecoin tốt nhất dành cho bạn!",
  introHome_text_2: "Bạn có thể khai thác bất kỳ loại tiền mã hoá nào có sẵn trong danh mục của chúng tôi! chuyển đổi sức mạnh khai thác của bạn ngay lập tức cho các đồng tiền mã hoá bằng cách sử dụng nền tảng của Mining Club.",
  introHome_text_3: "Bạn sẽ nhận được kết quả khai thác định kỳ vào ví chỉ định của mình. Hãy thử nền tảng khai thác đám mây của chúng tôi ngay bây giờ!",
  why_mining_club: "Tại sao lại là MINING CLUB?",
  why_mining_title: "Chúng ta đang làm cho việc khai thác có thể tiếp cận đến tất cả mọi người",
  why_mining_text: "Chúng tôi đang thống nhất tất cả các khía cạnh chính của việc vận hành hoạt động khai thác tiền mã hoá hiệu quả. Từ việc tìm kiếm và kết nối các trung tâm dữ liệu hiệu quả cao đến việc cung cấp hệ thống khai thác hợp lý cho người dùng của chúng tôi.",
  why_mining_title_card_1: "Chúng tôi làm cho việc khai thác trở nên cạnh tranh",
  why_mining_title_card_2: "Một cộng đồng sôi động hỗ trợ lẫn nhau",
  why_mining_title_card_3: "Bảo vệ dữ liệu trong khi vẫn bảo vệ được hành tinh",
  why_mining_text_card_1: "Bạn cần đầu tư đáng kể thời gian và tiền bạc để có thể thiết lập hệ thống khai thác tại nhà. Chúng tôi đã kết nối với các cơ sở khai thác hiệu quả nhất có thể làm việc ngay cho bạn thông qua nền tảng Mining Club. Bằng cách này, bạn có thể hoàn toàn tập trung vào việc theo dõi thị trường và phần thưởng khai thác mà không phải lo lắng về bảo trì và vận hành hệ thống.",
  why_mining_text_card_2: "Từ các giàn khai thác nhỏ đến các hoạt động trung tâm dữ liệu tiên tiến, Filecoin có một cộng đồng khai thác sôi động gồm các doanh nhân và doanh nghiệp tận tâm giúp đỡ nhau làm cho hoạt động của họ hiệu quả hơn, đáng tin cậy hơn và mang lại lợi nhuận.",
  why_mining_text_card_3: "Filecoin dựa vào lưu trữ hữu ích để bảo mật mạng, không như nhiều blockchain khác sử dụng bằng chứng công việc một cách lãng phí. Điều này làm cho Filecoin trở thành một trong những blockchain thân thiện với môi trường đầu tiên.",
  features_title: "Các tính năng của MINING CLUB",
  features_card_title_1: "Khai thác trên nền tảng đám mây",
  features_card_title_2: "Khai thác Filecoin",
  features_card_title_3: "Bảo mật & Riêng tư",
  features_card_title_4: "Công nghệ khai thác cạnh tranh",
  features_card_title_5: "Giao diện quản lý trực quan",
  features_card_title_6: "Phân phối khai thác hàng ngày",
  features_card_text_1: "Là quá trình khai thác tiền điện tử bằng cách sử dụng một trung tâm dữ liệu từ xa, nơi khách hàng chỉ cần đăng ký và mua các hợp đồng khai thác với sức mạnh xử lý được chia sẻ. Loại hình khai thác trên đám mây này cho phép người dùng khai thác tiền điện tử mà không cần quản lý phần cứng.",
  features_card_text_2: "Mạng Filecoin đạt được quy mô kinh tế đáng kinh ngạc bằng cách cho phép mọi người tham gia với tư cách là nhà cung cấp lưu trữ và kiếm tiền từ dung lượng ổ cứng mở của họ.",
  features_card_text_3: "Mining Club sẽ khai thác tiền điện tử cho bạn với tỷ lệ cạnh tranh toàn cầu và ở mức hiệu quả tối đa, đảm bảo rằng bạn nhận được phần thưởng khai thác cạnh tranh nhất có thể!",
  features_card_text_4: "Sử dụng thiết bị khai thác hiệu quả cao được thiết kế đặc biệt để khai thác tiền mã hoá.",
  features_card_text_5: "Tất cả dữ liệu và biểu đồ khai thác tiền điện tử bạn cần, bạn có thể dễ dàng theo dõi danh mục khai thác của mình mỗi ngày.",
  features_card_text_6: "Tự động phân phối kết quả khai thác vào tài khoản của bạn mỗi ngày và có thể rút tiền trên chuỗi bất kỳ lúc nào.",
  tutorial_title: "Cách tham gia cộng đồng Mining Club chỉ với 4 bước đơn giản",
  tutorial_card_title_1: "Tạo một tài khoản",
  tutorial_card_title_2: "Mua kế hoạch khai thác",
  tutorial_card_title_3: "Hãy để chúng tôi khai thác tiền mã hoá cho bạn",
  tutorial_card_title_4: "Kiếm tiền điện tử và xây dựng tương lai của bạn",
  tutorial_card_text_1: "Đăng ký bằng cách cung cấp tên người dùng của người giới thiệu của bạn, sau đó nhập tên người dùng và chi tiết liên hệ của bạn! Quá dễ dàng",
  tutorial_card_text_2: "Chọn kế hoạch khai thác phù hợp nhất cho bạn mà không có phí thành viên hoặc bất kì chi phí ẩn nào! Bạn còn chờ gì nữa? hãy để chúng tôi giúp bạn tham gia vào thế giới tiền mã hoá!",
  tutorial_card_text_3: "Mining Club sẽ khai thác tiền mã hoá cho bạn với tỷ lệ cạnh tranh toàn cầu và ở mức hiệu quả tối đa, đảm bảo rằng bạn nhận được phần thưởng khai thác cạnh tranh nhất có thể!",
  tutorial_card_text_4: "Chúng tôi phân phối phần thưởng khai thác cũng như phần thưởng giới thiệu một cách thường xuyên. Kiểm tra tiềm năng khai thác của bạn dựa trên kế hoạch khai thác!",

  title_footer_1: "Theo dõi chúng tôi tại",
  title_footer_2: "Điều khoản & Chính sách",
  title_footer_3: "Kiến thức",
  item_footer_1: "Luôn cập nhật các hoạt động của Mining Club.",
  item_footer_2: "Điều khoản dịch vụ",
  item_footer_3: "Chính sách bảo mật",
  item_footer_4: "Khai thác là gì?",
  item_footer_5: "Filecoin là gì?",
  text_footer: "Thông tin trên trang web này không truyền đạt một lời đề nghị dưới bất kỳ hình thức nào và không nhằm mục đích cũng như không được hiểu là một lời đề nghị bán, hoặc lời mời chào mua, bất kỳ chứng khoán, hàng hóa, hoặc các sản phẩm tài chính khác. Ngoài ra, thông tin trên trang web này không cấu thành việc cung cấp lời khuyên đầu tư. Không có đảm bảo rằng bất kỳ mục tiêu, giả định, kỳ vọng, chiến lược và / hoặc mục tiêu được thể hiện hoặc ngụ ý ở đây đã hoặc sẽ được thực hiện hoặc các hoạt động hoặc bất kỳ hiệu suất nào được mô tả đã hoặc sẽ tiếp tục như được mô tả trên trang web này.",

  blog_title: "Blog filecoin",
  blog_read_more_button: "Đọc thêm",

  partner_title: "Chúng tôi làm việc với những tổ chức và cộng đồng của những người đang xây dựng một tương lai tốt đẹp hơn thông qua công nghệ.",

  menu_header_link_1: "https://kienthuc.miningclub.org/mining-khai-thac-la-gi",
  menu_header_link_2: "https://kienthuc.miningclub.org/filecoin-la-gi/filecoin-la-gi-1/filecoin-la-gi",
  menu_footer_link_1: "https://kienthuc.miningclub.org/mining-khai-thac-la-gi",
  menu_footer_link_2: "https://kienthuc.miningclub.org/filecoin-la-gi/filecoin-la-gi-1/filecoin-la-gi",

  db_left_menu_link_1: "https://hotro.miningclub.org",
  db_left_menu_link_2: "https://cauhoi.miningclub.org",
  db_left_menu_link_3: "https://thongbao.miningclub.org",
  db_left_menu_link_4: "https://kienthuc.miningclub.org",
  db_left_menu_link_5: "https://tintuc-filecoin.miningclub.org",


  Filecoin_News: "Tin tức Filecoin",


  db_slide_banner_1: "https://hotro.miningclub.org/mua-ke-hoach-khai-thac/dang-ki-ngay-chi-voi-150usd",
  db_slide_banner_2: "https://hotro.miningclub.org/hoahong-capbac/untitled",
  db_slide_banner_3: "https://thongbao.miningclub.org/thong-bao-moi/welcome-launch-promotion",
  db_slide_banner_4: "https://thongbao.miningclub.org/thong-bao-moi/welcome-launch-promotion",


  verify_tab_description_1: "Nhập thông tin cá nhân",
  verify_tab_description_2: "ID/Hộ chiếu/Giấy phép lái xe",
  verify_tab_description_3: "Chụp ảnh chân dung",
  verify_tab_description_4: "Kiểm tra lại thông tin",
  verify_tab_menuItem_1: "Thông tin",
  verify_tab_menuItem_2: "Ảnh mặt trước",
  verify_tab_menuItem_3: "Ảnh mặt sau",
  verify_tab_menuItem_4: "Ảnh chân dung",


  KYC_NOTIFICATION: "Xác minh danh tính của tài khoản này đang chờ xử lý hoặc đang xác minh",



  Dark_Mode: "Chế độ màu tối",
  Light_Mode: "Chế độ màu sáng",
  Purple_Mode: "Chế độ màu tím",
  Orange_Mode: "Chế độ màu cam",

  SWAP_CPU_BELOW_MINIMUM: "Hoán đổi tối thiếu là {{num}} CPU",
  BASE_AMOUNT_GREATER_MIN: "Vui lòng nhập số tiền lớn hơn số tiền tối thiểu",
  SWAP_CONFIRM: "Xác nhận hoán đổi",
  INSUFFICIENT_FUNDS: "Số dư không có sẵn",
  UNAVAILABLE_BALANCE: "Số dư không có sẵn",


  YOU_NEED_ENABLE_GA: "Bạn cần kích hoạt trình xác thực google",
  JUST_TRANSFER_100_DOLLAR_WHEN_NO_VERIFICATION: "Chỉ cần chuyển $ 100 khi không có xác minh",
  USE_TRANSFER_SERVICE: "Sử dụng dịch vụ chuyển",
  USE_WITHDRAW_SERVER_YOU_NEED: "Sử dụng máy chủ rút tiền bạn cần",
  ENABLE_GOOGLE_AUTHENTICATION: "Bật xác thực google",
  WITHDRAW_DISABLE: "Rút tiền đã bị vô hiệu hóa. Vui lòng thử lại sau.",
  PLEASE_ENTER_ADDRESS: "Vui lòng nhập địa chỉ",
  PLEASE_ENTER_AMOUNT: "Vui lòng nhập số tiền",
  YOUR_AMOUNT_TOO_LOW: "Số tiền của bạn quá thấp. Tối thiểu: {{num}} {{coin}}",
  INVALID_ADDRESS: "Địa chỉ ví không hợp lệ",

  I_send: "Tôi gửi",
  I_receive: "Tôi nhận",
  ENTER_AMOUNT: "Nhập số tiền",
  USER_NO_PACKAGE: "Người dùng không có gói",
  TRANSFER_LIMIT: "Hạn mức chuyển khoản",
  GO_TO_DASHBOARD: "Đến bảng điều khiển",
  ACCOUNT_NOT_FOUND: "Tài khoản không được tìm thấy",
  PURCHASE: "Mua",




};