import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ACCESS_TOKEN_KEY, SCOPES_KEY } from "../../settings";
import { setAccessToken } from "../../utils/auth";
import CustomLoading from "./common/CustomLoading";
import { isMobileApp } from "../../utils/auth";

export default function LoginByToken() {
  const { token } = useParams();

  useEffect(() => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(SCOPES_KEY);
    setAccessToken(token);
    window.location.href = isMobileApp() ? "/mobile-app/dashboard" : "/dashboard";
  }, [token]);

  return <CustomLoading />;
}
