import React from "react";
import Img404 from "../../assets/images/img404.svg";
import './style.scss';

function Index() {
  return (
    <div className="page404">
      <img src={Img404} alt="Logo" className="img404" />
      <div className="content">
        <h1>404</h1>
        <p>PAGE NOT FOUND</p>
      </div>
    </div>
  )
}
export default Index;
