import React from "react";
import {
    Container,
    Grid,
} from "@material-ui/core";

import Carousel from "../../dashboard-areas/home-page/Carousel";
import HomeInfo from "../../dashboard-areas/home-page/HomeInfo";

import PlansMining from "./PlansMining";
import Transactions from "./Transactions";
import Wallet from "./Wallet";
import Menu from "./Menu";

function Index() {
    return (
        <div className="home-page">
            <div className="banner">
                <Carousel />
            </div>
            <Container maxWidth={false}>
                <Grid container spacing={2} alignItems="center">
                    <HomeInfo />
                    <PlansMining />
                    <Transactions />
                    <Wallet />
                    <Menu />
                </Grid>
            </Container>
        </div>
    )
}
export default Index;