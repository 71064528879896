import React, { useState } from "react";
import { getLanguage } from "../../../languages/getLanguage";
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  checkEmail,
  checkEmpty,
  checkPassword,
  checkUsername,
} from "../../../settings/validate";
import { post } from "../../../utils/api";
import { ENDPOINT_POST_CREATE_MEMBER } from "../../../settings/endpoint";
import { CustomToast } from "../../../settings";
import { useDispatch } from "react-redux";
import ValidateFormatError, {
  validateFormatErrorInput,
} from "../common/ValidateFormatError";
import { Visibility, VisibilityOff, Close } from "@material-ui/icons";
import { _getF1Member } from "../../../actions/miningActions";
import { ENDPOINT_POST_USER_REGISTER_OTP } from "../../../settings/endpoint";
import clsx from "clsx";

function NewMember(props) {
  const { user, close, _handleSuccess } = props;
  const { information } = user;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(information.email);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [sponsorUsername, setSponsorUsername] = useState(information.username);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [sendOtp, setSendOtp] = useState(getLanguage("SEND_OTP"));
  const [disabledSendOtp, setDisabledSendOtp] = useState(false);
  const [fieldError, setFieldError] = useState(null);
  const dispatch = useDispatch();

  const createMember = (e) => {
    e.preventDefault();

    //#region validete
    let tempFieldError = [];
    validateFormatErrorInput(e);

    if (checkEmpty(sponsorUsername)) {
      tempFieldError.push({
        field: "sponsorUsername",
        error: getLanguage("PLEASE_ENTER_SPONSOR_ID"),
      });
    }
    if (checkEmpty(username)) {
      tempFieldError.push({
        field: "username",
        error: getLanguage("PLEASE_ENTER_USERNAME"),
      });
    } else {
      if (checkUsername(username)) {
        tempFieldError.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 }),
        });
      }
    }
    if (checkEmpty(email)) {
      tempFieldError.push({
        field: "email",
        error: getLanguage("PLEASE_ENTER_EMAIL"),
      });
    } else {
      if (checkEmail(email)) {
        tempFieldError.push({
          field: "email",
          error: getLanguage("INVALID_EMAIL"),
        });
      }
    }
    if (checkEmpty(emailOtp) && email !== information.email) {
      tempFieldError.push({
        field: "emailOtp",
        error: getLanguage("PLEASE_ENTER_OTP"),
      });
    }
    if (checkEmpty(password)) {
      tempFieldError.push({
        field: "password",
        error: getLanguage("PLEASE_ENTER_PASSWORD"),
      });
    } else if (checkPassword(password)) {
      tempFieldError.push({
        field: "password",
        error: getLanguage("INVALID_PASSWORD", { minChar: 8, maxChar: 32 }),
      });
    }
    if (checkEmpty(repeatPassword)) {
      tempFieldError.push({
        field: "repeatPassword",
        error: getLanguage("PLEASE_ENTER_REPEAT_PASSWORD"),
      });
    } else {
      if (password !== repeatPassword) {
        tempFieldError.push({
          field: "repeatPassword",
          error: getLanguage("INVALID_REPEAT_PASSWORD"),
        });
      }
    }
    //#endregion

    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setLoading(true);
      setDisabledSendOtp(true);
      setFieldError(null);

      const param = {
        username,
        email,
        password,
        sponsorUsername,
        emailOtp: email === information.email ? "111111" : emailOtp,
      };
      post(
        ENDPOINT_POST_CREATE_MEMBER,
        param,
        () => {
          CustomToast("success", getLanguage("CREATE_MEMBER_SUCCESS"));
          close();
          _handleSuccess();
        },
        (error) => {
          setLoading(false);
          setDisabledSendOtp(false);
          setFieldError([{ field: "all", error: getLanguage(error.code) }]);
          dispatch(_getF1Member(1, 10, {}));
          CustomToast("error", getLanguage(error.code));
        }
      );
    }
  };

  const _getEmailOtp = (e) => {
    e.preventDefault();

    //#region validete
    let tempFieldError = [];
    if (checkEmpty(username)) {
      tempFieldError.push({
        field: "username",
        error: getLanguage("PLEASE_ENTER_USERNAME"),
      });
    } else {
      if (checkUsername(username)) {
        tempFieldError.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 }),
        });
      }
    }
    if (checkEmpty(email)) {
      tempFieldError.push({
        field: "email",
        error: getLanguage("PLEASE_ENTER_EMAIL"),
      });
    } else {
      if (checkEmail(email)) {
        tempFieldError.push({
          field: "email",
          error: getLanguage("INVALID_EMAIL"),
        });
      }
    }
    //#endregion

    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setLoading(true);
      setDisabledSendOtp(true);
      setFieldError(null);

      const param = {
        email,
        username,
      };

      post(
        ENDPOINT_POST_USER_REGISTER_OTP,
        param,
        () => {
          var i = 20;
          CustomToast("success", "Sent OTP success. Please check your email.");
          var timerInterval = setInterval(() => {
            i--;
            if (i === 0) {
              clearInterval(timerInterval);
              setLoading(false);
              setSendOtp(getLanguage("SEND_OTP"));
              setDisabledSendOtp(false);
            } else {
              setLoading(false);
              setSendOtp(getLanguage("COUNTDOWN_TIME", { seconds: i }));
            }
          }, 1000);
        },
        (error) => {
          setLoading(false);
          setSendOtp(getLanguage("SEND_OTP"));
          setDisabledSendOtp(false);
          CustomToast("error", getLanguage(error.code));
          setFieldError([{ field: "all", error: getLanguage(error.code) }]);
        }
      );
    }
  };

  const _checkError = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const _getErrorContent = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return check.error;
      } else {
        return null;
      };
    } else {
      return null;
    }
  };

  return (
    <form className={classes.form} onSubmit={(e) => createMember(e)} noValidate>
      <DialogTitle>{getLanguage("Enroll_new_member")}</DialogTitle>
      <IconButton
        style={{ position: "absolute", top: 20, right: 20 }}
        onClick={close}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              error={_checkError("sponsorUsername")}
              required fullWidth size="small"
            >
              <InputLabel>{getLanguage("Sponsor_Username")}</InputLabel>
              <Input
                value={sponsorUsername}
                onChange={(e) => setSponsorUsername(e.target.value)}
              />
              {
                _checkError("sponsorUsername") &&
                <FormHelperText error>
                  {_getErrorContent("sponsorUsername")}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={_checkError("username")}
              required fullWidth size="small"
            >
              <InputLabel>{getLanguage("USERNAME")}</InputLabel>
              <Input
                onChange={(e) => setUsername(e.target.value)}
              />
              {
                _checkError("username") &&
                <FormHelperText error>
                  {_getErrorContent("username")}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={_checkError("email")}
              required fullWidth size="small"
            >
              <InputLabel>{getLanguage("EMAIL_ADDRESS")}</InputLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                endAdornment={
                  email !== information.email ? (
                    <InputAdornment
                      className={clsx("ml-2 btn",
                        disabledSendOtp && "disabledEvent"
                      )}
                      onClick={(e) => _getEmailOtp(e)}
                      disabled={disabledSendOtp}
                    >
                      {loading ? (
                        <CircularProgress size={24} className="loading-icon" />
                      ) : (
                        sendOtp
                      )}
                    </InputAdornment>
                  ) : null
                }
              />
              {
                _checkError("email") &&
                <FormHelperText error>{_getErrorContent("email")}</FormHelperText>
              }
            </FormControl>
          </Grid>
          {email !== information.email && (
            <Grid item xs={12}>
              <FormControl
                error={_checkError("emailOtp")}
                required fullWidth size="small"
              >
                <InputLabel>{getLanguage("EMAIL_OTP")}</InputLabel>
                <Input
                  onChange={(e) => setEmailOtp(e.target.value)}
                />
                {
                  _checkError("emailOtp") &&
                  <FormHelperText error>
                    {_getErrorContent("emailOtp")}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl
              error={_checkError("password")}
              required fullWidth size="small"
            >
              <InputLabel>{getLanguage("PASSWORD")}</InputLabel>
              <Input
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                _checkError("password") &&
                <FormHelperText error>
                  {_getErrorContent("password")}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={_checkError("repeatPassword")}
              required fullWidth size="small"
            >
              <InputLabel>{getLanguage("REPEAT_PASSWORD")}</InputLabel>
              <Input
                type={showPassword ? "text" : "password"}
                onChange={(e) => setRepeatPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                _checkError("repeatPassword") &&
                <FormHelperText error>
                  {_getErrorContent("repeatPassword")}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          {
            _checkError("all") &&
            <Grid item xs={12}>
              <FormHelperText error>
                {_getErrorContent("all")}
              </FormHelperText>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="mt-3"
        >
          <Grid item xs={6}>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={loading}
                fullWidth
              >
                {getLanguage("CREATE")}
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
}

export default NewMember;

//#region CSS
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    padding: "30px 0px",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
}));
//#endregion
