import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import Setting from "./Setting";
import Info from "./Info";
import "./style.scss";
import { getLanguage } from "../../../languages/getLanguage";
import CustomLoading from "../common/CustomLoading";
import { isMobileApp } from "../../../utils/auth";

function Index() {

  const { user } = useSelector((state) => state);
  const { information } = user;

  return information.verifyLevel ? (
    <div className="my-profile">
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-page">
              {getLanguage("PROFILE")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={isMobileApp() ? 12 : 5}>
            <Info user={user} />
          </Grid>
          <Grid item xs={12} md={isMobileApp() ? 12 : 7}>
            <Setting user={user} />
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default Index;
