import React from "react";
import { Container } from "@material-ui/core";
import TitlePage from "../common/title-page";

function Index() {
  return (
    <div className="pricacy-policy-page bg-main-page">
      <TitlePage title="Privacy Policy" />
      <Container maxWidth="lg" className="bg-content-page blog-page">
        <p className="mb-5">
          This page inform you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data. We use your data to
          provide and improve the Service. By using the Service, you agree to
          the collection and use of provided information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, terms used in
          this Privacy Policy have the same meanings as in our Terms and
          Conditins, accessible from www.miningclub.org.
        </p>
        <h4 className="mb-3">Information Collection and Use of Data</h4>
        <p className="mb-5">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h4>Types of Data Collected</h4>
        <h4 className="mb-3"> Personal Data</h4>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul className="mb-5 ml-5">
          <li>• Email address</li>
          <li>• First name and last name</li>
          <li>• Phone number</li>
          <li>• Address, State, Province, ZIP/Postal code, City</li>
          <li>• Cookies and Usage Data</li>
        </ul>
        <h4 className="mb-3">Usage Data</h4>
        <p className="mb-5">
          We may also collect information on how the Service is accessed and
          used ("Usage Data"). This Usage Data may include information such as
          your computer's Internet Protocol address (e.g. IP address), browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique
          device identifiers and other diagnostic data.
        </p>
        <h4 className="mb-3">Tracking & Cookies Data</h4>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information. Cookies are files with
          small amount of data which may include an anonymous unique identifier.
          Cookies are sent to your browser from a website and stored on your
          device. Tracking technologies also used are beacons, tags, and scripts
          to collect and track information and to improve and analyze our
          Service and/or its performance. You can instruct your browser to
          refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Service. Examples of Cookies we use:
        </p>
        <ul className="mb-5 ml-5">
          <li>
            • Session Cookies.We use Session Cookies to operate our Service.
          </li>
          <li>
            • Preference Cookies.We use Preference Cookies to remember your
            preferences and various settings.
          </li>
          <li>
            • Security Cookies.We use Security Cookies for security purposes.
          </li>
        </ul>
        <h4 className="mb-3">Use of Data</h4>
        <p>We use the collected data for various purposes:</p>
        <ul className="mb-5 ml-5">
          <li>• To provide and maintain the Service;</li>
          <li>• To notify you about changes to our Service;</li>
          <li>
            • To allow you to participate in interactive features of our Service
            when you choose to do so;
          </li>
          <li>• To provide customer care and support;</li>
          <li>
            • To provide analysis or valuable information so that we can improve
            the Service;
          </li>
          <li>• To monitor the usage of the Service;</li>
          <li>
            • To detect, prevent and address technical issues of the Service.
          </li>
        </ul>
        <h4 className="mb-3">Transfer Of Data</h4>
        <p className="mb-5">
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction. If you are located
          outside of Europe and choose to provide information to us, please note
          that we transfer the data, including Personal Data, to Europe and
          process it there. Your consent to this Privacy Policy followed by your
          submission of such information represents your agreement to that
          transfer. We will take all steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <h4>Disclosure Of Data</h4>
        <h4 className="mb-3">Legal Requirements</h4>
        <p>
          We may disclose your Personal Data in good faith belief that such
          action is necessary to:
        </p>
        <ul className="mb-5 ml-5">
          <li>• To comply with a legal obligation;</li>
          <li>
            • To protect and defend the rights or property of Prophetek Limited;
          </li>
          <li>
            • To prevent or investigate possible wrongdoing in connection with
            the Service;
          </li>
          <li>
            • To protect the personal safety of users of the Service or the
            public;
          </li>
          <li>• To protect against legal liability.</li>
        </ul>
        <h4 className="mb-3">Your rights</h4>
        <p>
          The following rights are granted to you under General Data Protection
          Regulation ("GDPR"), in particular, the right to:
        </p>
        <ul className="ml-5">
          <li>• access to the Personal Data;</li>
          <li>• request to rectify, correct or delete the Personal Data;</li>
          <li>• object (forbid) to the processing of the Personal Data;</li>
          <li>• request a restriction of the Personal Data processing;</li>
          <li>• request a transfer of the Personal Data to another entity;</li>
          <li>
            • request the erasure of the Personal Data (the right to be
            forgotten) except as excluded by the applicable law;
          </li>
        </ul>
        <p className="mb-5">
          You have the right to withdraw a consent to the processing of Personal
          Data at any time, however the withdrawal does not affect the
          lawfulness of processing based on the consent before its withdrawal.
          If you believe that we are rocessing your Personal Data without
          jurisdiction or in manner inconsistent with the applicable law, you
          have the right to lodge a complaint with a data protection authority –
          Commissioner for Personal Data Protection, in UK. In order to exercise
          the aforesaid rights or in case of any questions or doubts about the
          Personal Data protection, please contact us at support@miningclub.org.
        </p>
        <h4 className="mb-3">Security Of Data</h4>
        <p className="mb-5">
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <h4 className="mb-3">Service Providers</h4>
        <p className="mb-5">
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf,
          to perform Service-related work and services or to assist us in
          analyzing how our Service is used or its performance. These third
          parties have access to your Personal Data only to perform these tasks
          on our behalf and are obligated to keep secure, not to disclose or use
          it for any other purpose.
        </p>
        <h4 className="mb-3">Analytics</h4>
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </p>
        <ul className="ml-5">
          <li>• Google Analytics</li>
        </ul>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity. For more information on the privacy
          practices of Google, please visit the Google Privacy & Terms web
        </p>
        <p className="mb-5">
          page:{" "}
          <a href="https://policies.google.com/privacy?hl=en">
            https://policies.google.com/privacy?hl=en
          </a>
        </p>
        <h4 className="mb-3">Links To Other Sites</h4>
        <p className="mb-5">
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </p>
        <h4 className="mb-3">Children's Privacy</h4>
        <p className="mb-5">
          Our Service does not address anyone under the age of 18 ("Children").
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children have provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from Children without verification of parental consent, we take
          steps to remove that information from our servers immediately.
        </p>
        <h4 className="mb-3">Changes To This Privacy Policy</h4>
        <p className="mb-5">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We will
          let you know via email and/or a prominent notice on our Service, prior
          to the change becoming effective and update the "effective date" at
          the top of this Privacy Policy. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.
        </p>
        <h4 className="mb-3">Contact Us</h4>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us: info@miningclub.org
        </p>
      </Container>
    </div>
  );
}

export default Index;
