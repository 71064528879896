import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LogoImg from "../../assets/images/logo.png";
import { useHistory } from "react-router";
import { isLoggedIn, setAccessToken, isMobileApp } from "../../../utils/auth";
import { post } from "../../../utils/api";
import { ENDPOINT_POST_USER_LOGIN } from "../../../settings/endpoint";
import {
  FormControl,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Copyright from "../common/Copyright";
import {
  checkEmpty,
  checkUsername,
  checkPassword,
} from "../../../settings/validate";
import { getLanguage } from "../../../languages/getLanguage";
import { random } from "../../common/RandomBackgroundComponent";
import OtpConfirm from "../../common/otp-confirm/OtpConfirm";

function Index() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [gaCode, setGaCode] = useState("");
  const [showGA, setShowGA] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const [fieldError, setFieldError] = useState(null);

  //realbewhy
  useEffect(() => {
    if (isLoggedIn()) {
      if (isMobileApp()) {
        history.push("/mobile-app/dashboard");
      } else {
        history.push("/dashboard");
      }
    }
  }, [history]);

  function _handleLogin(ga = null, e = null) {
    if (e) {
      e.preventDefault();
    }

    //#region validete
    let tempFieldError = [];

    if (checkEmpty(username)) {
      tempFieldError.push({
        field: "username",
        error: getLanguage("PLEASE_ENTER_USERNAME"),
      });
    } else {
      if (checkUsername(username)) {
        tempFieldError.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 }),
        });
      }
    }
    if (checkEmpty(password)) {
      tempFieldError.push({
        field: "password",
        error: getLanguage("PLEASE_ENTER_PASSWORD"),
      });
    } else {
      if (checkPassword(password)) {
        tempFieldError.push({
          field: "password",
          error: getLanguage("INVALID_PASSWORD"),
        });
      }
    }
    //#endregion

    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setLoading(true);
      setFieldError(null);

      var param;
      if (showGA) {
        param = {
          username,
          password,
          gaCode: ga ? ga : gaCode,
        };
      } else {
        param = {
          username,
          password,
        };
      }

      post(
        ENDPOINT_POST_USER_LOGIN,
        param,
        (data) => {
          setAccessToken(data.accessToken);
          if (isMobileApp()) {
            window.location.replace("/mobile-app/dashboard");
          } else {
            window.location.replace("/dashboard");
          }
        },
        (error) => {
          setLoading(false);
          if (error.code === "GACODE_REQUIRED") {
            setShowGA(true);
            if (showGA) {
              if (checkEmpty(gaCode)) {
                setFieldError([{ field: "gaCode", error: getLanguage("PLEASE_ENTER_GA_CODE") }]);
              }
            }
          } else {
            setShowGA(false);
            setFieldError([{ field: "all", error: getLanguage(error.code) }]);
          }
        }
      );
    }
  }

  const _checkError = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const _getErrorContent = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return check.error
      } else {
        return null;
      };
    } else {
      return null;
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        style={{ position: "relative" }}
      >
        <div className={classes.paper}>
          <div>
            <Link to={isMobileApp() ? "#" : "/"}>
              <img src={LogoImg} alt="Logo" className={classes.logo} />
            </Link>
          </div>
          <div>
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              className="mb-5"
              align="center"
            >
              {getLanguage("LOGIN").toUpperCase()}
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => _handleLogin(null, e)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    error={_checkError("username")}
                    required fullWidth size="small"
                  >
                    <InputLabel>{getLanguage("USERNAME")}</InputLabel>
                    <Input
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    {
                      _checkError("username") &&
                      <FormHelperText error>
                        {_getErrorContent("username")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    error={_checkError("password")}
                    required fullWidth size="small"
                  >
                    <InputLabel>{getLanguage("PASSWORD")}</InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {
                      _checkError("password") &&
                      <FormHelperText error>
                        {_getErrorContent("password")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                {showGA && (
                  <Grid item xs={12}>
                    <Typography component="p" className="mb-2"><small>{getLanguage("GA_CODE")}</small></Typography>
                    <OtpConfirm
                      _handleComplete={(e) => {
                        setGaCode(e);
                        _handleLogin(e, null);
                      }} />
                    {
                      _checkError("gaCode") &&
                      <FormHelperText error>
                        {_getErrorContent("gaCode")}
                      </FormHelperText>
                    }
                  </Grid>
                )}
                {
                  _checkError("all") &&
                  <Grid item xs={12}>
                    <FormHelperText error>
                      {_getErrorContent("all")}
                    </FormHelperText>
                  </Grid>
                }
                <Grid item xs={12} className="text-center">
                  <Grid container justify="center">
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        fullWidth
                        className="mt-3 mb-5"
                      >
                        {loading ? (
                          <CircularProgress color="primary" size={24} />
                        ) : (
                          getLanguage("SIGN_IN")
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid container spacing={2} align="center">
              <Grid item xs={12}>
                <Link
                  className={classes.link}
                  to={isMobileApp() ? "/mobile-app/dashboard/forgot-password" : "/dashboard/forgot-password"}>
                  {getLanguage("FORGOT_PASSWORD")}?
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  className={classes.link}
                  to={isMobileApp() ? "/mobile-app/dashboard/sign-up" : "/dashboard/sign-up"}>
                  {getLanguage("SIGN_UP_1")}
                </Link>
              </Grid>
            </Grid>
          </div>
          <Box>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
export default Index;

//#region CSS
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
  },
  image: {
    backgroundImage: `url(${random()})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100vh",
    padding: "50px 30px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo: {
    maxWidth: 140,
    marginBottom: "1em",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 180,
    },
  },
  link: {
    textDecoration: "none",
    // color: "#1976d2",
    "&:hover": {
      textDecoration: "underline",
      // color: "initial",
    },
  },
}));
//#endregion
