import { Button, MenuItem, Menu } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, HeadsetMic } from "@material-ui/icons";
import React, { useState } from "react";
import { getLanguage } from "../../../languages/getLanguage";

const BtnSupport = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="w-100">
      <Button onClick={handleClick} className="text-primary">
        <HeadsetMic className="mr-1" /> {getLanguage("SUPPORT")}
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
          }}
          style={{ display: "block", minHeight: "auto" }}
        >
          <a className="d-block" target="_blank" href={getLanguage("db_left_menu_link_1")}>{getLanguage("SUPPORT_CENTER")}</a>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
          style={{ display: "block", minHeight: "auto" }}
        >
          <a className="d-block" target="_blank" href={getLanguage("db_left_menu_link_2")}>{getLanguage("FAQ")}</a>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default BtnSupport;
