import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { getModesTemplate } from "../settings";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MatchHeight from "./common/fix-height/MatchHeight";
import throttle from "./common/fix-height/throttle";

import ScrollToTop from "./common/ScrollToTopComponent";
import PageNotFound from "./common/page-not-found/Index";
import "./scss/bootstrap.scss";
import "./scss/custom.scss";
import "./scss/template-modes.scss";

//#region client
import Client_MainPage from "./client-areas/Main";
import "./client-areas/scss/style.scss";
//import "./dashboard-areas/scss/mode/template-mode.scss";
//#endregion

//#region dashboard
import Dashboard_LoginPage from "./dashboard-areas/login-page/Index";
import Dashboard_SignUpPage from "./dashboard-areas/sign-up-page/Index";
import Dashboard_ForgotPasswordPage from "./dashboard-areas/forgot-password-page/Index";
import Dashboard_MainPage from "./dashboard-areas/Main";
import "./dashboard-areas/scss/style.scss";
import "./dashboard-areas/scss/mode/template-mode.scss";
import LoginByToken from "./dashboard-areas/LoginByToken";
//#endregion

//#region client
import MobileApp_MainPage from "./mobile-app-areas/Main";
import "./mobile-app-areas/scss/mobile-app.scss";
//#endregion

function Areas() {
  const fixMatchHeight = () => {
    window.addEventListener("DOMContentLoaded", function onDomReady() {
      MatchHeight.init();
      window.removeEventListener("DOMContentLoaded", onDomReady);
    });

    window.addEventListener("load", function onLoad() {
      MatchHeight.init();
      window.removeEventListener("load", onLoad);
    });

    document.addEventListener("scroll", function onScroll(e) {
      MatchHeight.init();
      window.removeEventListener("scroll", onScroll);
    });

    const throttledUpdate = throttle(MatchHeight.update, 200);
    window.addEventListener("resize", throttledUpdate);
    MatchHeight.init();
  };

  useEffect(() => {
    fixMatchHeight();
  });

  return (
    <Router>
      <div className="app">
        <Switch>
          <Route exact path="/login-by-token/:token" component={LoginByToken} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/mobile-app/dashboard" component={MobileApp} />
          <Route path="/" component={Client} />
        </Switch>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default Areas;

function Client() {
  useEffect(() => {
    var html = document.querySelector("html");
    html.removeAttribute("class");
    html.classList.add("client-page");

    var body = document.querySelector("body");
    body.removeAttribute("class");
  });
  return (
    <ScrollToTop>
      <Switch>
        <Redirect from="/sign-up/:sponsor" to="/dashboard/sign-up/:sponsor" />
        <Route path="/" component={Client_MainPage} />
        <Route component={PageNotFound} />
      </Switch>
    </ScrollToTop>
  );
}

function Dashboard() {
  useEffect(() => {
    var html = document.querySelector("html");
    html.dataset.theme = "theme-light";
    html.removeAttribute("class");
    html.classList.add("dashboard-page");

    var body = document.querySelector("body");
    body.removeAttribute("class");
    body.classList.add(`${getModesTemplate()}-mode`);

  });

  return (
    <Switch>
      <Route exact path="/dashboard/login" component={Dashboard_LoginPage} />
      <Route
        exact
        path="/dashboard/forgot-password"
        component={Dashboard_ForgotPasswordPage}
      />
      <Route exact path="/dashboard/sign-up" component={Dashboard_SignUpPage} />
      <Route
        exact
        path="/dashboard/sign-up/:sponsor"
        component={Dashboard_SignUpPage}
      />
      <Route
        exact
        path="/dashboard/sign-up?sponsor=:sponsor"
        component={Dashboard_SignUpPage}
      />
      <Route path="/dashboard" component={Dashboard_MainPage} />
      <Route component={PageNotFound} />
    </Switch>
  );
}

function MobileApp() {
  useEffect(() => {
    var html = document.querySelector("html");
    html.removeAttribute("class");
    html.classList.add("dashboard-page");
    html.classList.add("mobile-app");

    var body = document.querySelector("body");
    body.removeAttribute("class");
    body.classList.add("dark-mode");
  });
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path="/mobile-app/dashboard/login" component={Dashboard_LoginPage} />
        <Route exact path="/mobile-app/dashboard/forgot-password" component={Dashboard_ForgotPasswordPage} />
        <Route exact path="/mobile-app/dashboard/sign-up" component={Dashboard_SignUpPage} />
        <Route exact path="/mobile-app/dashboard/sign-up/:sponsor" component={Dashboard_SignUpPage} />
        <Route exact path="/mobile-app/dashboard/sign-up?sponsor=:sponsor" component={Dashboard_SignUpPage} />
        <Route path="/mobile-app/dashboard" component={MobileApp_MainPage} />
        <Route component={PageNotFound} />
      </Switch>
    </ScrollToTop>
  )
}
