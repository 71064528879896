import React from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography
} from '@material-ui/core';
import { Link } from "react-router-dom";
import { getLanguage, getCode } from "../../../languages/getLanguage";
import {
  formatTextShorten,
} from "../../../settings/format";
import { isMobileApp } from "../../../utils/auth";

function Footer() {

  return (
    <AppBar component="footer" className="footer">
      <Toolbar className="pt-2 pb-2">
        <Grid container spacing={2}>
          <Grid item xs={4} className="text-center">
            <Link to={
              isMobileApp() ?
                "/mobile-app/dashboard/plans/buy" :
                "/dashboard/plans/buy"
            }>
              <img src="/images/app-icon/buyplans.png" alt="" className="mb-2 icon-app" />
              <Typography component="p">
                {
                  getCode() === "kr" ?
                    formatTextShorten(getLanguage("BUY_PLANS"), 5) :
                    formatTextShorten(getLanguage("BUY_PLANS"))
                }
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={4} className="text-center">
            <Link to={
              isMobileApp() ?
                "/mobile-app/dashboard" :
                "/dashboard"
            }>
              <img src="/images/app-icon/Home.png" alt="" className="mb-2 icon-app" />
              <Typography component="p">
                {formatTextShorten(getLanguage("HOME"))}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={4} className="text-center">
            <Link to={
              isMobileApp() ?
                "/mobile-app/dashboard/wallet/balance" :
                "/dashboard/wallet/balance"
            }>
              <img src="/images/app-icon/wallet.png" alt="" className="mb-2 icon-app" />
              <Typography component="p">
                {formatTextShorten(getLanguage("WALLET"))}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
export default Footer;