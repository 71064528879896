import {
  CircularProgress,
  Container,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  _getDirectTransaction,
  _getTransaction,
} from "../../../actions/miningActions";
import { getLanguage } from "../../../languages/getLanguage";
import { formatAmount } from "../../../settings/format";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

export default function PromotionTx() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const { mining } = useSelector((state) => state);
  const { transactions } = mining;

  useEffect(() => {
    dispatch(
      _getTransaction(page, pageSize, {
        type: "PROMOTION",
      })
    );
  }, [dispatch, page, pageSize]);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <div>
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-page">
              {getLanguage("PROMOTION")}
            </Typography>

            {
              isMobile || isMobileApp() ?
                <TableMobile
                  transactions={transactions}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                /> :
                <TableDeskTop
                  transactions={transactions}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                />
            }

          </Grid>
        </Grid>
      </Container>
    </div>
  );
}


const TableDeskTop = (props) => {
  const {
    transactions,
    _handleChangePage,
    page,
    pageSize
  } = props;

  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableHead className="hide-xs">
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{getLanguage("TYPE")}</TableCell>
            <TableCell align="right">{getLanguage("AMOUNT")}</TableCell>
            <TableCell align="right">{getLanguage("TIME")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions &&
            transactions.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>#{row.id}</TableCell>
                <TableCell component="th" scope="row">
                  {row.text}
                </TableCell>
                <TableCell align="right">
                  {formatAmount(row.amount)} {row.asset}
                </TableCell>
                <TableCell align="right">
                  {moment(row.createdTime).format("yyyy-MM-DD")}
                </TableCell>
              </TableRow>
            ))}
          {transactions && transactions.itemCount === 0 && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!transactions && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={3} align="right">
              {getLanguage("TOTAL")}:{" "}
              <span>
                {formatAmount(transactions ? transactions.meta.total : 0)}{" "}
                FIL
              </span>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
      {transactions && transactions.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={transactions && transactions.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}

const TableMobile = (props) => {
  const {
    transactions,
    _handleChangePage,
    page,
    pageSize
  } = props;

  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableBody>
          {transactions &&
            transactions.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Grid container>
                    <Grid xs={6} className="mb-2">
                      #{row.id}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="text-right mb-2"
                    >
                      {formatAmount(row.amount)} {row.asset}
                    </Grid>
                    <Grid item xs={6}>
                      <small className="opacity-06">
                        {getLanguage("TYPE")}
                      </small>
                      <div>{row.text}</div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <small className="opacity-06">
                        {getLanguage("TIME")}
                      </small>
                      <div>
                        {moment(row.createdTime).format("yyyy-MM-DD")}
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          {transactions && transactions.itemCount === 0 && (
            <TableRow>
              <TableCell align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!transactions && (
            <TableRow>
              <TableCell align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell align="right">
              {getLanguage("TOTAL")}:{" "}
              <span>
                {formatAmount(transactions ? transactions.meta.total : 0)}{" "}
                FIL
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {transactions && transactions.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={transactions && transactions.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}