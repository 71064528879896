import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LogoImg from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { logout } from "../../../utils/auth";
import { getLanguage } from "../../../languages/getLanguage";
import { Divider, Grid } from "@material-ui/core";
import { S3_URL, getModesTemplate } from "../../../settings";
import LanguageIcon from "@material-ui/icons/Language";
import i18next from '../../../languages/getLanguage';
import PaletteIcon from '@material-ui/icons/Palette';
import { getCountryFlags } from "../../../settings";
import {
  DarkMode,
  LightMode
} from "../../assets/svg/svg";

function Header(props) {
  const classes = useStyles();

  const { handleDrawerToggleLeftMenu } = props;

  //#region lang
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleLanguageMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (lang) => {
    setAnchorEl(null);
    if (
      lang === "en" ||
      lang === "vi" ||
      lang === "kr" ||
      lang === "de" ||
      lang === "es" ||
      lang === "ru"
    ) {
      i18next.changeLanguage(lang);
    }
  };

  const renderMenuLanguage = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem onClick={() => handleLanguageMenuClose("en")}><img className="mr-2" src={getCountryFlags("gb", 24)} />English</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("vi")}><img className="mr-2" src={getCountryFlags("vn", 24)} />Vietnamese</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("kr")}><img className="mr-2" src={getCountryFlags("kr", 24)} />Korean</MenuItem>
      {/* <MenuItem onClick={() => handleLanguageMenuClose("de")}><img className="mr-2" src={getCountryFlags("de", 24)} />German</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("es")}><img className="mr-2" src={getCountryFlags("es", 24)} />Spanish</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("ru")}><img className="mr-2" src={getCountryFlags("ru", 24)} />Russian</MenuItem> */}
    </Menu>
  );
  //#endregion

  //#region them mode
  const [isModeDark, setIsModeDark] = React.useState(getModesTemplate());

  const handleModesTemplate = () => {
    if (isModeDark) {
      var body = document.querySelector("body");
      body.removeAttribute("class");
      var mode = isModeDark === "dark" ? "light" : "dark";
      setIsModeDark(mode);
      body.classList.add(`${mode}-mode`);
      localStorage.setItem('mode-template', mode);
    }
  }
  //#endregion

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Link to="/dashboard">
          <img src={LogoImg} alt="Logo" className={classes.logo} />
        </Link>
        <div className={classes.grow} style={{ textAlign: "right" }}>
          <div className="show-md">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerToggleLeftMenu}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
        <div className={"hide-md"}>
          <Grid container alignItems="center">
            <Grid item>
              <Link to="/dashboard/plans/buy">
                <Button
                  variant="contained"
                  className="text-white text-bold"
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#FF644E",
                  }}
                >
                  <img
                    src={`${S3_URL}menu-icon/Cart.png`}
                    className="mr-2"
                    alt="cart"
                    style={{
                      height: 20,
                      width: 20,
                    }}
                  />
                  {getLanguage("BUY_PLANS")}
                </Button>
              </Link>
            </Grid>
            <Grid item className="ml-4">
              <Link to="/dashboard/my-profile">
                <Typography variant="subtitle1" color="primary">
                  {getLanguage("ACCOUNT")}
                </Typography>
              </Link>
            </Grid>
            <Grid item className="ml-3">
              <IconButton onClick={handleLanguageMenuOpen}>
                <LanguageIcon color="primary" />
              </IconButton>
              {renderMenuLanguage}
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleModesTemplate()} className="mode-template">
                {
                  isModeDark === "dark" ?
                    <LightMode /> :
                    <DarkMode />
                }
              </IconButton>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              className="bg-primary mr-3"
            />
            <Grid item>
              <Typography variant="subtitle1" color="primary">
                <a href="#" className="text-nowrap" onClick={() => logout()}>
                  {getLanguage("LOGOUT")}
                  <img
                    src="/images/logout-icon.png"
                    style={{ width: 20, height: 20 }}
                    alt="logout-icon"
                    className="ml-2"
                  />
                </a>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Toolbar>
    </AppBar >
  );
}
export default Header;

//#region CSS
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
    color: "#000",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    boxShadow: "none",
    width: "100%",
    left: 0,
  },
  root: {
    height: 100,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    maxWidth: 180,
    marginRight: "10px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 180,
    },
  },
  textDecorationUnderline: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
//#endregion
