import {
  Avatar,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  withStyles,
  Container,
} from "@material-ui/core";
import React from "react";
import { CheckCircleOutline } from "@material-ui/icons";
import { getLanguage } from "../../../languages/getLanguage";
import { formatUSD } from "../../../settings/format";
import { S3_URL } from "../../../settings";
import { ranks } from "../../../constants";
import { isMobileApp } from "../../../utils/auth";
import { useSelector } from "react-redux";
import CustomLoading from "../common/CustomLoading";

const rankDetail = [
  {
    key: "PRO",
    member: 1,
    left: 2,
    right: 2,
    cumPv: 1000,
    monthPv: 0,
    quarterPv: 0,
    maintain: 0,
    beforeRank: "MEMBERSHIP",
  },
  {
    key: "MASTER",
    member: 4,
    left: 2,
    right: 2,
    cumPv: 6000,
    monthPv: 0,
    quarterPv: 0,
    maintain: 0,
    beforeRank: "PRO",
  },
  {
    key: "COACH",
    member: 5,
    left: 2,
    right: 2,
    cumPv: 0,
    monthPv: 30000,
    quarterPv: 0,
    maintain: 30,
    beforeRank: "MASTER",
  },
  {
    key: "SUPERVISOR",
    member: 5,
    left: 2,
    right: 2,
    cumPv: 0,
    monthPv: 120000,
    quarterPv: 400000,
    maintain: 90,
    beforeRank: "COACH",
  },
  {
    key: "FOUNDER",
    member: 5,
    left: 2,
    right: 2,
    cumPv: 1000,
    monthPv: 180000,
    quarterPv: 900000,
    maintain: 90,
    beforeRank: "SUPERVISOR",
  },
  {
    key: "MEMBERSHIP",
    member: 5,
    left: 2,
    right: 2,
    cumPv: 1000,
    monthPv: 180000,
    quarterPv: 900000,
    maintain: 90,
    beforeRank: "",
  },
];

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: "#131415",
  },
  bar: {
    borderRadius: 10,
  },
}))(LinearProgress);

const ProgressBar = ({ value, max }) => {
  let percent = (value / max) * 100;
  percent = percent > 100 ? 100 : percent;
  return <BorderLinearProgress variant="determinate" value={percent} />;
};

const Membership = () => {
  const { user } = useSelector((state) => state);
  const { information } = user;
  const { rank } = information;
  const detail = rankDetail[5];

  return (
    <Grid item xs={12} sm={isMobileApp() ? 12 : 6} lg={isMobileApp() ? 12 : 4}>
      <Paper
        variant="outlined"
        className={`p-4 text-center ${rank === "NO_RANK" ? "active" : ""}`}
        style={{
          height: "100%",
        }}
      >
        <Avatar
          className="m-auto"
          src={`${S3_URL}${ranks[detail.key].image}`}
          style={{ width: 70, height: 70 }}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className="mt-3 mb-3"
        >
          <Grid item>
            <Typography>{detail.key}</Typography>
          </Grid>
          <Grid item>
            <CheckCircleOutline className="ml-2" color={"primary"} />
          </Grid>
        </Grid>
        {/* <small>&nbsp;</small> */}
        <Typography className="mt-5" variant="h6" color="">
          {getLanguage("Registered_successfully")}
        </Typography>
      </Paper>
    </Grid>
  );
};

const RankPro = () => {
  const { mining, user } = useSelector((state) => state);
  const { information } = user;
  const { rank } = information;
  const { achievement } = mining;
  const detail = rankDetail[0];
  const cumPvLeft = achievement ? achievement.cumPvLeft : 0;
  const cumPvRight = achievement ? achievement.cumPvRight : 0;
  let status = false;
  if (cumPvRight >= detail.cumPv && cumPvLeft >= detail.cumPv) status = true;

  return (
    <Grid item xs={12} sm={isMobileApp() ? 12 : 6} lg={isMobileApp() ? 12 : 4}>
      <Paper
        variant="outlined"
        className={`p-4 text-center ${rank === detail.key ? "active" : ""}`}
        style={{ height: "100%" }}
      >
        <Avatar
          className="m-auto"
          src={`${S3_URL}${ranks[detail.key].image}`}
          style={{ width: 70, height: 70 }}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className="mt-3 mb-3"
        >
          <Grid item>
            <Typography>{detail.key}</Typography>
          </Grid>
          <Grid item>
            <CheckCircleOutline className="ml-2" color={status ? "primary" : "disabled"} />
          </Grid>
        </Grid>
        <div className="mt-3">
          <div>
            {formatUSD(cumPvLeft)}/{formatUSD(detail.cumPv)}
          </div>
          <div className="mt-2 mb-2">
            <ProgressBar value={cumPvLeft} max={detail.cumPv} />
          </div>
          <small>{getLanguage("Left PV")}</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(cumPvRight)}/{formatUSD(detail.cumPv)}
          </div>
          <div className="mt-2 mb-2">
            <ProgressBar value={cumPvRight} max={detail.cumPv} />
          </div>
          <small>{getLanguage("Right PV")}</small>
        </div>
      </Paper>
    </Grid>
  );
};

const RankMaster = () => {
  const { mining, user } = useSelector((state) => state);
  const { information } = user;
  const { rank } = information;
  const { achievement } = mining;
  const detail = rankDetail[1];
  const leftCount = achievement ? achievement.leftProCount : 0;
  const rightCount = achievement ? achievement.rightProCount : 0;
  const memberCount = achievement ? leftCount + rightCount : 0;
  const cumPvLeft = achievement ? achievement.cumPvLeft : 0;
  const cumPvRight = achievement ? achievement.cumPvRight : 0;
  let status = false;
  if (
    memberCount >= detail.member &&
    cumPvLeft >= detail.cumPv &&
    cumPvRight >= detail.cumPv &&
    leftCount >= detail.left &&
    rightCount >= detail.right
  ) {
    status = true;
  }

  return (
    <Grid item xs={12} sm={isMobileApp() ? 12 : 6} lg={isMobileApp() ? 12 : 4}>
      <Paper
        variant="outlined"
        className={`p-4 text-center ${rank === detail.key ? "active" : ""}`}
        style={{ height: "100%" }}
      >
        <Avatar
          className="m-auto"
          src={`${S3_URL}${ranks[detail.key].image}`}
          style={{ width: 70, height: 70 }}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className="mt-3 mb-3"
        >
          <Grid item>
            <Typography>{detail.key}</Typography>
          </Grid>
          <Grid item>
            <CheckCircleOutline className="ml-2" color={status ? "primary" : "disabled"} />
          </Grid>
        </Grid>
        <div>
          <div>
            {memberCount}/{detail.member}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={memberCount} max={detail.member} />
          </div>
          <small>
            {getLanguage(detail.beforeRank) + " " + getLanguage("MEMBER")}
          </small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(cumPvLeft)}/{formatUSD(detail.cumPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={cumPvLeft} max={detail.cumPv} />
          </div>
          <small>{getLanguage("Left PV")}</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(cumPvRight)}/{formatUSD(detail.cumPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={cumPvRight} max={detail.cumPv} />
          </div>
          <small>{getLanguage("Right PV")}</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(leftCount)}/{formatUSD(detail.left)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={leftCount} max={detail.left} />
          </div>
          <small>{getLanguage("LEFT")}</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(rightCount)}/{formatUSD(detail.right)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={rightCount} max={detail.right} />
          </div>
          <small>{getLanguage("RIGHT")}</small>
        </div>
      </Paper>
    </Grid>
  );
};

const RankCoach = () => {
  const { mining, user } = useSelector((state) => state);
  const { information } = user;
  const { rank } = information;
  const { achievement } = mining;
  const detail = rankDetail[2];
  const leftCount = achievement ? achievement.leftMasterCount : 0;
  const rightCount = achievement ? achievement.rightMasterCount : 0;
  const memberCount = achievement ? leftCount + rightCount : 0;
  const oneMonthPvLeft = achievement ? achievement.oneMonthPvLeft : 0;
  const oneMonthPvRight = achievement ? achievement.oneMonthPvRight : 0;
  let status = false;
  if (
    memberCount >= detail.member &&
    oneMonthPvLeft >= detail.monthPv &&
    oneMonthPvRight >= detail.monthPv &&
    leftCount >= detail.left &&
    rightCount >= detail.right
  ) {
    status = true;
  }

  return (
    <Grid item xs={12} sm={isMobileApp() ? 12 : 6} lg={isMobileApp() ? 12 : 4}>
      <Paper
        variant="outlined"
        className={`p-4 text-center ${rank === detail.key ? "active" : ""}`}
        style={{ height: "100%" }}
      >
        <Avatar
          className="m-auto"
          src={`${S3_URL}${ranks[detail.key].image}`}
          style={{ width: 70, height: 70 }}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className="mt-3 mb-3"
        >
          <Grid item>
            <Typography>{detail.key}</Typography>
          </Grid>
          <Grid item>
            <CheckCircleOutline className="ml-2" color={status ? "primary" : "disabled"} />
          </Grid>
        </Grid>
        <div>
          <div>
            {memberCount}/{detail.member}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={memberCount} max={detail.member} />
          </div>
          <small>
            {getLanguage(detail.beforeRank) + " " + getLanguage("MEMBER")}
          </small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(oneMonthPvLeft)}/{formatUSD(detail.monthPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={oneMonthPvLeft} max={detail.monthPv} />
          </div>
          <small>{getLanguage("MONTHLY")} Left PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(oneMonthPvRight)}/{formatUSD(detail.monthPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={oneMonthPvRight} max={detail.monthPv} />
          </div>
          <small>{getLanguage("MONTHLY")} Right PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(leftCount)}/{formatUSD(detail.left)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={leftCount} max={detail.left} />
          </div>
          <small>{getLanguage("LEFT")}</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(rightCount)}/{formatUSD(detail.right)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={rightCount} max={detail.right} />
          </div>
          <small>{getLanguage("RIGHT")}</small>
        </div>
        <div className="mt-3">
          <div>
            {detail.maintain} {getLanguage("DAYS")}
          </div>
          <small>{getLanguage("MAINTAIN")}</small>
        </div>
      </Paper>
    </Grid>
  );
};

const RankSupervisor = () => {
  const { mining, user } = useSelector((state) => state);
  const { information } = user;
  const { rank } = information;
  const { achievement } = mining;
  const detail = rankDetail[3];
  const leftCount = achievement ? achievement.leftCoachCount : 0;
  const rightCount = achievement ? achievement.rightCoachCount : 0;
  const memberCount = achievement ? leftCount + rightCount : 0;
  const oneMonthPvLeft = achievement ? achievement.oneMonthPvLeft : 0;
  const oneMonthPvRight = achievement ? achievement.oneMonthPvRight : 0;
  const threeMothPvLeft = achievement ? achievement.threeMothPvLeft : 0;
  const threeMothPvRight = achievement ? achievement.threeMothPvRight : 0;

  let status = false;
  if (
    memberCount >= detail.member &&
    ((oneMonthPvLeft >= detail.monthPv && oneMonthPvRight >= detail.monthPv) ||
      (threeMothPvLeft >= detail.quarterPv &&
        threeMothPvRight >= detail.quarterPv)) &&
    leftCount >= detail.left &&
    rightCount >= detail.right
  ) {
    status = true;
  }

  return (
    <Grid item xs={12} sm={isMobileApp() ? 12 : 6} lg={isMobileApp() ? 12 : 4}>
      <Paper
        variant="outlined"
        className={`p-4 text-center ${rank === detail.key ? "active" : ""}`}
        style={{ height: "100%" }}
      >
        <Avatar
          className="m-auto"
          src={`${S3_URL}${ranks[detail.key].image}`}
          style={{ width: 70, height: 70 }}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className="mt-3 mb-3"
        >
          <Grid item>
            <Typography>{detail.key}</Typography>
          </Grid>
          <Grid item>
            <CheckCircleOutline className="ml-2" color={status ? "primary" : "disabled"} />
          </Grid>
        </Grid>
        <div>
          <div>
            {memberCount}/{detail.member}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={memberCount} max={detail.member} />
          </div>
          <small>
            {getLanguage(detail.beforeRank) + " " + getLanguage("MEMBER")}
          </small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(oneMonthPvLeft)}/{formatUSD(detail.monthPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={oneMonthPvLeft} max={detail.monthPv} />
          </div>
          <small>{getLanguage("MONTHLY")} Left PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(oneMonthPvRight)}/{formatUSD(detail.monthPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={oneMonthPvRight} max={detail.monthPv} />
          </div>
          <small>{getLanguage("MONTHLY")} Right PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(threeMothPvLeft)}/{formatUSD(detail.quarterPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={threeMothPvLeft} max={detail.quarterPv} />
          </div>
          <small>{getLanguage("QUARTERLY")} Left PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(threeMothPvRight)}/{formatUSD(detail.quarterPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={threeMothPvRight} max={detail.quarterPv} />
          </div>
          <small>{getLanguage("QUARTERLY")} Left PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(leftCount)}/{formatUSD(detail.left)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={leftCount} max={detail.left} />
          </div>
          <small>{getLanguage("LEFT")}</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(rightCount)}/{formatUSD(detail.right)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={rightCount} max={detail.right} />
          </div>
          <small>{getLanguage("RIGHT")}</small>
        </div>
        <div className="mt-3">
          <div>
            {detail.maintain} {getLanguage("DAYS")}
          </div>
          <small>{getLanguage("MAINTAIN")}</small>
        </div>
      </Paper>
    </Grid>
  );
};

const RankFounder = () => {
  const { mining, user } = useSelector((state) => state);
  const { information } = user;
  const { rank } = information;
  const { achievement } = mining;
  const detail = rankDetail[4];
  const leftCount = achievement ? achievement.leftSupervisorCount : 0;
  const rightCount = achievement ? achievement.rightSupervisorCount : 0;
  const memberCount = achievement ? leftCount + rightCount : 0;
  const oneMonthPvLeft = achievement ? achievement.oneMonthPvLeft : 0;
  const oneMonthPvRight = achievement ? achievement.oneMonthPvRight : 0;
  const threeMothPvLeft = achievement ? achievement.threeMothPvLeft : 0;
  const threeMothPvRight = achievement ? achievement.threeMothPvRight : 0;

  let status = false;
  if (
    memberCount >= detail.member &&
    ((oneMonthPvLeft >= detail.monthPv && oneMonthPvRight >= detail.monthPv) ||
      (threeMothPvLeft >= detail.quarterPv &&
        threeMothPvRight >= detail.quarterPv)) &&
    leftCount >= detail.left &&
    rightCount >= detail.right
  ) {
    status = true;
  }

  return (
    <Grid item xs={12} sm={isMobileApp() ? 12 : 6} lg={isMobileApp() ? 12 : 4}>
      <Paper
        variant="outlined"
        className={`p-4 text-center ${rank === detail.key ? "active" : ""}`}
        style={{ height: "100%" }}
      >
        <Avatar
          className="m-auto"
          src={`${S3_URL}${ranks[detail.key].image}`}
          style={{ width: 70, height: 70 }}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className="mt-3 mb-3"
        >
          <Grid item>
            <Typography>{detail.key}</Typography>
          </Grid>
          <Grid item>
            <CheckCircleOutline className="ml-2" color={status ? "primary" : "disabled"} />
          </Grid>
        </Grid>
        <div>
          <div>
            {memberCount}/{detail.member}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={memberCount} max={detail.member} />
          </div>
          <small>
            {getLanguage(detail.beforeRank) + " " + getLanguage("MEMBER")}
          </small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(oneMonthPvLeft)}/{formatUSD(detail.monthPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={oneMonthPvLeft} max={detail.monthPv} />
          </div>
          <small>{getLanguage("MONTHLY")} Left PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(oneMonthPvRight)}/{formatUSD(detail.monthPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={oneMonthPvRight} max={detail.monthPv} />
          </div>
          <small>{getLanguage("MONTHLY")} Right PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(threeMothPvLeft)}/{formatUSD(detail.quarterPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={threeMothPvLeft} max={detail.quarterPv} />
          </div>
          <small>{getLanguage("QUARTERLY")} Left PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(threeMothPvRight)}/{formatUSD(detail.quarterPv)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={threeMothPvRight} max={detail.quarterPv} />
          </div>
          <small>{getLanguage("QUARTERLY")} Left PV</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(leftCount)}/{formatUSD(detail.left)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={leftCount} max={detail.left} />
          </div>
          <small>{getLanguage("LEFT")}</small>
        </div>
        <div className="mt-3">
          <div>
            {formatUSD(rightCount)}/{formatUSD(detail.right)}
          </div>
          <div className="mt-1 mb-1">
            <ProgressBar value={rightCount} max={detail.right} />
          </div>
          <small>{getLanguage("RIGHT")}</small>
        </div>
        <div className="mt-3">
          <div>
            {detail.maintain} {getLanguage("DAYS")}
          </div>
          <small>{getLanguage("MAINTAIN")}</small>
        </div>
      </Paper>
    </Grid>
  );
};

export default function Achievement() {

  const { mining } = useSelector((state) => state);
  const { achievement } = mining;

  return achievement ? (
    <div className="achievement">
      <Container maxWidth={isMobileApp() ? false : "md"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-page">
              {getLanguage("ACHIEVEMENT")}
            </Typography>
            <Grid container spacing={3} justify="center">
              <Membership />
              <RankPro />
              <RankMaster />
              <RankCoach />
              <RankSupervisor />
              <RankFounder />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : (
    <CustomLoading />
  );
}
