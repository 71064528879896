import React, { useState } from "react";
import ChangePassword from "./ChangePassword";
import OffGA from "./OffGA";
import OnGA from "./OnGA";
import {
  TableContainer,
  Table,
  TableCell,
  TableBody,
  Dialog,
  TableRow,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { isMobileApp } from "../../../utils/auth";

function Setting(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { user } = props;
  const { information } = user;
  const { gaEnable, verifyLevel } = information;
  const [showChangeGA, setShowChangeGA] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const history = useHistory();

  return (
    <div className={clsx("card", (isMobile || isMobileApp()) ? "" : "profile-setting")} >
      <div className="card-header">
        <b className="text-color-3">{getLanguage("SECURITY")}</b>
      </div>
      <div className="card-body">
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {getLanguage("IDENTITY_VERIFICATION")}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color={"primary"}
                    disabled={
                      verifyLevel === "PENDING" ||
                      verifyLevel === "VERIFIED" ||
                      !verifyLevel
                    }
                    onClick={() => {
                      isMobileApp() ?
                        history.push("/mobile-app/dashboard/verify") :
                        history.push("/dashboard/verify")
                    }}
                  >
                    {verifyLevel === "PENDING"
                      ? getLanguage("PENDING")
                      : verifyLevel === "VERIFIED"
                        ? getLanguage("VERIFIED")
                        : getLanguage("VERIFY")}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {getLanguage("GA")}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color={gaEnable ? "secondary" : "primary"}
                    onClick={() => setShowChangeGA(true)}
                  >
                    {gaEnable
                      ? getLanguage("TURN_OFF")
                      : getLanguage("TURN_ON")}
                  </Button>
                  <Dialog
                    fullScreen={fullScreen}
                    open={showChangeGA}
                    onClose={() => setShowChangeGA(false)}
                    scroll="body"
                    style={{ overflow: "revert" }}
                  >
                    {gaEnable ? (
                      <OffGA
                        user={user}
                        open={showChangeGA}
                        close={() => setShowChangeGA(false)}
                      />
                    ) : (
                      <OnGA
                        user={user}
                        open={showChangeGA}
                        close={() => setShowChangeGA(false)}
                      />
                    )}
                  </Dialog>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {getLanguage("LOGIN_PASSWORD")}
                </TableCell>
                <TableCell align="right">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setShowChangePassword(true)}
                  >
                    {getLanguage("CHANGE")}
                  </Button>
                  <Dialog
                    fullScreen={fullScreen}
                    open={showChangePassword}
                    onClose={() => setShowChangePassword(false)}
                    scroll="body"
                    style={{ overflow: "revert" }}
                  >
                    <ChangePassword
                      open={showChangePassword}
                      close={() => setShowChangePassword(false)}
                    />
                  </Dialog>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ div>
  );
}

export default Setting;
