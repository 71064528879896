import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

function Index() {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      align="center"
    >
      {"Copyright © "}
      <a
        className={classes.link + " text-primary"}
        href="https://miningclub.org/"
        target="_blank"
        rel="noreferrer"
      >
        miningclub.org
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
export default Index;

//#region CSS
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
//#endregion
