import {
  FETCH_TRANSACTION_HISTORY,
  FETCH_USER,
  GET_ACHIEVEMENT,
  GET_BALANCE,
  GET_F1_MEMBER,
  GET_F1_MEMBER_IN_BINARY_TREE,
  GET_F1_MEMBER_NOT_IN_BINARY_TREE,
  GET_MINED_BALANCE,
  GET_MY_PACKAGE_LIST,
  GET_PACKAGE_LIST,
  GET_TRANSACTION_BINARY,
  GET_TRANSACTION_DIRECT,
  GET_TRANSACTION_MATCHING,
  GET_TRANSACTION_MINE,
} from "../constants";
import {
  ENDPOINT_GET_ACHIEVEMENT,
  ENDPOINT_GET_BALANCE,
  ENDPOINT_GET_F1_MEMBER,
  ENDPOINT_GET_PACKAGE,
  ENDPOINT_GET_POST_LOT,
  ENDPOINT_POST_TRANSACTION,
  ENDPOINT_POST_TRANSACTION_BINARY,
  ENDPOINT_POST_TRANSACTION_DIRECT,
  ENDPOINT_POST_TRANSACTION_MATCHING,
  ENDPOINT_POST_TRANSACTION_MINE,
} from "../settings/endpoint";
import { get, post } from "../utils/api";

//get new profile
export const _getNewProfile = () => (dispatch) => {
  get(ENDPOINT_GET_ACHIEVEMENT, (data) => {
    dispatch({
      type: GET_MINED_BALANCE,
      payload: data.balances.filter((item) => item.asset === "FIL"),
    });
    dispatch({
      type: GET_ACHIEVEMENT,
      payload: data.achievement,
    });
    dispatch({
      type: FETCH_USER,
      payload: data.user,
    });
  });
};

export const _getTransaction = (page, pageSize, filters) => (dispatch) => {
  dispatch({
    type: FETCH_TRANSACTION_HISTORY,
    payload: null,
  });
  post(
    ENDPOINT_POST_TRANSACTION,
    {
      page,
      pageSize,
      search: "",
      orderBy: "",
      responseMeta: true,
      filters,
    },
    (data) => {
      dispatch({
        type: FETCH_TRANSACTION_HISTORY,
        payload: data,
      });
    }
  );
};

export const _getBinaryTransaction = (page, pageSize) => (dispatch) => {
  post(
    ENDPOINT_POST_TRANSACTION_BINARY,
    {
      page,
      pageSize,
      search: "",
      orderBy: "",
      responseMeta: true,
      filters: {},
    },
    (data) => {
      dispatch({
        type: GET_TRANSACTION_BINARY,
        payload: data,
      });
    }
  );
};

export const _getDirectTransaction = (page, pageSize) => (dispatch) => {
  post(
    ENDPOINT_POST_TRANSACTION_DIRECT,
    {
      page,
      pageSize,
      search: "",
      orderBy: "",
      responseMeta: true,
      filters: {},
    },
    (data) => {
      dispatch({
        type: GET_TRANSACTION_DIRECT,
        payload: data,
      });
    }
  );
};

export const _getMatchingTransaction = (page, pageSize) => (dispatch) => {
  post(
    ENDPOINT_POST_TRANSACTION_MATCHING,
    {
      page,
      pageSize,
      search: "",
      orderBy: "",
      responseMeta: true,
      filters: {},
    },
    (data) => {
      dispatch({
        type: GET_TRANSACTION_MATCHING,
        payload: data,
      });
    }
  );
};

export const _getMineTransaction = (page, pageSize) => (dispatch) => {
  post(
    ENDPOINT_POST_TRANSACTION_MINE,
    {
      page,
      pageSize,
      search: "",
      orderBy: "",
      responseMeta: false,
      filters: {},
    },
    (data) => {
      dispatch({
        type: GET_TRANSACTION_MINE,
        payload: data,
      });
    }
  );
};

export const _getF1MemberInBinaryTree =
  (page, pageSize, username) => (dispatch) => {
    post(
      ENDPOINT_GET_F1_MEMBER,
      {
        page,
        pageSize,
        search: "",
        orderBy: "",
        responseMeta: false,
        filters: {
          isInBinaryTree: true,
          username,
        },
      },
      (data) => {
        console.log(data);
        dispatch({
          type: GET_F1_MEMBER_IN_BINARY_TREE,
          payload: data,
        });
      }
    );
  };

export const _getF1MemberNotInBinaryTree = () => (dispatch) => {
  post(
    ENDPOINT_GET_F1_MEMBER,
    {
      page: 1,
      pageSize: 100,
      search: "",
      orderBy: "",
      responseMeta: false,
      filters: {
        isInBinaryTree: false,
      },
    },
    (data) => {
      data.items = data.items.filter((item) => item.package !== "NO_PACKAGE");
      dispatch({
        type: GET_F1_MEMBER_NOT_IN_BINARY_TREE,
        payload: data,
      });
    }
  );
};

export const _getF1Member = (page, pageSize, filters) => (dispatch) => {
  dispatch({
    type: GET_F1_MEMBER,
    payload: null,
  });
  post(
    ENDPOINT_GET_F1_MEMBER,
    {
      page,
      pageSize,
      search: "",
      orderBy: "",
      responseMeta: false,
      filters,
    },
    (data) =>
      dispatch({
        type: GET_F1_MEMBER,
        payload: data,
      })
  );
};

export const _getPackageList = () => (dispatch) => {
  get(ENDPOINT_GET_PACKAGE, (data) =>
    dispatch({
      type: GET_PACKAGE_LIST,
      payload: data,
    })
  );
};

export const _getMyPackageList = () => (dispatch) => {
  get(ENDPOINT_GET_POST_LOT, (data) =>
    dispatch({
      type: GET_MY_PACKAGE_LIST,
      payload: data,
    })
  );
};

export const _getBalance = () => (dispatch) => {
  get(ENDPOINT_GET_BALANCE, (data) =>
    dispatch({
      type: GET_BALANCE,
      payload: data,
    })
  );
};
