import { IconButton, MenuItem, Popover } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import React, { useState } from "react";
import i18next from '../../../languages/getLanguage';
import { getCountryFlags } from "../../../settings";

const BtnLang = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //#region 
  const handleLanguageMenuClose = (lang) => {
    if (
      lang === "en" ||
      lang === "vi" ||
      lang === "kr" ||
      lang === "de" ||
      lang === "es" ||
      lang === "ru"
    ) {
      i18next.changeLanguage(lang);
    }
  };
  //#endregion

  return (
    <div className="text-center w-100">
      <IconButton onClick={handleClick} className="text-primary">
        <Language />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            handleLanguageMenuClose("en");
            handleClose();
          }}
        >
          <img className="mr-2" src={getCountryFlags("gb", 24)} />English
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLanguageMenuClose("vi")
            handleClose();
          }}
        >
          <img className="mr-2" src={getCountryFlags("vn", 24)} />Vietnamese
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLanguageMenuClose("kr");
            handleClose();
          }}
        >
          <img className="mr-2" src={getCountryFlags("kr", 24)} />Korean
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleLanguageMenuClose("de");
            handleClose();
          }}
        >
          <img className="mr-2" src={getCountryFlags("de", 24)} />German
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLanguageMenuClose("es");
            handleClose();
          }}
        >
          <img className="mr-2" src={getCountryFlags("es", 24)} />Spanish
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLanguageMenuClose("ru");
            handleClose();
          }}
        >
          <img className="mr-2" src={getCountryFlags("ru", 24)} />Russian
        </MenuItem> */}
      </Popover>
    </div>
  );
};

export default BtnLang;
