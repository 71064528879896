import React from "react";
import { Container, Typography } from "@material-ui/core";
import "./style.scss";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getLanguage } from "../../../../languages/getLanguage";


const Partner = () => {

  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className="partner">
      <Container>
        <Typography
          variant="h4"
          className="text-center m-auto"
          color="primary"
          style={{ maxWidth: 600 }}
        >{getLanguage("partner_title")}</Typography>

        {
          // screen ? <Mobile /> : <Desktop />
        }

      </Container>
    </div>
  );
};

export default Partner;

const Desktop = () => {
  return (
    <div className="partner-desktop">
      {/* top */}
      <ul className="list-card list-card-top">
        <li className="card-item">
          <a href="https://filecoin.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-1.png"
              alt="partner_1"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://protocol.ai" target="_blank">
            <img
              src="/images/partner-img/partner-item-2.png"
              alt="partner_2"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://filfox.info/ko" target="_blank">
            <img
              src="/images/partner-img/partner-item-3.png"
              alt="partner_3"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://ipfs.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-4.png"
              alt="partner_4"
            />
          </a>
        </li>
      </ul>
      {/* bottom */}
      <ul className="list-card list-card-bottom">
        <li className="card-item">
          <a href="https://spexchange.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-5.png"
              alt="partner_5"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://etherscan.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-6.png"
              alt="partner_6"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://bscscan.com" target="_blank">
            <img
              src="/images/partner-img/partner-item-7.png"
              alt="partner_7"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://spwallet.org" target="_blank">
            <img
              src="/images/partner-img/partner-item-8.png"
              alt="partner_8"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://tronscan.org/#/" target="_blank">
            <img
              src="/images/partner-img/partner-item-9.png"
              alt="partner_9"
            />
          </a>
        </li>
      </ul>
    </div>
  )
}

const Mobile = () => {
  return (
    <div className="partner-mobile">
      {/* slot-1 */}
      <ul className="list-card list-card-slot-1">
        <li className="card-item">
          <a href="https://filecoin.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-1.png"
              alt="partner_1"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://protocol.ai" target="_blank">
            <img
              src="/images/partner-img/partner-item-2.png"
              alt="partner_2"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://filfox.info/ko" target="_blank">
            <img
              src="/images/partner-img/partner-item-3.png"
              alt="partner_3"
            />
          </a>
        </li>
      </ul>
      {/* slot-2 */}
      <ul className="list-card list-card-slot-2">
        <li className="card-item">
          <a href="https://ipfs.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-4.png"
              alt="partner_4"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://spexchange.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-5.png"
              alt="partner_5"
            />
          </a>
        </li>
      </ul>
      {/* slot-3 */}
      <ul className="list-card list-card-slot-3">
        <li className="card-item">
          <a href="https://etherscan.io" target="_blank">
            <img
              src="/images/partner-img/partner-item-6.png"
              alt="partner_6"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://bscscan.com" target="_blank">
            <img
              src="/images/partner-img/partner-item-7.png"
              alt="partner_7"
            />
          </a>
        </li>
        <li className="card-item">
          <a href="https://spwallet.org" target="_blank">
            <img
              src="/images/partner-img/partner-item-8.png"
              alt="partner_8"
            />
          </a>
        </li>
      </ul>
      {/* slot-4 */}
      {/* <ul className="list-card list-card-slot-4"></ul> */}
      {/* slot-5 */}
      {/* <ul className="list-card list-card-slot-5">
            <li className="card-item">
              <a href="https://tronscan.org/#/" target="_blank">
                <img
                  src="/images/partner-img/partner-item-9.png"
                  alt="partner_9"
                />
              </a> 
            </li>
          </ul> */}
    </div>
  )
}