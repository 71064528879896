import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function CustomLoading() {
  return (
    <div
      style={{
        height: "calc(100vh - 120px)",
        display: "flex",
      }}
    >
      <CircularProgress color="primary" style={{ margin: "auto" }} />
    </div>
  );
}
