export function random() {
    const listBackgroundImage = [
        '/images/login-bg/MiningClub-01.png',
        '/images/login-bg/MiningClub-02.png',
        '/images/login-bg/MiningClub-03.png',
        '/images/login-bg/MiningClub-04.png',
        '/images/login-bg/MiningClub-05.png',
    ];
    var randomNumber = Math.floor(Math.random() * listBackgroundImage.length);
    return listBackgroundImage[randomNumber];
};