import {
  Container,
  Drawer,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { getLanguage } from "../../../languages/getLanguage";

export default function GACode({ open, _onCancel, _onConfirm, loading }) {
  return (
    <Drawer anchor="left" open={open} className={"custom-modal-vk"}>
      <Container maxWidth="md" style={{ padding: 0 }}>
        <div
          style={{
            textAlign: "center",
            padding: "30px 20px",
            minWidth: 300,
            whiteSpace: "nowrap",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            onClick={_onCancel}
          >
            <Close />
          </IconButton>
          <Typography variant="h4" className="mb-2">
            2-Step Verification
          </Typography>
          <Typography variant="body1" className="mb-4">
            Please enter the Google Authenticator Code
          </Typography>
          <form onSubmit={_onConfirm} noValidate>
            <FormControl required fullWidth>
              <InputLabel>{getLanguage("GA_CODE")}</InputLabel>
              <Input
                name="gaCode"
                inputProps={{
                  maxLength: 6,
                }}
              />
              <FormHelperText error></FormHelperText>
            </FormControl>
            <div className="mt-5">
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  getLanguage("CONTINUE")
                )}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </Drawer>
  );
}
