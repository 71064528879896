import React, { useEffect, useState } from "react";
import { _getLoginActivity, _getProfile } from "../../../actions/userActions";
import { getLanguage } from "../../../languages/getLanguage";
import {
  FormControl,
  Button,
  AppBar,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  InputLabel,
  Input,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { _getBalance } from "../../../actions/miningActions";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { checkEmpty, checkNumber } from "../../../settings/validate";
import { post, get } from "../../../utils/api";
import {
  ENDPOINT_POST_WITHDRAW_TRANSFER,
  ENDPOINT_GET_VALIDATE_ADDRESS,
} from "../../../settings/endpoint";
import { formatAmount, formatAddress } from "../../../settings/format";
import NumberFormat from "react-number-format";
import { CustomSwal, CustomToast } from "../../../settings";
import { _getWithdrawHistory } from "../../../actions/walletActions";
import { useDispatch, useSelector } from "react-redux";
import TransactionConfirm from "./TransactionConfirm";
import GACode from "./GACode";

function Withdraw(props) {
  const { balance, _showWarning } = props;
  const { networks } = balance;

  const [valueTab, setValueTab] = useState(0);
  const [panesTab, setPanesTab] = useState([
    {
      menuItem: "",
      render: null,
    },
  ]);

  const _handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    var panes = [];
    for (const item of networks) {
      var tab = {
        menuItem: item.network,
        render: (
          <TabNetworkList
            balance={balance}
            network={item}
            _showWarning={_showWarning}
          />
        ),
      };
      panes.push(tab);
    }
    setPanesTab(panes);
    setValueTab(0);
  }, [balance]);

  return (
    <div className="card">
      <AppBar position="static" color="default" className="mb-4">
        <Tabs
          value={valueTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={_handleChangeTab}
          variant="fullWidth"
          scrollButtons="auto"
        >
          {panesTab.map((item, index) => (
            <Tab label={item.menuItem} key={index} value={item.index} />
          ))}
        </Tabs>
      </AppBar>
      <div className="card-body">{panesTab[valueTab].render}</div>
    </div>
  );
}

export default Withdraw;

function TabNetworkList(props) {
  const { balance, network, _showWarning } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [youWillGet, setYouWillGet] = useState(0);
  const dispatch = useDispatch();
  const [fieldError, setFieldError] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isFlag, setIsFlag] = useState(false);
  const { user } = useSelector((state) => state);
  const { information } = user;
  const [showGaInput, setShowGaInput] = useState(false);
  const [gaCodeLoading, setGaCodeLoading] = useState(false);

  useEffect(() => {
    setAddress("");
    setAmount("");
  }, [balance, network]);

  const _handleSubmit = () => {
    setLoading(false);
    const tempErrors = [...fieldError];
    if (checkEmpty(amount)) {
      tempErrors.push({
        field: "amount",
        error: getLanguage("PLEASE_ENTER_AMOUNT"),
      });
    } else {
      var num = parseFloat(amount.replaceAll(",", ""));
      if (isNaN(num)) {
        num = 0;
      }
      if (checkNumber(num)) {
        tempErrors.push({
          field: "amount",
          error: getLanguage("INVALID_AMOUNT"),
        });
      } else {
        if (num <= 0) {
          tempErrors.push({
            field: "amount",
            error: getLanguage("NUMBER_GREATER", {
              param: "Amount",
              number: 0,
            }),
          });
        }
      }
    }
    if (num - network.withdrawFee <= 0 || num < network.minimumWithdraw) {
      tempErrors.push({
        field: "amount",
        error: getLanguage("YOUR_AMOUNT_TOO_LOW", { num: formatAmount(network.minimumWithdraw), coin: balance.asset })
      });
    }

    setFieldError(tempErrors);

    setIsFlag(false);
    if (tempErrors.length === 0) {
      setFieldError(null);
      if (information.verifyLevel === "VERIFIED" && information.gaEnable) {
        setShowConfirm(true);
        setLoading(true);
      } else {
        _showWarning();
        setLoading(false);
      }
    }
  };

  const _handleWithdraw = (gaCode) => {
    setShowConfirm(false);
    var param;
    if (gaCode) {
      param = {
        type: "WITHDRAW",
        asset: balance.asset,
        network: network.network,
        amount: youWillGet,
        address,
        gaCode,
        addressTag: "",
      };
    } else {
      param = {
        type: "WITHDRAW",
        asset: balance.asset,
        network: network.network,
        amount: youWillGet,
        address,
        addressTag: "",
      };
    }
    post(
      ENDPOINT_POST_WITHDRAW_TRANSFER,
      param,
      () => {
        setAddress("");
        setAmount("");
        setYouWillGet("");
        setLoading(false);
        CustomToast("success", getLanguage("Withdraw successfully"));
        dispatch(_getWithdrawHistory(1, 10, "WITHDRAW"));
        dispatch(_getWithdrawHistory(1, 10, "TRANSFER"));
        setGaCodeLoading(false);
        setShowGaInput(false);
      },
      (error) => {
        CustomToast("error", getLanguage(error.code));
        setGaCodeLoading(false);
        setLoading(false);
        setFieldError([...fieldError, { field: "all", error: getLanguage(error.code) }]);
      }
    );
  };

  useEffect(() => {
    if (isFlag) _handleSubmit();
  }, [isFlag]);

  const _checkAddress = (e, action) => {
    let isSubmit = action === "submit";
    let tempErrors = [];
    if (isSubmit) e.preventDefault();
    if (checkEmpty(address)) {
      tempErrors.push({
        field: "address",
        error: getLanguage("PLEASE_ENTER_ADDRESS"),
      });
      setFieldError(tempErrors);
      if (isSubmit) setIsFlag(true);
    } else {
      setLoading(true);
      setFieldError(null);

      get(
        `${ENDPOINT_GET_VALIDATE_ADDRESS}?network=${network.network}&address=${address}`,
        (data) => {
          if (!data.result) {
            tempErrors.push({
              field: "address",
              error: getLanguage("INVALID_ADDRESS"),
            });
          }
          setFieldError(tempErrors);
          if (!isSubmit) setLoading(false);
          if (isSubmit) setIsFlag(true);
        },
        () => {
          tempErrors.push({
            field: "address",
            error: getLanguage("INVALID_ADDRESS"),
          });
          setFieldError(tempErrors);
          if (!isSubmit) setLoading(false);
          if (isSubmit) setIsFlag(true);
        }
      );
    }
  };

  const _checkError = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const _getErrorContent = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return check.error
      } else {
        return null;
      };
    } else {
      return null;
    }
  };

  const _resetErrors = () => setFieldError(null);

  const _handleConfirm = () => {
    setShowConfirm(false);
    if (information.gaEnable && information.verifyLevel === "VERIFIED") {
      setShowGaInput(true);
    } else {
      _handleWithdraw();
    }
  };

  const _onHandleInputGaCode = (e) => {
    e.preventDefault();
    setGaCodeLoading(true);
    const { value } = e.target.gaCode;
    if (checkEmpty(value)) {
      CustomToast("error", getLanguage("PLEASE_ENTER_GA_CODE"));
      setGaCodeLoading(false);
    } else if (value.trim().length !== 6) {
      CustomToast("error", getLanguage("WRONG_GACODE"));
      setGaCodeLoading(false);
    } else {
      _handleWithdraw(value);
    }
  };

  return (
    <>
      <form
        className="w-100"
        onSubmit={(e) => _checkAddress(e, "submit")}
        noValidate
      >
        <Grid container spacing={2}>
          {network.withdrawEnable ? (
            <>
              <Grid item xs={12}>
                <FormControl error={_checkError("address")} required fullWidth size="small">
                  <InputLabel>{getLanguage("ADDRESS")}</InputLabel>
                  <Input
                    value={address}
                    onChange={(e) => { setAddress(e.target.value); }}
                    onBlur={_checkAddress}
                  />
                  {
                    _checkError("address") &&
                    <FormHelperText error>
                      {_getErrorContent("address")}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={_checkError("amount")} required fullWidth size="small">
                  <InputLabel>{getLanguage("AMOUNT")}</InputLabel>
                  <NumberFormat
                    thousandSeparator={true}
                    inputMode="numeric"
                    customInput={Input}
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      var value = parseFloat(
                        e.target.value.replaceAll(",", "")
                      );
                      if (isNaN(value)) {
                        value = 0;
                      }
                      if (value > 0 && value >= network.minimumWithdraw) {
                        setYouWillGet((value - network.withdrawFee).toFixed(8));
                      } else {
                        setYouWillGet(0);
                      }
                    }}
                    placeholder={`${getLanguage("MINIMUM")}: ${formatAmount(
                      network.minimumWithdraw
                    )} ${balance.asset}`}
                  />
                  {
                    _checkError("amount") &&
                    <FormHelperText error>
                      {_getErrorContent("amount")}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} className="text-right mt-3 mb-3">
                <Typography>
                  {getLanguage("AMOUNT")}:{" "}
                  {amount === "" ? (
                    0
                  ) : (
                    <NumberFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}{" "}
                  {balance.asset}
                </Typography>
                <Typography>
                  {getLanguage("TRANSACTION_FEE")}:{" "}
                  {
                    <NumberFormat
                      value={network.withdrawFee}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }{" "}
                  {balance.asset}
                </Typography>
                <Typography>
                  {getLanguage("YOU_WILL_GET")}: {formatAmount(youWillGet)}{" "}
                  {balance.asset}
                </Typography>
              </Grid>
              {
                _checkError("all") &&
                <Grid item xs={12}>
                  <FormHelperText error={_checkError("all")}>
                    {_getErrorContent("all")}
                  </FormHelperText>
                </Grid>
              }
              <Grid item xs={12}>
                <Grid container className={classes.wrapper} justify="center">
                  <Grid xs={6}>
                    <Button
                      fullWidth
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={loading ? true : false}
                    >
                      {getLanguage("WITHDRAWAL")}
                    </Button>
                    {loading && (
                      <CircularProgress
                        color="primary"
                        size={30}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" color="initial" align="center">
                {getLanguage("WITHDRAW_DISABLE")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
      <GACode
        open={showGaInput}
        _onCancel={() => {
          setShowGaInput(false);
          setLoading(false);
        }}
        _onConfirm={_onHandleInputGaCode}
        loading={gaCodeLoading}
      />
      <TransactionConfirm
        open={showConfirm}
        title="Confirming for your withdraw"
        data={{
          amount: parseFloat(amount.replaceAll(",", "")),
          username: address,
          fee: network.withdrawFee,
          asset: balance.asset,
          youWillGet,
        }}
        _onCancel={() => {
          setShowConfirm(false);
          setLoading(false);
        }}
        _onConfirm={_handleConfirm}
        type="WITHDRAW"
      />
    </>
  );
}

//#region CSS
const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
}));
//#endregion
