import React from "react";
import {
    Container
} from '@material-ui/core';
import TitlePage from "../common/title-page";

function Index() {
    return (
        <div className="terms-of-services-page bg-main-page">
            <TitlePage title="Terms of services" />
            <Container maxWidth="lg" className="bg-content-page blog-page">
                <h4 className="mb-3">Statement</h4>
                <p className="mb-5">All services and operations are provided by www.miningclub.org (hereinafter referred to as MiningClub). “MiningClub Service Agreement” (hereinafter referred to as This Agreement) specifies the rights and obligations of MiningClub’s services which have been agreed on by both of MiningClub and its users. By using our site users agree to the terms and conditions of MiningClub. According to This Agreement, MiningClub acting as the operator of MiningClub website (<a target="_blank" href="https://www.miningclub.org">www.miningclub.org</a>)—will provide users with the relevant services. Users who do not agree to This Agreements are forbidden from using MiningClub website. MiningClub has right to revise This Agreement at any time. Upon any modification to This Agreement, MiningClub will update this page, so users can read the updated agreement at any time.</p>
                <h4 className="mb-3">Scope of services</h4>
                <ul className="mb-5">
                    <li>(1) MiningClub allows all users of the platform to deposit cryptocurrencies as an investment, buy and trade cloud hashrate with other users.</li>
                    <li>(2) The service operated by MiningClub allows the user to deposit cryptocurrencies including, but not limited to: Bitcoin, Filecoin and to buy and/or to sell commodities known as “GS” or Gigahashes and “MHS” or Megahashes. However, Miningclub offers the user its services for use only if the user fully understands, complies and agrees with This Agreement.</li>
                    <li>(3) Depending on the user’s place of residence, the user may not be able to use all the functions of the site. It is the user’s responsibility to follow those rules and laws in his/her place of residence and/or place from which the user accesses this Site.</li>
                    <li>(4) The user acknowledges and agrees that, when completing transactions, he is trading with other users, and that MiningClub acts only as an intermediary in such transactions, not as counterparty to any trade.</li>
                </ul>
                <h4 className="mb-3">User’s rights & responsibilities</h4>
                <ul className="mb-5">
                    <li>(1) The user has the right to enter and use the site and the services, as long as he/she agrees to and actually complies with This Agreement. By using the site, the user agrees to accept and comply with the terms and conditions stated herein.</li>
                    <li>(2) The user undertakes to read the entire terms of use carefully before using the site or any of the services provided by MiningClub</li>
                    <li>(3) The user undertakes to monitor all and any changes on his/her account, including but not limited to the balance matters.</li>
                    <li>(4) The user agrees that, whenever the transaction is made, the platform sends and receives the monetary sums and/or cryptocurrencies from the buyer’s and the seller’s Accounts in their name and on their behalf, through the platform.</li>
                    <li>(5) The user undertakes not to use the service to perform criminal activity of any sort, including but not limited to, money laundering, illegal gambling operations, financing terrorist organizations, or malicious hacking.</li>
                    <li>(6) The user is responsible for any and all damages caused and all liability actions brought against MiningClub for infringement of third party rights or violation of applicable laws.</li>
                </ul>
                <h4 className="mb-3">Miningclub’s rights & responsibilities</h4>
                <ul className="mb-5">
                    <li>(1) MiningClub has the right to suspend user’s account and block all cryptocurrency, fiat currency and commodity sums contained therein, in case of non-fulfilment or unduly fulfilment of the terms of use by the user.</li>
                    <li>(2) MiningClub undertakes to provide services with the utmost effort, due care and in accordance with these terms of use.</li>
                    <li>(3) MiningClub’s responsibility shall be limited to using reasonable technical efforts to ensure the receipt of the cryptocurrency transferred. When initiating cryptocurrency transactions to a user who is not the MiningClub user, MiningClub responsibility shall be further limited to ensuring the transfer of the necessary technical data to the cryptocurrency network.</li>
                    <li>(4) To the extent permitted by law, MiningClub is not responsible for any damages, loss of profit, loss of revenue, loss of business, loss of opportunity, loss of data, indirect or consequential loss unless the loss suffered is caused by a breach of the terms of use by MiningClub.</li>
                    <li>(5) MiningClub is not responsible for any malfunction, breakdown, delay or interruption of the Internet connection, or any reason why our site is unavailable at any given time. MiningClub have right to delete all of illegal information or against agreements without informed users.</li>
                </ul>
                <h4 className="mb-3">Privacy policy</h4>
                <ul className="mb-5">
                    <li>(1) When you create an MiningClub account, MiningClub will ask for personal information, which is not limited to your name, email address, telephone number, shipping address, credit card etc. MiningClub will never share your information with a third-party without notifying you first and asking for your permission.</li>
                    <li>(2) You acknowledge and agree that, MiningClub may, on requests from governmental authorities (including judicial and administrative departments), provide user information provided by you to MiningClub, transaction records and any other necessary information. If you allegedly infringe upon any other’s intellectual rights or other legitimate interests, MiningClub may provide the necessary ID information of you to the interest holder if MiningClub preliminarily decides that the infringement exists.</li>
                </ul>
                <h4 className="mb-3">Termination of agreement</h4>
                <ul className="mb-5">
                    <li>(1) You hereby agree that, MiningClub shall have the right to terminate all or part of MiningClub services to you, temporarily freeze or permanently freeze (cancel) the authorizations of your account on MiningClub at MiningClub’s sole discretion, without any prior notice, for whatsoever reason, and MiningClub shall not be liable to you or any third party; however, MiningClub shall have the right to keep and use the transaction data, records and other information that is related to such account.</li>
                    <li>(2) You agree that, after the termination of agreement between you and MiningClub, MiningClub shall still have the rights to: keep your user’s information and all the transaction information during your use of MiningClub services; Claim against you according to this agreement if you have violated any laws, This Agreement or the rules during your use of MiningClub services.</li>
                </ul>
                <h4 className="mb-3">Intellectual property</h4>
                <p className="mb-5">The trademarks, service marks and logos of MiningClub and others used on the site are the property of MiningClub and its respective owners. The software, text, images, graphics, data, prices, trades, charts, graphs, video and audio materials used on this site belong to MiningClub. The trademarks and other materials on the site should not be copied, reproduced, modified, republished, uploaded, posted, transmitted, scraped, collected or distributed in any form or by any means, no matter manual or automated. The use of any materials from the site on any other site or a networked computer environment for any other purpose is strictly prohibited; any such unauthorized use may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.</p>
                <h4 className="mb-3">Risk warning</h4>
                <ul className="mb-5">
                    <li>(1) The trading of commodities involves a significant amount of risk. Prices can fluctuate on any given day. Because of such price fluctuations, you may gain or lose value of your assets at any given moment. Any currency may be subject to large swings in value and may even become absolutely worthless. There is always an inherent risk that losses will occur as a result of buying, selling or trading anything on the market.</li>
                    <li>(2) There may be additional risks, which have not been foreseen or identified in the current terms of use. Every user has to carefully assess whether his/her financial situation and tolerance for risk is suitable for buying/selling/trading Bitcoins and Filecoin.</li>
                </ul>
                <h4 className="mb-3">Limitation of liability</h4>
                <ul className="mb-5">
                    <li>(1) In no event shall MiningClub be liable for any failure or delay of service resulted from regular equipment maintenance of the information network, connection error of information network, error of computers, communication or other systems, power failure, strike, labor disputes, riots, revolutions, chaos, insufficiency of production or materials, fire, flood, tornado, blast, war, governmental acts, judicial orders or omission by third parties.</li>
                    <li>(2) To the extent permitted by law, MiningClub is not responsible for any damages, loss of profit, loss of revenue, loss of business, loss of opportunity, loss of data, indirect or consequential loss unless the loss suffered is caused by a breach of the Service Agreement by MiningClub.</li>
                </ul>
                <h4 className="mb-3">Legal Disputes and Disclaimer</h4>
                <ul className="mb-5">
                    <li>(1) The Company shall not guarantee any profit under the plans purchased by the Customer. The Customer is fully aware that mining rewards may vary due to several factors.</li>
                    <li>(2) The Company shall not be liable for disputes regarding the provision and use of services between customers.</li>
                    <li>(3) The Company shall not be liable for cases where it cannot provide the Services due to natural disasters, unforeseeable events or any other reasons beyond its control.</li>
                    <li>(4) The Company shall not be liable for the reliability or accuracy of the information and data issued by individual customers.</li>
                    <li>(5) The Company shall not be liable for transactions between customers or between the Customer and a third party conducted through the service or advertisements provided by the Company.</li>
                    <li>(6) The Company shall not be liable for the failure of cryptocurrency transfers due to the failure of the cryptocurrency issuance management system or telecommunications services provide by a third party.</li>
                    <li>(7) The Company shall not be liable for defects or limitations caused by an unforeseeable failure of the cryptocurrency issuance management system or technical limitations due to the nature of cryptocurrency.</li>
                    <li>(8) Within the scope permitted by the applicable law, the Company shall not be liable for damages to the Customer caused by unauthorized access to the server, interruption of operations or unauthorized use of the Customer's data by a third party.</li>
                </ul>
                <h4 className="mb-3">Governing law and jurisdiction</h4>
                <ul className="mb-5">
                    <li>(1) Disputes regarding the Agreement shall be resolved in accordance with the laws of UK.</li>
                    <li>(2) Disputes between the Company and the Customer that arise from the Agreement shall be brought to the competent court in UK.</li>
                </ul>
                <h4 className="mb-3">Sustainability of the Agreement</h4>
                <ul>
                    <li>(1) If any provision of the Agreement is rendered invalid, the remaining provisions shall not be affected.</li>
                    <li>(2) The Agreement were drawn up in English as well as in all other languages supported by the MiningClub Website. In the event of any discrepancies between the English version and any other language version, the English version shall prevail.</li>
                    <li>(3) The Customer fully recognizes and agrees to the Agreement above as well as the specifications of the Mining Plans provided by MiningClub. Prices are payable in the equivalent amount of the cryptocurrency relevant to the selected mining plan.</li>
                </ul>
            </Container>
        </div>
    );
}

export default Index;
