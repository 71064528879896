import React from "react";
import { Grid, Typography, Paper, Divider } from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";
import { S3_URL } from "../../../settings";
import { formatAmount, formatUSD } from "../../../settings/format";

function Balances(props) {
  const { balances, minedBalances } = props;
  return (
    <>
      {minedBalances &&
        minedBalances.map((item, index) => (
          <Grid xs={12} sm={6} item key={index}>
            <Paper variant="outlined" className="p-3">
              <Grid
                container
                alignItems="center"
                className="mb-3"
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h5" style={{ fontWeight: 300 }}>
                    {getLanguage("MINING_BALANCE_COIN", { coin: item.asset })}
                  </Typography>
                </Grid>
                <Grid item className="text-right">
                  <img
                    src={`${S3_URL}/coins/${item.asset}.png`}
                    style={{ width: 30 }}
                    alt={item.asset}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography variant="h6">
                    {formatAmount(item.totalMined)}
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    <small>$</small>
                    {formatUSD(item.totalMined * item.usdPrice)}
                  </Typography>
                </Grid>
                <Grid xs={12} item className="show-lg mt-2 mb-2">
                  <Divider />
                </Grid>
                <Grid md={6} xs={12} item>
                  <Grid container>
                    <Grid item xs={6} className="text-center">
                      <small>{getLanguage("RELEASED")}</small>
                      <Typography variant="subtitle1" color="primary">
                        {formatAmount(item.totalRelease)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className="text-center">
                      <small>{getLanguage("BLOCKED")}</small>
                      <Typography variant="subtitle1" color="primary">
                        {formatAmount(item.blockAmount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      {balances &&
        balances.map((item, index) => (
          <Grid xs={12} sm={6} item key={index}>
            <Paper
              variant="outlined"
              className="p-3"
              style={{ height: "100%" }}
            >
              <Grid
                container
                alignItems="center"
                justify="space-between"
                className="mb-3"
              >
                <Grid item>
                  <Typography variant="h5" style={{ fontWeight: 300 }}>
                    {getLanguage("BALANCE_COIN", { coin: item.asset })}
                  </Typography>
                </Grid>
                <Grid item className="text-right">
                  <img
                    src={`${S3_URL}/coins/${item.asset}.png`}
                    style={{ width: 30 }}
                    alt={item.asset}
                  />
                </Grid>
              </Grid>
              <Typography variant="h6">{formatAmount(item.amount)}</Typography>
              <Typography variant="subtitle1" color="primary">
                <small>$</small>
                {formatUSD(item.amount * item.usdPrice)}
              </Typography>
            </Paper>
          </Grid>
        ))}
    </>
  );
}

export default Balances;
