import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import "./style.scss";
import { getLanguage } from "../../../../languages/getLanguage";

const Media = () => {
  return (
    <div className="media">
      <Container>
        <Typography
          variant="h4"
          className="text-center pb-5 text-white"
        >
          {getLanguage("MEDIA")}
        </Typography>
        <Grid container spacing={2} className="list-card" justify="center">
          <Grid item xs={6} sm={4} md={3} className="card-item">
            <a
              href="https://www.marketwatch.com/press-release/mining-club-announces-working-on-the-extraction-of-filecoin-cryptocurrency-2021-08-18"
              target="_blank"
              rel="noreferrer"
            >
              <div className="box-card">
                <img
                  src="/images/media-img/marketwatch.svg"
                  alt="media-1"
                />
              </div>
            </a>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className="card-item">
            <a
              href="https://finance.yahoo.com/news/mining-club-announces-working-extraction-154500085.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="box-card">
                <img src="/images/media-img/yahoo_finance.png" alt="media-2" />
              </div>
            </a>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className="card-item">
            <a
              href="https://www.bloomberg.com/news/articles/2020-08-03/filecoin-to-spur-decentralized-data-storage-in-new-internet-era"
              target="_blank"
              rel="noreferrer"
            >
              <div className="box-card">
                <img src="/images/media-img/bloomberg.png" alt="media-3" />
              </div>
            </a>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className="card-item">
            <a
              href="https://www.coindesk.com/filecoin-miners-go-on-strike-one-day-after-mainnet-launch-prompting-early-reward-release"
              target="_blank"
              rel="noreferrer"
            >
              <div className="box-card">
                <img src="/images/media-img/coindesk.png" alt="media-3" />
              </div>
            </a>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className="card-item">
            <a
              href="https://n.news.naver.com/article/008/0004620574"
              target="_blank"
              rel="noreferrer"
            >
              <div className="box-card">
                <img src="/images/media-img/naver-logo.png" alt="media-2" />
              </div>
            </a>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className="card-item">
            <a
              href="https://news.yahoo.com/mining-club-announces-working-extraction-154500085.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="box-card">
                <img src="/images/media-img/yahoo-news.svg" alt="media-3" />
              </div>
            </a>
          </Grid>
          <Grid item xs={6} sm={4} md={3} className="card-item">
            <a
              href="https://money.yahoo.com/mining-club-announces-working-extraction-154500085.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="box-card">
                <img src="/images/media-img/yahoo-money.png" alt="media-3" />
              </div>
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Media;
