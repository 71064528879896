import React, { useEffect, useState } from "react";
import { _getLoginActivity, _getProfile } from "../../../actions/userActions";
import DefaultMan from "../../assets/images/default-man.png";
import { getLanguage } from "../../../languages/getLanguage";
import { get } from "../../../utils/api";
import { ENDPOINT_GET_ADDRESS } from "../../../settings/endpoint";
import {
  Typography,
  FormControl,
  AppBar,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { _getBalance } from "../../../actions/miningActions";
import { makeStyles } from "@material-ui/core/styles";
import ValidateFormatError, {
  validateFormatErrorInput,
} from "../common/ValidateFormatError";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { toast } from "react-toastify";
import { CustomToast } from "../../../settings";
import { NetworkLocked, TramOutlined } from "@material-ui/icons";

function QrCode(props) {
  const { balance } = props;
  const { networks } = balance;

  const [valueTab, setValueTab] = useState(0);
  const [panesTab, setPanesTab] = useState([
    {
      menuItem: "",
      render: null,
    },
  ]);

  const _handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    var panes = [];
    for (const item of networks) {
      var tab = {
        menuItem: item.network,
        render: <TabNetworkList balance={balance} network={item} />,
      };
      panes.push(tab);
    }
    setValueTab(0);
    setPanesTab(panes);
  }, [balance]);

  return (
    <div className="card text-center" style={{ height: 420 }}>
      <AppBar position="static" color="default" className="mb-4">
        <Tabs
          value={valueTab}
          textColor="primary"
          indicatorColor="primary"
          onChange={_handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {panesTab.map((item, index) => (
            <Tab label={item.menuItem} key={index} value={item.index} />
          ))}
        </Tabs>
      </AppBar>
      <div className="card-body">{panesTab[valueTab].render}</div>
    </div>
  );
}

export default QrCode;

function TabNetworkList(props) {
  const { balance, network } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataAddress, setDataAddress] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(false);
    console.log(balance, network);
    if (network.network !== "") {
      get(
        ENDPOINT_GET_ADDRESS + "?network=" + network.network,
        (data) => {
          setDataAddress(data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log(error);
          setError(true);
        }
      );
    }
  }, [balance, network]);

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid item xs={12}>
          <CircularProgress size={40} />
        </Grid>
      ) : network.depositEnable && !error ? (
        <>
          <Grid item xs={12}>
            <p>
              Coin/Token: {balance.asset} - Network: {network.network}
            </p>
          </Grid>
          <Grid item xs={12}>
            <img
              className={classes.qrImage}
              src={
                "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" +
                dataAddress.address
              }
              alt="qrcode"
            />
          </Grid>
          <Grid item xs={12}>
            <CopyToClipboard
              text={dataAddress.address}
              onCopy={() => toast.success("copy")}
            >
              <Typography className="text-nowrap overflow-auto">
                {dataAddress.address}
              </Typography>
            </CopyToClipboard>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6" color="initial">
            {getLanguage("DEPOSIT_DISABLE")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

//#region CSS
const useStyles = makeStyles((theme) => ({
  qrImage: {
    maxWidth: "200px",
    width: "100%",
  },
}));
//#endregion
