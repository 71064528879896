import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LogoImg from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory, useParams } from "react-router";
import { isLoggedIn, isMobileApp } from "../../../utils/auth";
import {
  checkEmail,
  checkEmpty,
  checkPassword,
  checkUsername,
} from "../../../settings/validate";
import { post } from "../../../utils/api";
import {
  FormControl,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import {
  ENDPOINT_POST_REGISTER,
  ENDPOINT_POST_USER_REGISTER_OTP,
} from "../../../settings/endpoint";
import { Visibility, VisibilityOff, Send, Check } from "@material-ui/icons";
import { CustomSwal, CustomToast } from "../../../settings";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Copyright from "../common/Copyright";
import { getLanguage } from "../../../languages/getLanguage";
import { random } from "../../common/RandomBackgroundComponent";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Index() {
  const classes = useStyles();
  const { sponsor } = useParams();
  const query = useQuery();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [sponsorUsername, setSponsorUsername] = useState(
    sponsor ? sponsor : query ? query.get("sponsor") : ""
  );
  const [repeatPassword, setRepeatPassword] = useState("");
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [sendOtp, setSendOtp] = useState(getLanguage("SEND_OTP"));
  const [disabledSendOtp, setDisabledSendOtp] = useState(false);
  const [fieldError, setFieldError] = useState(null);

  useEffect(() => {
    if (isLoggedIn()) {
      if (isMobileApp()) {
        history.push("/mobile-app/dashboard");
      } else {
        history.push("/dashboard");
      }
    }
  }, [history]);

  const _handleRegister = (e) => {
    e.preventDefault();

    //#region validete
    let tempFieldError = [];

    if (checkEmpty(sponsorUsername)) {
      tempFieldError.push({
        field: "sponsorUsername",
        error: getLanguage("PLEASE_ENTER_SPONSOR_ID"),
      });
    }
    if (checkEmpty(username)) {
      tempFieldError.push({
        field: "username",
        error: getLanguage("PLEASE_ENTER_USERNAME"),
      });
    } else {
      if (checkUsername(username)) {
        tempFieldError.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 }),
        });
      }
    }
    if (checkEmpty(email)) {
      tempFieldError.push({
        field: "email",
        error: getLanguage("PLEASE_ENTER_EMAIL"),
      });
    } else {
      if (checkEmail(email)) {
        tempFieldError.push({
          field: "email",
          error: getLanguage("INVALID_EMAIL"),
        });
      }
    }
    if (checkEmpty(emailOtp)) {
      tempFieldError.push({
        field: "emailOtp",
        error: getLanguage("PLEASE_ENTER_OTP"),
      });
    }
    if (checkEmpty(password)) {
      tempFieldError.push({
        field: "password",
        error: getLanguage("PLEASE_ENTER_PASSWORD"),
      });
    } else if (checkPassword(password)) {
      tempFieldError.push({
        field: "password",
        error: getLanguage("INVALID_PASSWORD", { minChar: 8, maxChar: 32 }),
      });
    }
    if (checkEmpty(repeatPassword)) {
      tempFieldError.push({
        field: "repeatPassword",
        error: getLanguage("PLEASE_ENTER_REPEAT_PASSWORD"),
      });
    } else {
      if (password !== repeatPassword) {
        tempFieldError.push({
          field: "repeatPassword",
          error: getLanguage("INVALID_REPEAT_PASSWORD"),
        });
      }
    }
    if (!checked) {
      tempFieldError.push({
        field: "all",
        error: getLanguage("PLEASER_CHECK_TOS"),
      });
    }
    //#endregion
    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setLoading(true);
      setDisabledSendOtp(true);
      setFieldError(null);

      const param = {
        username,
        email,
        password,
        sponsorUsername,
        emailOtp,
      };

      post(
        ENDPOINT_POST_REGISTER,
        param,
        () => {
          if (isMobileApp()) {
            history.push("/mobile-app/dashboard/login");
          } else {
            history.push("/dashboard/login");
          }
        },
        (error) => {
          setLoading(false);
          setDisabledSendOtp(false);
          setFieldError([{ field: "all", error: getLanguage(error.code) }]);
        }
      );
    }
  };

  const _getEmailOtp = (e) => {
    e.preventDefault();

    //#region validate
    let tempFieldError = [];

    if (checkEmpty(username)) {
      tempFieldError.push({
        field: "username",
        error: getLanguage("PLEASE_ENTER_USERNAME"),
      });
    } else {
      if (checkUsername(username)) {
        tempFieldError.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 }),
        });
      }
    }
    if (checkEmpty(email)) {
      tempFieldError.push({
        field: "email",
        error: getLanguage("PLEASE_ENTER_EMAIL"),
      });
    } else {
      if (checkEmail(email)) {
        tempFieldError.push({
          field: "email",
          error: getLanguage("INVALID_EMAIL"),
        });
      }
    }
    //#endregion

    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setLoading(true);
      setDisabledSendOtp(true);
      setFieldError(null);

      const param = {
        email,
        username,
      };

      post(
        ENDPOINT_POST_USER_REGISTER_OTP,
        param,
        () => {
          var i = 20;
          CustomToast("success", "Sent OTP success. Please check your email");
          var timerInterval = setInterval(() => {
            i--;
            if (i === 0) {
              clearInterval(timerInterval);
              setLoading(false);
              setSendOtp(getLanguage("SEND_OTP"));
              setDisabledSendOtp(false);
            } else {
              setLoading(false);
              setSendOtp(getLanguage("COUNTDOWN_TIME", { seconds: i }));
            }
          }, 1000);
        },
        (error) => {
          setLoading(false);
          setSendOtp(getLanguage("SEND_OTP"));
          setDisabledSendOtp(false);
          CustomToast("error", getLanguage(error.code));
          setFieldError([{ field: "all", error: getLanguage(error.code) }]);
        }
      );
    }
  };

  const _checkError = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const _getErrorContent = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return check.error
      } else {
        return null;
      };
    } else {
      return null;
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        style={{ position: "relative" }}
      >
        <div className={classes.paper}>
          <div>
            <Link to={isMobileApp() ? "#" : "/"}>
              <img src={LogoImg} alt="Logo" className={classes.logo} />
            </Link>
          </div>
          <div className="mt-3 mb-5">
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              align="center"
              className="mb-5"
            >
              {getLanguage("REGISTER").toUpperCase()}
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={_handleRegister}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    required
                    size="small"
                    error={_checkError("sponsorUsername")}
                  >
                    <InputLabel>{getLanguage("Sponsor_Username")}</InputLabel>
                    <Input
                      value={sponsorUsername}
                      onChange={(e) => setSponsorUsername(e.target.value)}
                      disabled={sponsor}
                    />
                    {
                      _checkError("sponsorUsername") &&
                      <FormHelperText error>
                        {_getErrorContent("sponsorUsername")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    error={_checkError("username")}
                    required size="small" fullWidth
                  >
                    <InputLabel>{getLanguage("USERNAME")}</InputLabel>
                    <Input
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    {
                      _checkError("username") &&
                      <FormHelperText error>
                        {_getErrorContent("username")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    error={_checkError("email")}
                    required size="small" fullWidth
                  >
                    <InputLabel>{getLanguage("EMAIL_ADDRESS")}</InputLabel>
                    <Input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      endAdornment={
                        <InputAdornment
                          className={clsx("ml-2 btn",
                            disabledSendOtp && "disabledEvent"
                          )}
                          onClick={(e) => _getEmailOtp(e)}
                          disabled={disabledSendOtp}
                        >
                          {
                            loading ?
                              <CircularProgress
                                size={24}
                                className="loading-icon"
                              /> : sendOtp
                          }
                        </InputAdornment>
                      }
                    />
                    {
                      _checkError("email") &&
                      <FormHelperText error>
                        {_getErrorContent("email")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    size="small"
                    fullWidth
                    error={_checkError("emailOtp")}
                  >
                    <InputLabel>{getLanguage("EMAIL_OTP")}</InputLabel>
                    <Input
                      onChange={(e) => setEmailOtp(e.target.value)}
                    />
                    {
                      _checkError("emailOtp") &&
                      <FormHelperText error>
                        {_getErrorContent("emailOtp")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    size="small"
                    fullWidth
                    error={_checkError("password")}
                  >
                    <InputLabel>{getLanguage("PASSWORD")}</InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {
                      _checkError("password") &&
                      <FormHelperText error>
                        {_getErrorContent("password")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    size="small"
                    fullWidth
                    error={_checkError("repeatPassword")}
                  >
                    <InputLabel>{getLanguage("REPEAT_PASSWORD")}</InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {
                      _checkError("repeatPassword") &&
                      <FormHelperText error>
                        {_getErrorContent("repeatPassword")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowPrivacyPolicy" color="primary" />
                    }
                    label={<PrivacyPolicy />}
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    color="primary"
                  />
                </Grid>
                {
                  _checkError("all") &&
                  <Grid item xs={12}>
                    <FormHelperText error>
                      {_getErrorContent("all")}
                    </FormHelperText>
                  </Grid>
                }
                <Grid item xs={12} className="text-center">
                  <Grid container justify="center">
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="mt-3 mb-5"
                        disabled={loading}
                        fullWidth
                      >
                        {loading ? (
                          <CircularProgress color="primary" size={24}>
                            {getLanguage("SIGN_UP")}
                          </CircularProgress>
                        ) : (
                          getLanguage("SIGN_UP")
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <Link className={classes.link}
                  to={isMobileApp() ? "/mobile-app/dashboard/login" : "/dashboard/login"}>
                  {getLanguage("SignIn_Already_have_an_account")}
                </Link>
              </Grid>
            </Grid>
          </div>
          <Box>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
export default Index;

function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <Typography variant="body2">
      {getLanguage("CheckBox_PrivacyPolicy", null, [
        <a className={classes.link + " text-primary"} href="/terms-of-services" target="_blank">
          {getLanguage("Terms_and_Conditions")}
        </a>,
        <a className={classes.link + " text-primary"} href="/privacy-policy" target="_blank">
          {getLanguage("Privacy_Policy")}
        </a>])}
    </Typography>
  );
}

//#region CSS
const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
  },
  image: {
    backgroundImage: `url(${random()})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    padding: "50px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    height: "100vh",
    justifyContent: "space-between",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo: {
    maxWidth: 140,
    marginBottom: "1em",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 180,
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
//#endregion
