import React, { useEffect } from "react";
import { FormHelperText } from "@material-ui/core";
import { toast } from "react-toastify";
import { getLanguage } from "../../../languages/getLanguage";

function ValidateFormatError(props) {
  const { error, isToast } = props;

  useEffect(() => {
    if (isToast) {
      toast.error(
        <ContentHtml
          error={error}
          color={"#fff"}
          title={getLanguage("ERROR")}
        />
      );
    }
  }, [error]);

  return <ContentHtml error={error} />;
}

export default ValidateFormatError;

const ContentHtml = (props) => {
  const { error, color, title } = props;

  return (
    <>
      {title && <h3 style={{ color: color }}>{title}</h3>}
      {error
        ? error.map((item, index) => (
            <FormHelperText key={index} style={{ color: color }}>
              - {item}
            </FormHelperText>
          ))
        : null}
    </>
  );
};

export const validateFormatErrorInput = (form, element = "reset") => {
  if (element === "reset") {
    if (form.target) {
      var allInput = form.target.querySelectorAll("input");
      if (allInput !== null) {
        allInput.forEach((element) => {
          element.style.backgroundColor = "";
        });
      }
    } else {
      var allInput = form.querySelectorAll("input");
      if (allInput !== null) {
        allInput.forEach((element) => {
          element.style.backgroundColor = "";
        });
      }
    }
  } else {
    // if (form.target) {
    //   var el = form.target.querySelector("input[name='" + element + "']");
    //   if (el !== null) {
    //     form.target.querySelector(
    //       "input[name='" + element + "']"
    //     ).style.backgroundColor = "rgb(255 0 0 / 20%)";
    //   }
    // } else {
    //   var el = form.querySelector("input[name='" + element + "']");
    //   if (el !== null) {
    //     form.querySelector(
    //       "input[name='" + element + "']"
    //     ).style.backgroundColor = "rgb(255 0 0 / 20%)";
    //   }
    // }
  }
};
