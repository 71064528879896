import React from "react";
import {
  Container,
  Menu,
  Grid,
  Button,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { isLoggedIn, isMobileApp } from "../../../utils/auth";
import { getLanguage } from "../../../languages/getLanguage";
import { createRef } from "react";
import i18next from "../../../languages/getLanguage";
import LanguageIcon from "@material-ui/icons/Language";
import { getCountryFlags } from "../../../settings";

const Navbar = () => {
  const ref = createRef();
  const menus = [
    {
      title: getLanguage("HOME"),
      url: `/`,
    },
    {
      title: getLanguage("ABOUT_US"),
      url: `/#why_mining_club`,
    },
    {
      title: getLanguage("WHAT_IS_MINING"),
      url: getLanguage("menu_header_link_1"),
      outside: true,
    },
    {
      title: getLanguage("WHAT_IS_FIL_COIN"),
      url: getLanguage("menu_header_link_2"),
      outside: true,
    },
    {
      title: getLanguage("HOW_TO"),
      url: `/#tutorial`,
    },
  ];

  //#region
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleLanguageMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (lang) => {
    setAnchorEl(null);
    if (
      lang === "en" ||
      lang === "vi" ||
      lang === "kr" ||
      lang === "de" ||
      lang === "es" ||
      lang === "ru"
    ) {
      i18next.changeLanguage(lang);
    }
  };

  const renderMenuLanguage = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem onClick={() => handleLanguageMenuClose("en")}><img className="mr-2" src={getCountryFlags("gb", 24)} />English</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("vi")}><img className="mr-2" src={getCountryFlags("vn", 24)} />Vietnamese</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("kr")}><img className="mr-2" src={getCountryFlags("kr", 24)} />Korean</MenuItem>
      {/* <MenuItem onClick={() => handleLanguageMenuClose("de")}><img className="mr-2" src={getCountryFlags("de", 24)} />German</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("es")}><img className="mr-2" src={getCountryFlags("es", 24)} />Spanish</MenuItem>
      <MenuItem onClick={() => handleLanguageMenuClose("ru")}><img className="mr-2" src={getCountryFlags("ru", 24)} />Russian</MenuItem> */}
    </Menu>
  );
  //#endregion

  return (
    <div ref={ref} className="navbar hide-md">
      <Container>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Link to="/">
              <img src={`/images/logo.png`} alt="logo-web" className="logo" />
            </Link>
          </Grid>
          <Grid item>
            {menus &&
              menus.map((item, index) =>
                item.outside ? (
                  <a
                    key={index}
                    className="nav-item"
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                ) : (
                  <HashLink
                    className="nav-item"
                    smooth
                    key={index}
                    to={item.url}
                  >
                    {item.title}
                  </HashLink>
                )
              )}
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {isLoggedIn() ? (
                <Grid item>
                  <Link to={isMobileApp() ? "/mobile-app/dashboard" : "/dashboard"}>
                    <Button variant="contained" color="primary">
                      {getLanguage("GO_TO_DASHBOARD")}
                    </Button>
                  </Link>
                </Grid>
              ) : (
                <>
                  <Grid item className="mr-3">
                    <Link to={isMobileApp() ? "/mobile-app/dashboard/sign-up" : "/dashboard/sign-up"}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "transparent",
                          backgroundImage:
                            "linear-gradient(to right, #0076BA, #16E7CF)",
                          color: "#fff",
                        }}
                      >
                        {getLanguage("SIGN_UP")}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={isMobileApp() ? "/mobile-app/dashboard/login" : "/dashboard/login"}>
                      <Button
                        variant="contained"
                        className="text-primary btn-login"
                      >
                        {getLanguage("LOGIN")}
                      </Button>
                    </Link>
                  </Grid>
                </>
              )}
              <Grid item>
                <IconButton onClick={handleLanguageMenuOpen}>
                  <LanguageIcon color="primary" className="ml-2" />
                </IconButton>
                {renderMenuLanguage}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Navbar;
