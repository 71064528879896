import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";

export default function Countdown() {
  const [time, setTime] = useState("");

  const _countdown = () => {
    let m = moment().utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m = m.add(1, "d");
    const diffTime = m - moment();
    const duration = moment.duration(diffTime);
    const hours =
      duration.hours() > 9 ? duration.hours() : "0" + duration.hours();
    const minutes =
      duration.minutes() > 9 ? duration.minutes() : "0" + duration.minutes();
    const seconds =
      duration.seconds() > 9 ? duration.seconds() : "0" + duration.seconds();
    setTime(hours + "h " + minutes + "m " + seconds + "s");
  };

  useEffect(() => {
    _countdown();
    const interval = setInterval(() => {
      _countdown();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <span
      className="count-down"
    >
      {time}
    </span>
  );
}
