import { FETCH_TRANSFER_HISTORY, FETCH_WITHDRAW_HISTORY } from "../constants";

const initialState = {
  depositHistory: null,
  withdrawHistory: null,
  transferHistory: null,
};
export const WalletReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WITHDRAW_HISTORY:
      return { ...state, withdrawHistory: payload };
    case FETCH_TRANSFER_HISTORY:
      return { ...state, transferHistory: payload };
    default:
      return { ...state };
  }
};
