import React, { useEffect, useState } from "react";
import { _getLoginActivity, _getProfile } from "../../../actions/userActions";
import { getLanguage } from "../../../languages/getLanguage";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    CircularProgress
} from "@material-ui/core";
import NoData from '../../common/no-data/Index';
import { ENDPOINT_GET_WITHDRAW } from "../../../settings/endpoint";
import { post } from "../../../utils/api";
import ValidateFormatError, { validateFormatErrorInput } from "../common/ValidateFormatError";

function History(props) {
    const { balance } = props;

    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        var textError = [];
        setError(null);
        setLoading(true);

        const param = {
            "page": page,
            "pageSize": pageSize,
            "search": "",
            "orderBy": "",
            "responseMeta": true,
            "filters": {
                asset: balance.asset
            }
        }
        post(ENDPOINT_GET_WITHDRAW, param,
            (data) => {
                setData(data);
                setLoading(false);
            },
            (error) => {
                textError = [getLanguage(error.code)];
                setError(<ValidateFormatError error={textError} />);
            }
        );
    }, [page, pageSize, balance]);

    return (
        <div className="card mb-4">
            <div className="card-header">
                <b>{getLanguage("History")}</b>
            </div>
            {
                loading ?
                    <div item xs={12} className="card-body text-center">
                        <CircularProgress size={100} />
                    </div> :
                    <TableContainer component={Paper} className="table mb-0">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Time</b></TableCell>
                                    <TableCell><b>Address</b></TableCell>
                                    <TableCell><b>Hash</b></TableCell>
                                    <TableCell><b>Amount</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.length > 0 ?
                                        data.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.calories}</TableCell>
                                                <TableCell>{item.fat}</TableCell>
                                                <TableCell>{item.carbs}</TableCell>
                                                <TableCell>{item.protein}</TableCell>
                                            </TableRow>
                                        )) :
                                        <TableRow>
                                            <TableCell colSpan={4} >
                                                <NoData height={"200px"} />
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                        {data.itemCount > pageSize && (
                            <TablePagination
                                component="div"
                                count={data.itemCount}
                                page={page - 1}
                                rowsPerPage={pageSize}
                                onChangePage={(e) => setPage(e + 1)}
                                rowsPerPageOptions={[]}
                            />
                        )}
                    </TableContainer>
            }
        </div>
    );
}

export default History;
