import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LogoImg from "../../assets/images/logo.png";
import { put } from "../../../utils/api";
import {
  ENDPOINT_PUT_FORGOT_PASSWORD,
  ENDPOINT_PUT_RESET_PASSWORD,
} from "../../../settings/endpoint";
import {
  FormControl,
  CircularProgress,
  InputAdornment,
  IconButton,
  InputLabel,
  Typography,
  Input,
  Button,
  CssBaseline,
  Paper,
  Box,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import Copyright from "../common/Copyright";
import {
  checkEmpty,
  checkUsername,
  checkPassword,
} from "../../../settings/validate";
import { getLanguage } from "../../../languages/getLanguage";
import queryString from "query-string";
import { CustomSwal, CustomToast } from "../../../settings";
import { useHistory } from "react-router";
import { isLoggedIn, isMobileApp } from "../../../utils/auth";
import { random } from "../../common/RandomBackgroundComponent";

function Index() {
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabledSendOtp, setDisabledSendOtp] = useState(false);
  const [sendOtp, setSendOtp] = useState(getLanguage("SEND_OTP"));
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [fieldError, setFieldError] = useState(null);

  useEffect(() => {
    if (isLoggedIn()) {
      if (isMobileApp()) {
        history.push("/mobile-app/dashboard");
      } else {
        history.push("/dashboard");
      }
    }
  }, [history]);

  const _handleForgotPassword = (e) => {
    e.preventDefault();

    //#region validete
    let tempFieldError = [];

    if (checkEmpty(username)) {
      tempFieldError.push({
        field: "username",
        error: getLanguage("PLEASE_ENTER_USERNAME"),
      });
    } else {
      if (checkUsername(username)) {
        tempFieldError.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 })
        });
      }
    }
    if (checkEmpty(emailOtp)) {
      tempFieldError.push({
        field: "emailOtp",
        error: getLanguage("PLEASE_ENTER_OTP"),
      });
    }
    if (checkEmpty(password)) {
      tempFieldError.push({
        field: "password",
        error: getLanguage("PLEASE_ENTER_PASSWORD"),
      });
    } else if (checkPassword(password)) {
      tempFieldError.push({
        field: "password",
        error: getLanguage("INVALID_PASSWORD", { minChar: 8, maxChar: 32 }),
      });
    }
    if (checkEmpty(repeatPassword)) {
      tempFieldError.push({
        field: "repeatPassword",
        error: getLanguage("PLEASE_ENTER_REPEAT_PASSWORD"),
      });
    } else {
      if (password !== repeatPassword) {
        tempFieldError.push({
          field: "repeatPassword",
          error: getLanguage("INVALID_REPEAT_PASSWORD"),
        });
      }
    }
    //#endregion

    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setFieldError(null);
      setLoading(true);

      const param = {
        username,
        password,
        emailOtp,
      };

      put(
        ENDPOINT_PUT_RESET_PASSWORD,
        param,
        () => {
          CustomSwal("success").then(() => {
            if (isMobileApp()) {
              history.push("/mobile-app/dashboard/login");
            } else {
              history.push("/dashboard/login");
            }
          });
        },
        (error) => {
          setLoading(false);
          setDisabledSendOtp(false);
          setFieldError([{ field: "all", error: getLanguage(error.code) }]);
        }
      );
    }
  };

  const _getEmailOtp = (e) => {
    e.preventDefault();

    //#region validete
    let tempFieldError = [];

    if (checkEmpty(username)) {
      tempFieldError.push({
        field: "username",
        error: getLanguage("PLEASE_ENTER_USERNAME"),
      });
    } else {
      if (checkUsername(username)) {
        tempFieldError.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 })
        });
      }
    }
    //#endregion

    if (tempFieldError.length > 0) {
      setFieldError(tempFieldError);
    } else {
      setFieldError(null);
      setLoading(true);
      setDisabledSendOtp(true);

      const param = {
        username,
      };
      var query = queryString.stringify(param);
      put(
        ENDPOINT_PUT_FORGOT_PASSWORD + "?" + query,
        null,
        () => {
          var i = 20;
          CustomToast("success", "Sent OTP success. Please check your email.");
          var timerInterval = setInterval(() => {
            i--;
            if (i === 0) {
              clearInterval(timerInterval);
              setLoading(false);
              setSendOtp(getLanguage("SEND_OTP"));
              setDisabledSendOtp(false);
            } else {
              setLoading(false);
              setSendOtp(getLanguage("COUNTDOWN_TIME", { seconds: i }));
            }
          }, 1000);
        },
        (error) => {
          setLoading(false);
          setSendOtp(getLanguage("SEND_OTP"));
          setDisabledSendOtp(false);
          CustomToast("error", getLanguage(error.code));
          setFieldError([{ field: "all", error: getLanguage(error.code) }]);
        }
      );
    }
  };

  const _checkError = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const _getErrorContent = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return check.error
      } else {
        return null;
      };
    } else {
      return null;
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div>
            <Link to={isMobileApp() ? "#" : "/"}>
              <img src={LogoImg} alt="Logo" className={classes.logo} />
            </Link>
          </div>
          <div>
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              align="center"
              className="mb-5"
            >
              {getLanguage("RESET_PASSWORD").toUpperCase()}
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={_handleForgotPassword}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    required
                    fullWidth
                    className={clsx(classes.margin, classes.textField)}
                    error={_checkError("username")}
                  >
                    <InputLabel>{getLanguage("USERNAME")}</InputLabel>
                    <Input
                      onChange={(e) => setUsername(e.target.value)}
                      endAdornment={
                        <InputAdornment
                          className={clsx("btn ml-2",
                            disabledSendOtp && "disabledEvent"
                          )}
                          onClick={(e) => _getEmailOtp(e)}
                          disabled={disabledSendOtp}
                          color="primary"
                        >
                          {loading ? (
                            <CircularProgress
                              size={24}
                              className="loading-icon"
                            />
                          ) : (
                            sendOtp
                          )}
                        </InputAdornment>
                      }
                    />
                    {
                      _checkError("username") &&
                      <FormHelperText error>
                        {_getErrorContent("username")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    fullWidth
                    className={clsx(classes.margin, classes.textField)}
                    error={_checkError("emailOtp")}
                  >
                    <InputLabel>{getLanguage("EMAIL_OTP")}</InputLabel>
                    <Input
                      onChange={(e) => setEmailOtp(e.target.value)}
                    />
                    {
                      _checkError("emailOtp") &&
                      <FormHelperText error>
                        {_getErrorContent("emailOtp")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    fullWidth
                    className={clsx(classes.margin, classes.textField)}
                    error={_checkError("password")}
                  >
                    <InputLabel>{getLanguage("PASSWORD")}</InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {
                      _checkError("password") &&
                      <FormHelperText error>
                        {_getErrorContent("password")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    fullWidth
                    className={clsx(classes.margin, classes.textField)}
                    error={_checkError("repeatPassword")}
                  >
                    <InputLabel>{getLanguage("REPEAT_PASSWORD")}</InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {
                      _checkError("repeatPassword") &&
                      <FormHelperText error>
                        {_getErrorContent("repeatPassword")}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
                {
                  _checkError("all") &&
                  <Grid item xs={12}>
                    <FormHelperText error>
                      {_getErrorContent("all")}
                    </FormHelperText>
                  </Grid>
                }
                <Grid item xs={12} align="center">
                  <Grid container justify="center">
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="mt-3 mb-5"
                        disabled={loading}
                        fullWidth
                      >
                        {loading ? (
                          <CircularProgress color="primary" size={24} />
                        ) : (
                          getLanguage("RESET_PASSWORD")
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid container justify="center">
              <Grid item>
                <Link className={classes.link}
                  to={isMobileApp() ? "/mobile-app/dashboard/login" : "/dashboard/login"}>
                  {getLanguage("SignIn_Already_have_an_account")}
                </Link>
              </Grid>
            </Grid>
          </div>

          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
export default Index;

//#region CSS
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${random()})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    padding: "50px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100vh",
    overflow: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    maxWidth: 140,
    marginBottom: "1em",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 180,
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
//#endregion
