export const kr = {
  ID: "ID",
  DASHBOARD: "대쉬보드",
  ANNOUNCEMENTS: "공고",
  SUPPORT: "지원",
  ENGLISH: "영어",
  VIETNAMESE: "베트남어",
  KOREAN: "Korean",
  HOME: "홈",
  ABOUT_US: "회사 소개",
  WHY_MINING: "왜 마이닝인가 ?",
  WHAT_IS_FIL_COIN: "파일코인이란?",
  HOW_TO: "방법?",
  KNOWLEDGE: "지식",
  SIGN_UP: "무료 계정에 가입",
  LOGIN: "로그인",
  LOGOUT: "로그 아웃",
  USERNAME: "사용자 이름",
  PASSWORD: "비밀번호",
  SIGN_IN: "로그인",
  FORGOT_PASSWORD: "비밀번호 분실",
  SIGN_UP_1: "계정이 없으신가요? 등록",
  MINING_CLUB: "Mining Club",
  MEDIA: "미디어",
  PARTNER: "파트너",
  ACHIEVEMENT: "성취",
  PLANS: "채굴 계획",
  BUY_PLANS: "마이닝 계획 구매",
  MY_PLANS: "나의 마이닝계획",
  TRANSACTIONS: "보고",
  ACCOUNT: "계정",
  BEST_WAY_TO_MINING: `<span class='text-nowrap'>Filecoin [[0]]</span> 을 채굴하는 가장 좋은 방법`,
  MEMBERS: "회원 목록",
  BINARY_TREE: "회원 계보도",
  MINING_HISTORY: "채굴 내역",
  MATCHING_COMMISSION: "커뮤니티 보너스",
  DIRECT_COMMISSION: "에이전시 보너스",
  BINARY_COMMISSION: "매출 보너스",
  WALLET: "지갑",
  DEPOSIT: "재충전",
  TRANSFER: "옮기다",
  WITHDRAWAL: "출금",
  AVAILABLE: "사용 가능",
  SUPPORT_CENTER: "지원 센터",
  FAQ: "자주 하는 질문 ",
  WHAT_IS_MINING: "채굴이란?",
  NEXT_PAYOUT: "다음 지불",
  INVITED_FRIEND: "초대, 추천보상",
  Referral_Link: "추천 코드 링크",
  PROMOTION: "프로모션",
  BALANCE: "균형",
  BALANCE_COIN: "{{coin}} 잔액",
  TRANSFER_WITHDRAW: "암호화폐 이체/출금",
  RANK: "등급",
  RANK_INFO: "등급 정보",
  MY_PACKAGE: "마이 패키지",
  PAYOUT_FROM_REFERRAL_PLAN: "채굴 보상 지급",
  MINING_BALANCE: "채굴 잔고",
  MINING_BALANCE_COIN: "{{coin}} 채굴 잔고",
  CONFIRMING_FOR_BUY_PLAN: "마이닝 팩 구매 확인",
  RELEASED: "발행",
  Enroll_new_member: "신규 회원 등록",
  PERSONAL_INFORMATION: `개인 정보`,
  PERSONAL_DOCUMENTS: `개인 문서`,
  LEFT: "왼쪽",
  RIGHT: "오른쪽",
  PACKAGE: "마이닝 팩",
  TOTAL_MINED: "총 채굴",
  START_TIME: "시작 시간",
  END_TIME: "종료 시간",
  FREE: "무료",
  CREATE_MEMBER: "회원 가입",
  SECURITY: "보안",
  PROFILE: "파일",
  SPONSOR: "애용하다",
  YOUR_SPONSOR: "스폰서 ",
  IDENTITY_VERIFICATION: "본인 확인",
  GA: "구글 인증",
  LOGIN_PASSWORD: "로그인 비밀번호",
  VERIFY: "확인",
  TURN_ON: "켜다",
  CHANGE: "변경",
  AMOUNT: "금액",
  BLOCK: "블록",
  CREATED_TIME: "생성 시간",
  MINE_TIME: "채굴 시간",
  UNBLOCK_TIME: "잠금 해제 시간",
  TIME: "시간",
  LAST_LOGIN: "마지막 로그인",
  NETWORK: "네트워크",
  HISTORY: "내역",
  TRANSACTION_FEE: "거래 수수료",
  FEE: "회비",
  SEND: "보내다",
  RECEIVE: "받다",
  YOU_WILL_GET: "얻다",
  CONTINUE: "계속",
  ASSET: "자산",
  CONFIRM: "확인",
  FIRST_NAME: "이름",
  LAST_NAME: "성",
  MIDDLE_NAME: "중간 이름",
  DOB: "생일",
  COUNTRY: "국가",
  SELECT_COUNTRY: "국가 선택",
  CITY: "도시",
  POSTAL_CODE: "우편 번호",
  START_DATE: "시작일",
  END_DATE: "종료일",
  PRICE: "가격",
  PAYMENT: "지불",
  SignIn_Already_have_an_account: "이미 계정이 있습니까? 로그인",
  CheckBox_PrivacyPolicy: "서비스 [[0]] 및 [[1]] 동의합니다",
  Terms_and_Conditions: "이용약관",
  Privacy_Policy: "개인정보처리방침에",
  PURCHASE_NOW: "지금 구입",
  LAST_MINED: "마지막 채굴",
  MINIMUM_WITHDRAW_AMOUNT: "최소 출금 금액",
  PLEASER_CHECK_TOS: "MiningClub 이용약관을 읽고 동의해주세요.",
  REGISTER: "등록하다",
  Sponsor_Username: "후원자 사용자 이름",
  EMAIL_ADDRESS: "이메일 주소",
  SELECT_ASSET: "자산 선택",

  Lasts_for_days: "{{number}}일 동안 지속",
  Start_after_days: "{{number}}일 후 시작",
  Days_of_mining: "{{number}}일 채굴",
  No_profit_from_mining: "채굴로 인한 이익 없음",
  Reservation: "좌석 예약",


  NO_PACKAGE: "아니다",
  ADDRESS: "주소",
  ID_CODE: "신분증 번호 / 여권번호",
  ID_TYPE: "문서 유형 선택",


  UPLOAD_PHOTO: "사진 업로드",
  Front_ID_card: "앞면",
  Back_ID_card: "뒷면",
  Address_verify: "주소 확인",
  PASSPORT: "여권",
  ID_CARD: "신분증",
  CREATE_MEMBER_SUCCESS: "가입 성공",
  SWAP: "교환",
  SWAP_HISTORY: "스왑 기록",
  PLEASER_ENTER_BASE_AMOUNT: "스왑 금액을 입력하세요",


  PASSWORD_WRONG: "비밀번호가 잘못되었습니다",
  PLEASE_ENTER_SPONSOR_ID: "후원자 아이디를 입력해주세요",
  PLEASE_ENTER_EMAIL: "당신의 이메일 주소를 입력하십시오",
  PLEASE_ENTER_USERNAME: "사용자 이름을 입력하세요",
  PLEASE_ENTER_LAST_NAME: `당신의 성을 입력하세요`,
  PLEASE_ENTER_FIRST_NAME: `당신의 이름을 입력 해주세요`,
  PLEASE_ENTER_PASSWORD: "비밀번호를 입력하세요",
  PLEASE_ENTER_REPEAT_PASSWORD: "비밀번호를 다시 입력하세요",
  INVALID_REPEAT_PASSWORD: "반복되는 비밀번호가 잘못되었습니다",
  PLEASE_ENTER_REFERRAL: "추천 코드를 입력하세요",
  CREATE_NEW_ACCOUNT: "새 계정 등록",
  HAVE_A_ACCOUNT: "나는 이미 계정을 갖고있다",
  INVALID_EMAIL: "잘못된 이메일",
  INVALID_PASSWORD: "잘못된 비밀번호. 비밀번호는 {{minChar}}~{{maxChar}}자 사이여야 하며 대문자와 소문자, 숫자, 특수 문자를 포함하고 공백이 없어야 합니다",
  INVALID_USERNAME: "사용자 이름은 {{minChar}}자 이하, {{maxChar}}자 이하여야 합니다",

  RESET_PASSWORD: "암호를 재설정",
  SEND_OTP: "OTP 보내기",
  PLEASE_ENTER_OLD_PASSWORD: "이전 비밀번호를 입력하세요.",
  PLEASE_ENTER_NEW_PASSWORD: "새 비밀번호를 입력하세요",
  PLEASE_ENTER_CONFIRM_PASSWORD: "비밀번호를 확인해주세요",
  CONFIRM_NEW_PASSWORD: "비밀번호 확인",
  UPDATE_PASSWORD: "비밀번호 업데이트",
  EMAIL_OTP: "이메일 OTP",
  PLEASE_ENTER_OTP: "이메일의 OTP를 입력하세요.",
  REPEAT_PASSWORD: "비밀번호 반복",
  PLEASE_ENTER_DOB: `생년월일을 입력하세요`,
  PLEASE_CHOOSE_DOCUMENT_TYPE: "신분증 종류를 선택해주세요",
  PLEASE_ENTER_DOCUMENT_NUMBER: `아이디 번호를 입력해주세요`,
  PLEASE_ENTER_YOUR_ADDRESS: `주소를 입력하세요`,
  PLEASE_ENTER_COUNTRY: `도시를 선택하십시오`,
  PLEASE_ENTER_CITY: `도시를 입력하세요`,
  PLEASE_ENTER_POSTAL_CODE: `우편번호를 입력하세요.`,
  GA_CODE: "구글 인증 코드",
  PLEASE_ENTER_GA_CODE: "Google 인증 코드를 입력하세요.",
  CANCEL: "취소",
  TURN_OFF: "끄다",
  SUCCESS: "성공",
  Search_username: "사용자 이름 검색",
  ACCOUNT_EXIST: "계정 존재",
  HI_USERNAME: `안녕 {{username}},`,
  WARNING_WITHDRAW: `인출하기 전에 계정을 확인해야 합니다.`,
  CLOSE: "닫기",
  VERIFY_ACCOUNT: "계정 확인",
  BLOCKED: "차단",
  Registered_successfully: "가입 성공",
  ACCOUNT_EXPIRED: "계정 만료됨",
  POWER: "힘",
  PlansMining: "Plans mining",
  MINIMUM: "최저한의",
  SWAP_CPU_BELOW_MINIMUM: "최소 {{num}} CPU 미만으로 스왑",
  BASE_AMOUNT_GREATER_MIN: "최소 금액보다 큰 금액을 입력하세요.",
  SWAP_CONFIRM: "스왑 확인",
  INSUFFICIENT_FUNDS: "사용할 수 없는 잔액",
  UNAVAILABLE_BALANCE: "사용할 수 없는 잔액",











  BEST_WAY_TO_MINING_FILECOIN: "FILECOIN을 채굴하는 가장 좋은 방법",
  home_banner_text: "Mining Club는 차세대 인터넷 프로토콜 인 Filecoin을 채굴하고 있습니다. Mining Club 는 매우 효율적인 암호 화폐 채굴 환경을 구축하고 간소화 된 시스템을 제공합니다.",
  introHome_title_1: "하드웨어가  이미 실행 중입니다",
  introHome_title_2: "대체 암호화폐 채굴",
  introHome_title_3: "오늘 첫번째 채굴 결과를 얻으십시요.",
  introHome_text_1: "장비준비와 여러가지 문제로 채굴을 위한 난관에 봉착하지 마십시요. MiningClub은 이미 가장 빠르고 편한 Filecoin 채굴 하드웨어들 제공 합니다.",
  introHome_text_2: "목록에 명시한 사용 가능한 모든 암화화페를  채굴할 수 있습니다.MiningClub 플랫폼을 통하여 모든 코인을 채굴할 수 있습니다.",
  introHome_text_3: "지정한 지갑에서 정기적으로 채굴현황을 확인할수 있습니다. 지금 클라우드 마이닝 플랫폼을 경험해 보세요.",
  why_mining_club: "MINING CLUB이 필요한 이유?",
  why_mining_title: "모든 사용자들이 채굴에 쉽고 편하게 접근할 수 있습니다.",
  why_mining_text: "우리는 효율적인 암호화폐 채굴 작업을 실행하기 위한 고효율 데이터 센터 구축부터 사용자를 위한 간소화된 마이닝 시스템 제공까지 모든 주요 측면을 통합하고 있습니다.",
  why_mining_title_card_1: "우리는 채굴을 경쟁력 있게 만듭니다.",
  why_mining_title_card_2: "상호간 지원하는 활기찬 채굴 커뮤니티",
  why_mining_title_card_3: "환경보호와 데이터 보호",
  why_mining_text_card_1: "효과적으로 채굴하려면 가정 채굴 설정에 상당한 자원, 시간 및 노력을 투자해야 합니다. 당사의 Mining Club 플랫폼은 귀하를 위해 가장 효율적인 마이닝 시스템과 연결되어 있습니다. 시장 모니터링에 완전히 집중하고 채굴 보상으로 경쟁력을 유지할 수 있습니다.",
  why_mining_text_card_2: "소규모 채굴 장비에서 최첨단 데이터 센터 운영에 이르기까지 Filecoin은 사업자와 기업으로 구성된 활기찬 채굴 커뮤니티를 통해 서로가 운영을 보다 효율적이고 안정적이며 신뢰할 수 있도록 돕고 있습니다.",
  why_mining_text_card_3: "Filecoin은 작업 증명을 낭비하는 다른 많은 블록체인과 달리 네트워크를 보호하기 위해 유용한 저장소를 활용합니다.  Filecoin을 최초의 친환경 블록체인 중 하나로 만듭니다.",
  features_title: "마이닝 클럽 기능",
  features_card_title_1: "클라우드 마이닝",
  features_card_title_2: "파일코인 채굴",
  features_card_title_3: "개인정보 보호및 보안",
  features_card_title_4: "경쟁력 있는 채굴 기술",
  features_card_title_5: "직관적인 관리 인터페이스",
  features_card_title_6: "일일 채굴 분배",
  features_card_text_1: "고객과의  공유로 마이닝 계약을 등록하고 구매하는 원격 데이터 센터를 사용하여 암호화폐를 마이닝하는 프로세스입니다. 클라우드 마이닝을 통해 사용자는 하드웨어를 관리하지 않고도 암호화폐를 마이닝할 수 있습니다.",
  features_card_text_2: "Filecoin 네트워크는 누구나 스토리지 제공자로 가입하고 오픈 하드 드라이브 공간에서 수익을 창출할 수 있도록 함으로써 놀라운 규모의 경제를 달성합니다.",
  features_card_text_3: "Mining Club은 전 세계적으로 경쟁력 있는 속도와 최대 효율성으로 암호화폐를 채굴하여 가장 경쟁력 있는 채굴 보상을 받을수 있도록 보장합니다!",
  features_card_text_4: "암호화폐 채굴을 위해 특별히 설계된 고효율 채굴 장비 사용",
  features_card_text_5: "필요한 모든 암호화폐 마이닝 데이터 및 차트, 마이닝 포트폴리오를 매일 쉽게 모니터링할수 있습니다.",
  features_card_text_6: "매일 자동으로 마이닝 결과를 계정에서 확인할수 있고 언제든지 인출가능 합니다.",
  tutorial_title: "간단한 4단계로 Mining Club 커뮤니티에 가입하는 방법",
  tutorial_card_title_1: "계정 생성",
  tutorial_card_title_2: "채굴 계획 구매",
  tutorial_card_title_3: "암호화폐를 채굴해 드립니다.",
  tutorial_card_title_4: "암호화폐 획득 하고 미래를 건설하십시요.",
  tutorial_card_text_1: "추천인의 이메일 주소와 사용자 이름을 제공하여 가입한 다음 사용자 이름과 연락처 정보를 입력하십시오!",
  tutorial_card_text_2: "회비나 비용 없이 가장 적합한 마이닝 플랜을 선택하세요! 당신은 무엇을 기다리고 있습니까? 암호화폐 세계에 합류할수 있도록 도와드리겠습니다!",
  tutorial_card_text_3: "Mining Club은 전 세계적으로 경쟁력 있는 비율과 최대 효율성으로 암호화폐를 채굴하여 가장 경쟁력 있는 채굴 보상을 받을 수 있도록 보장합니다!",
  tutorial_card_text_4: "Mining Club은 정기적으로 채굴 보상과 추천 보상을 배포합니다. 마이닝 계획에 따라 마이닝 잠재력을 확인하십시오!",

  title_footer_1: "팔로우",
  title_footer_2: "약관 및 정책",
  title_footer_3: "지식",

  item_footer_1: "Mining Club 활동에 대한 최신 정보를 얻으십시오.",
  item_footer_2: "서비스 약관",
  item_footer_3: "개인 정보 정책",
  item_footer_4: "채굴이란?",
  item_footer_5: "어떻게 작동합니까?",
  text_footer: "이 웹사이트의 정보는 어떤 유형의 제안도 전달하지 않으며 증권,상품, 또는 기타 금융 상품의 판매 제안또는 구매 제안 권유로 의도되지 않으며 그렇게 해석되어서도 안됩니다. 또한 웹사이트의 정보는 투자 조언의 제공을 구성하지 않습니다. 여기에 명시되거나 임시된 모든 목표,가정,기대,전략, 및 또는 목표가 실현 되었거나 실현될 것이라는 보증은 할수 없습니다. 나열된 모든 성능은 웹사이트에 설명된 동일한 방식으로 수행되었거나 진행될 것입니다.",

  blog_title: "Blog filecoin",
  blog_read_more_button: "더 읽기",

  partner_title: "우리는 기술을 통해 더 나은 미래를 건설하는 사람들의 조직 및 커뮤니티와 협력합니다.",

  menu_header_link_1: "https://knowledge.miningclub.org/what-is-mining/what-is-mining",
  menu_header_link_2: "https://knowledge.miningclub.org/what-is-filecoin/what-is-filecoin",
  menu_footer_link_1: "https://knowledge.miningclub.org/what-is-mining/what-is-mining",
  menu_footer_link_2: "https://knowledge.miningclub.org/what-is-filecoin/what-is-filecoin",


  db_left_menu_link_1: "https://support.miningclub.org",
  db_left_menu_link_2: "https://faq.miningclub.org",
  db_left_menu_link_3: "https://announcements.miningclub.org",
  db_left_menu_link_4: "https://knowledge.miningclub.org",
  db_left_menu_link_5: "https://filecoin-news.miningclub.org",


  Filecoin_News: "파일 코인 뉴스",



  db_slide_banner_1: "https://support.miningclub.org/buy-plans/member-reservation-150usdt",
  db_slide_banner_2: "/",
  db_slide_banner_3: "https://announcements.miningclub.org/new-activities/welcome-launch-promotion",
  db_slide_banner_4: "https://announcements.miningclub.org/new-activities/welcome-launch-promotion",


  verify_tab_description_1: "개인정보 입력",
  verify_tab_description_2: "ID/여권/운전 면허증",
  verify_tab_description_3: "인물 사진 찍기",
  verify_tab_description_4: "정보 확인",
  verify_tab_menuItem_1: "정보",
  verify_tab_menuItem_2: "전면 사진",
  verify_tab_menuItem_3: "뒷면 사진",
  verify_tab_menuItem_4: "초상화",

  KYC_NOTIFICATION: "이 계정의 신원 인증이 보류 중이거나 확인 중입니다",



  Dark_Mode: "다크 모드",
  Light_Mode: "조명 모드",
  Purple_Mode: "보라색 모드",
  Orange_Mode: "주황색 모드",

  YOU_NEED_ENABLE_GA: "Google 인증기를 활성화해야 합니다.",
  JUST_TRANSFER_100_DOLLAR_WHEN_NO_VERIFICATION: "인증 없이 $100만 송금",
  USE_TRANSFER_SERVICE: "환승 서비스 이용",
  USE_WITHDRAW_SERVER_YOU_NEED: "필요한 출금 서버 사용",
  ENABLE_GOOGLE_AUTHENTICATION: "구글 인증 활성화",
  WITHDRAW_DISABLE: "출금이 비활성화되었습니다. 나중에 다시 시도하십시오.",
  PLEASE_ENTER_ADDRESS: "주소를 입력하세요",
  PLEASE_ENTER_AMOUNT: "금액을 입력하세요",
  YOUR_AMOUNT_TOO_LOW: "금액이 너무 낮습니다. 최소: {{num}} {{coin}}",
  INVALID_ADDRESS: "지갑 주소가 잘못되었습니다.",

  I_send: "나는 보낸다",
  I_receive: "나는 받는다",
  ENTER_AMOUNT: "금액을 입력하세요",

  USER_NO_PACKAGE: "사용자 패키지 없음",
  TRANSFER_LIMIT: "송금 한도",
  GO_TO_DASHBOARD: "대시 보드로 이동",
  ACCOUNT_NOT_FOUND: "계정을 찾지 못했습니다",
  PURCHASE: "구입",



};