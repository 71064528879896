import React, { useEffect, useState } from "react";
import { getLanguage } from "../../../languages/getLanguage";
import {
  FormControl,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Input,
  Typography,
  FormHelperText,
  Divider,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  checkEmpty,
  checkNumber,
  checkUsername,
} from "../../../settings/validate";
import { post } from "../../../utils/api";
import { ENDPOINT_POST_WITHDRAW_TRANSFER } from "../../../settings/endpoint";
import NumberFormat from "react-number-format";
import { CustomToast } from "../../../settings";
import { _getWithdrawHistory } from "../../../actions/walletActions";
import { useDispatch, useSelector } from "react-redux";
import { formatAmount } from "../../../settings/format";
import TransactionConfirm from "./TransactionConfirm";
import GACode from "./GACode";
import { InputAdornment } from "@material-ui/core";

function Transfer(props) {
  const { balance, _showWarning } = props;

  return (
    <div className="card">
      <div className="card-body">
        <TabNetworkList balance={balance} _showWarning={_showWarning} />
      </div>
    </div>
  );
}

export default Transfer;

function TabNetworkList(props) {
  const { balance, _showWarning } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [amount, setAmount] = useState("");
  const [youWillGet, setYouWillGet] = useState(0);
  const dispatch = useDispatch();
  const [fieldError, setFieldError] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const { user } = useSelector((state) => state);
  const { information } = user;
  const [showGaInput, setShowGaInput] = useState(false);
  const [gaCodeLoading, setGaCodeLoading] = useState(false);
  const [showWaring, setShowWarning] = useState(false);

  useEffect(() => {
    setUsername("");
    setAmount("");
  }, [balance]);

  const _handleSubmit = (e) => {
    e.preventDefault();
    if (information.gaEnable) {
      const tempErrors = [];
      if (checkEmpty(username)) {
        tempErrors.push({
          field: "username",
          error: getLanguage("PLEASE_ENTER_USERNAME"),
        });
      }
      if (checkUsername(username)) {
        tempErrors.push({
          field: "username",
          error: getLanguage("INVALID_USERNAME", { minChar: 6, maxChar: 32 }),
        });
      }
      if (checkEmpty(amount)) {
        tempErrors.push({
          field: "amount",
          error: getLanguage("PLEASE_ENTER_AMOUNT"),
        });
      } else {
        var num = parseFloat(amount.replaceAll(",", ""));
        if (isNaN(num)) {
          num = 0;
        }
        if (checkNumber(num)) {
          tempErrors.push({
            field: "amount",
            error: getLanguage("INVALID_AMOUNT"),
          });
        } else {
          if (num <= 0) {
            tempErrors.push({
              field: "amount",
              error: getLanguage("NUMBER_GREATER", {
                param: "Amount",
                number: 0,
              }),
            });
          }
        }
      }
      if (num - balance.transferFee <= 0 || num < balance.minimumTransfer) {
        tempErrors.push({
          field: "amount",
          error: getLanguage("YOUR_AMOUNT_TOO_LOW", { num: formatAmount(balance.minimumTransfer), coin: balance.asset })
        });
      }

      setFieldError(tempErrors);

      if (tempErrors.length === 0) {
        setLoading(true);
        setShowConfirm(true);
        setFieldError(null);
      }
    } else {
      setShowWarning(true);
    }
  };

  const _handleTransfer = (gaCode) => {
    var param;
    if (gaCode) {
      param = {
        type: "TRANSFER",
        asset: balance.asset,
        network: "",
        amount: youWillGet,
        username,
        gaCode,
        addressTag: "",
      };
    } else {
      param = {
        type: "TRANSFER",
        asset: balance.asset,
        network: "",
        amount: youWillGet,
        username,
        addressTag: "",
      };
    }
    post(
      ENDPOINT_POST_WITHDRAW_TRANSFER,
      param,
      () => {
        setUsername("");
        setAmount("");
        setYouWillGet("");
        setLoading(false);
        CustomToast("success", getLanguage("Transfer successfully"));
        dispatch(_getWithdrawHistory(1, 10, "TRANSFER"));
        setGaCodeLoading(false);
        setShowGaInput(false);
      },
      (error) => {
        setLoading(false);
        setGaCodeLoading(false);
        CustomToast("error", getLanguage(error.code));
        setFieldError([{ field: "all", error: getLanguage(error.code) }]);
      }
    );
  };

  const _handleConfirm = () => {
    setShowConfirm(false);
    if (information.gaEnable) {
      setShowGaInput(true);
    } else {
      _handleTransfer();
    }
  };

  const _checkError = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const _getErrorContent = (field) => {
    if (fieldError) {
      const check = fieldError.filter((item) => item.field === field)[0];
      if (check) {
        return check.error
      } else {
        return null;
      };
    } else {
      return null;
    }
  };

  const _resetErrors = () => setFieldError(null);

  const _onHandleInputGaCode = (e) => {
    e.preventDefault();
    setGaCodeLoading(true);
    const { value } = e.target.gaCode;
    if (checkEmpty(value)) {
      CustomToast("error", getLanguage("PLEASE_ENTER_GA_CODE"));
      setGaCodeLoading(false);
    } else if (value.trim().length !== 6) {
      CustomToast("error", getLanguage("WRONG_GACODE"));
      setGaCodeLoading(false);
    } else {
      _handleTransfer(value);
    }
  };

  const _handleHideWarning = () => setShowWarning(false);

  return (
    <>
      <form className="w-100" onSubmit={_handleSubmit} noValidate>
        <Grid container spacing={2}>
          {balance.transferEnable ? (
            <>
              <Grid item xs={12}>
                <FormControl error={_checkError("username")} required fullWidth size="small">
                  <InputLabel>{getLanguage("USERNAME")}</InputLabel>
                  <Input
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      _resetErrors();
                    }}
                  />
                  {
                    _checkError("username") &&
                    <FormHelperText error>
                      {_getErrorContent("username")}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={_checkError("amount")} required fullWidth>
                  <InputLabel>{getLanguage("AMOUNT")}</InputLabel>
                  <NumberFormat
                    thousandSeparator={true}
                    inputMode="numeric"
                    customInput={Input}
                    value={amount}
                    onChange={(e) => {
                      _resetErrors();
                      setAmount(e.target.value);
                      var value = parseFloat(
                        e.target.value.replaceAll(",", "")
                      );
                      if (isNaN(value)) {
                        value = 0;
                      }
                      if (value > 0 && value >= balance.minimumTransfer) {
                        setYouWillGet((value - balance.transferFee).toFixed(8));
                      } else {
                        setYouWillGet(0);
                      }
                    }}
                    placeholder={`${getLanguage("MINIMUM")}: ${formatAmount(
                      balance.minimumTransfer
                    )} ${balance.asset}`}
                  />
                  {
                    _checkError("amount") &&
                    <FormHelperText error>
                      {_getErrorContent("amount")}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} className="text-right mt-3 mb-3">
                <Typography>
                  {getLanguage("AMOUNT")}:{" "}
                  {amount === "" ? (
                    0
                  ) : (
                    <NumberFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}{" "}
                  {balance.asset}
                </Typography>
                <Typography>
                  {getLanguage("TRANSACTION_FEE")}:{" "}
                  {
                    <NumberFormat
                      value={balance.transferFee}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }{" "}
                  {balance.asset}
                </Typography>
                <Typography>
                  {getLanguage("YOU_WILL_GET")}: {formatAmount(youWillGet)}{" "}
                  {balance.asset}
                </Typography>
              </Grid>
              {
                _checkError("all") &&
                <Grid item xs={12}>
                  <FormHelperText error={_checkError("all")}>
                    {_getErrorContent("all")}
                  </FormHelperText>
                </Grid>
              }
              <Grid item xs={12}>
                <Grid container className={classes.wrapper} justify="center">
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={loading ? true : false}
                    >
                      {getLanguage("CONFIRM")}
                    </Button>
                    {loading && (
                      <CircularProgress
                        color="primary"
                        size={30}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="mt-3">
                <Typography variant="body2">{getLanguage("Tip")}: </Typography>
                <Typography variant="body2">
                  - {getLanguage("YOU_NEED_ENABLE_GA")}
                </Typography>
                <Typography variant="body2">
                  -{" "}
                  {getLanguage("JUST_TRANSFER_100_DOLLAR_WHEN_NO_VERIFICATION")}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" color="initial" align="center">
                {getLanguage("TRANSFER_DISABLE")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
      <GACode
        open={showGaInput}
        _onCancel={() => {
          setShowGaInput(false);
          setLoading(false);
        }}
        _onConfirm={_onHandleInputGaCode}
        loading={gaCodeLoading}
      />
      <TransactionConfirm
        open={showConfirm}
        title="Confirming for your transfer"
        balance={balance}
        data={{
          amount: parseFloat(amount.replaceAll(",", "")),
          username,
          fee: balance.transferFee,
          asset: balance.asset,
          youWillGet,
        }}
        _onCancel={() => {
          setShowConfirm(false);
          setLoading(false);
        }}
        _onConfirm={_handleConfirm}
      />
      <Dialog
        open={showWaring}
        onClose={_handleHideWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {getLanguage("USE_TRANSFER_SERVICE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getLanguage("YOU_NEED_ENABLE_GA")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleHideWarning} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

//#region CSS
const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
}));
//#endregion
