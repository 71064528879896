import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _getWithdrawHistory } from "../../../actions/walletActions";
import { getLanguage } from "../../../languages/getLanguage";
import { getLinkHash } from "../../../settings";
import {
  formatAddress,
  formatAmount,
  formatTime,
} from "../../../settings/format";

function WithdrawHistory() {
  const { wallet } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const { withdrawHistory } = wallet;

  useEffect(() => {
    dispatch(_getWithdrawHistory(page, pageSize, "WITHDRAW"));
  }, [dispatch, page, pageSize]);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <TableContainer component={Paper} className="pb-3">
      <Typography variant="h6" className="p-3">
        {getLanguage("WITHDRAW_HISTORY")}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{getLanguage("TIME")}</TableCell>
            <TableCell>{getLanguage("TYPE")}</TableCell>
            <TableCell>{getLanguage("AMOUNT")}</TableCell>
            <TableCell>{getLanguage("ADDRESS")}</TableCell>
            <TableCell>{getLanguage("TRANSACTION_HASH")}</TableCell>
            <TableCell>{getLanguage("STATUS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {withdrawHistory &&
            withdrawHistory.itemCount > 0 &&
            withdrawHistory.items.map((item, index) => (
              <TableRow key={index} className="text-nowrap">
                <TableCell>{formatTime(item.createdTime)}</TableCell>
                <TableCell>{getLanguage(item.type)}</TableCell>
                <TableCell>
                  {formatAmount(item.amount)} {item.asset}
                </TableCell>
                <TableCell>{formatAddress(item.address)}</TableCell>
                <TableCell>
                  {item.status === "COMPLETED"
                    ? getLinkHash(item.txId)
                    : "--/--"}
                </TableCell>
                <TableCell>{getLanguage(item.status)}</TableCell>
              </TableRow>
            ))}
          {withdrawHistory && withdrawHistory.itemCount === 0 && (
            <TableRow>
              <TableCell colSpan={5}>
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!withdrawHistory && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {withdrawHistory && withdrawHistory.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={withdrawHistory.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  );
}

export default WithdrawHistory;
