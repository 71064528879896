import {
  FETCH_TRANSACTION_HISTORY,
  GET_ACHIEVEMENT,
  GET_BALANCE,
  GET_F1_MEMBER,
  GET_F1_MEMBER_IN_BINARY_TREE,
  GET_F1_MEMBER_NOT_IN_BINARY_TREE,
  GET_MINED_BALANCE,
  GET_MY_PACKAGE_LIST,
  GET_PACKAGE_LIST,
  GET_TRANSACTION_BINARY,
  GET_TRANSACTION_DIRECT,
  GET_TRANSACTION_MATCHING,
  GET_TRANSACTION_MINE,
} from "../constants";

const initialState = {
  balances: null,
  packageList: null,
  myPackageList: null,
  member: null,
  memberNotInBinaryTree: null,
  memberInBinaryTree: null,
  mineTransaction: null,
  matchingTransaction: null,
  directTransaction: null,
  binaryTransaction: null,
  achievement: null,
  minedBalances: [],
  transactions: null,
};

export const MiningReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTION_HISTORY:
      return { ...state, transactions: payload };
    case GET_MINED_BALANCE:
      return { ...state, minedBalances: payload };
    case GET_ACHIEVEMENT:
      return { ...state, achievement: payload };
    case GET_TRANSACTION_BINARY:
      return { ...state, binaryTransaction: payload };
    case GET_TRANSACTION_DIRECT:
      return { ...state, directTransaction: payload };
    case GET_TRANSACTION_MATCHING:
      return { ...state, matchingTransaction: payload };
    case GET_TRANSACTION_MINE:
      return { ...state, mineTransaction: payload };
    case GET_F1_MEMBER_IN_BINARY_TREE:
      return { ...state, memberInBinaryTree: payload };
    case GET_F1_MEMBER_NOT_IN_BINARY_TREE:
      return { ...state, memberNotInBinaryTree: payload };
    case GET_F1_MEMBER:
      return { ...state, member: payload };
    case GET_MY_PACKAGE_LIST:
      return {
        ...state,
        myPackageList: payload,
      };
    case GET_BALANCE:
      return { ...state, balances: payload };
    case GET_PACKAGE_LIST:
      return { ...state, packageList: payload };
    default:
      return { ...state };
  }
};
