import React from "react";
import { Container, Grid, Button, Link } from "@material-ui/core";
import "./style.scss";
import { getLanguage } from "../../../../languages/getLanguage";

const Blog = () => {

  const blogs = [
    {
      title: "Filecoin and Hedera Hashgraph Announce Grant Program to Propel Web3 Interoperability",
      category: "Update",
      description: "Filecoin is making the web more secure and efficient with a decentralized data storage marketplace, protocol, and cryptocurrency.",
      image: "https://filecoin.io/uploads/fil-blog-2021-xx-xx-hedera.png",
      link: "https://filecoin.io/blog/posts/filecoin-and-hedera-hashgraph-announce-grant-program-to-propel-web3-interoperability",
      date: "Jul 27, 2021"
    },
    {
      title: "Zero Knowledge and the Filecoin Network",
      category: "Update",
      description: "Filecoin is making the web more secure and efficient with a decentralized data storage marketplace, protocol, and cryptocurrency.",
      image: "https://filecoin.io/uploads/fil-blog-2021-07-29-snarks.png",
      link: "https://filecoin.io/blog/posts/zero-knowledge-and-the-filecoin-network",
      date: "Jul 29, 2021"
    },
    {
      title: "Introducing Web3.Storage: A simple interface for Filecoin storage",
      category: "Update",
      description: "Filecoin is making the web more secure and efficient with a decentralized data storage marketplace, protocol, and cryptocurrency.",
      image: "https://filecoin.io/uploads/frame-4.jpg",
      link: "https://filecoin.io/blog/posts/introducing-web3-storage",
      date: "Aug 3, 2021"
    },
  ];

  return (
    <div className="blog">
      <Container>
        <h6 className="txt-main-5 fw-7">Blog filecoin</h6>
        <Grid container spacing={2}>
          {blogs &&
            blogs.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <div className="box-card">
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img src={item.image} alt={item.title} />
                    <span>{item.category}</span>
                    <p className="txt-sub-1 fw-7 title">{item.title}</p>
                  </a>
                  <div className="card-info">
                    <p className="txt-sub-3">{item.description}</p>
                    <p className="txt-sub-5">{item.date}</p>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
        <Link href="https://filecoin.io/blog/#posts" target="_blank">
          <Button
            variant="contained"
            style={{
              backgroundImage: "linear-gradient(to right, #0076ba, #16e7cf)",
              color: "#fff",
            }}
          >
            {getLanguage("blog_read_more_button")}
          </Button>
        </Link>
      </Container>
    </div>
  );
};

export default Blog;
