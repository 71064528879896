import {
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getLanguage } from "../../../../languages/getLanguage";
import { isLoggedIn, isMobileApp } from "../../../../utils/auth";
import BtnLang from "../BtnLang";
import BtnSupport from "../BtnSupport";
import "./style.scss";
import { HashLink } from "react-router-hash-link";

const Sidebar = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const menus = [
    {
      title: getLanguage("HOME"),
      url: `/`,
    },
    {
      title: getLanguage("ABOUT_US"),
      url: `/#why_mining_club`,
    },
    {
      title: getLanguage("WHAT_IS_MINING"),
      url: getLanguage("menu_header_link_1"),
      outside: true,
    },
    {
      title: getLanguage("WHAT_IS_FIL_COIN"),
      url: getLanguage("menu_header_link_2"),
      outside: true,
    },
    {
      title: getLanguage("HOW_TO"),
      url: `/#tutorial`,
    },
  ];

  return (
    <div className="sidebar">
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={6} className="logo">
            <Link to="/">
              <img src="/images/logo.png" alt="" />
            </Link>
          </Grid>
          <Grid item xs={6} className="right">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <Menu fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <List>
          <ListItem>
            <BtnSupport />
            <BtnLang />
          </ListItem>
        </List>
        <Divider />
        <List>
          {menus &&
            menus.map((item, index) => (
              <ListItem key={index}>
                {item.outside ? (
                  <a className="nav-item w-100" href={item.url} target="_blank">
                    {item.title}
                  </a>
                ) : (
                  <HashLink
                    className="nav-item w-100"
                    smooth
                    key={index}
                    to={item.url}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {item.title}
                  </HashLink>
                )}
              </ListItem>
            ))}
        </List>
        <Divider className="mb-4" />
        <div className="text-center">
          {isLoggedIn() ? (
            <Grid container justify="center">
              <Grid item xs={10}>
                <Link to={isMobileApp() ? "/mobile-app/dashboard" : "/dashboard"}>
                  <Button variant="contained" color="primary" fullWidth>
                    {getLanguage("GO_TO_DASHBOARD")}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center">
              <Grid item xs={10}>
                <Link to={isMobileApp() ? "/mobile-app/dashboard/login" : "/dashboard/login"}>
                  <Button variant="contained" color="primary" fullWidth>
                    {getLanguage("LOGIN")}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={10} className="mt-3 text-center">
                <Link to={isMobileApp() ? "/mobile-app/dashboard/sign-up" : "/dashboard/sign-up"}>Create new account</Link>
              </Grid>
            </Grid>
          )}
        </div>
        <div className="text-center">
          <img src="/images/symbol-img/symbol-web.png" alt="symbol-web" />
        </div>
      </Drawer>
    </div>
  );
};

export default Sidebar;
