import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Banner from "./Banner";
import Intro from "./Intro";
import WhyMiningClub from "./WhyMiningClub";
import Features from "./Features";
import Tutorial from "./Tutorial";
import Partner from "./partner/Partner";
import Media from "./media/Media";
import Blog from "./blog/Blog";

function Index() {
  return (
    <div className="home-page bg-main-page">
      <div id="back-to-top-anchor" />
      <Banner />
      <Intro />
      <WhyMiningClub />
      <Features />
      <Tutorial />
      <Partner />
      <Media />
      <Blog />
    </div>
  );
}

export default Index;
