import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  useMediaQuery,
} from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";
import History from "./History";
import QrCode from "./QrCode";
import "./style.scss";
import { useSelector } from "react-redux";
import { S3_URL } from "../../../settings";
import CustomLoading from "../common/CustomLoading";
import { useHistory, useParams } from "react-router-dom";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

function Index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { mining } = useSelector((state) => state);
  const { balances } = mining;
  const [balance, setBalance] = useState(null);
  const { asset } = useParams();
  const history = useHistory();

  const _handleChangeSelectOption = (e) => {
    var asset = e.target.value;
    const found = balances.find((element) => element.asset === asset);
    setBalance(found);
  };

  useEffect(() => {
    if (balances) {
      const currentAsset = balances.filter((item) => item.asset === asset)[0];
      if (!currentAsset || !asset) {
        const temp = balances[0];
        if (isMobileApp()) {
          history.push(`/mobile-app/dashboard/wallet/deposit/${temp.asset}`);
        } else {
          history.push(`/dashboard/wallet/deposit/${temp.asset}`);
        }
      } else {
        setBalance(currentAsset);
      }
    }
  }, [asset, balances, history]);

  return (
    <div className="deposit">
      {balance ? (
        <>
          <Container className="mb-3" maxWidth={isMobileApp() ? false : "sm"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" className="title-page">
                  {getLanguage("DEPOSIT")}
                </Typography>
                <div className="card mb-4">
                  <div className="card-body">
                    <FormControl fullWidth className="mb-3">
                      <InputLabel>{getLanguage("SELECT_ASSET")}</InputLabel>
                      <Select
                        value={balance.asset}
                        onChange={(e) => _handleChangeSelectOption(e)}
                      >
                        {balances.map((item, index) => (
                          <MenuItem value={item.asset} key={index}>
                            <img
                              src={`${S3_URL}/coins/${item.asset}.png`}
                              alt={item.asset}
                              width="20"
                              className="mr-2"
                            />
                            {item.asset}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <QrCode balance={balance} />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={isMobileApp() ? false : "lg"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <History balance={balance} />
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <CustomLoading />
      )}

    </div>
  );
}

export default Index;
