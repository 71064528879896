import React from "react";
import "./style.scss";
import {
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { S3_URL } from "../../../../settings";
import { getLanguage } from "../../../../languages/getLanguage";

import clsx from "clsx";
import { isMobileApp } from "../../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

function Index(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { children, title, item, colorSet } = props;

  return (
    <div className={clsx("pricing-design", (isMobileApp() || isMobile) ? "mr-5 ml-5" : "")}>
      <div
        style={{ position: "absolute", width: "100%", top: (isMobileApp() || isMobile) ? 10 : 20, zIndex: 999 }}
      >
        <img
          src={`${S3_URL}packages/${item.package}_2.png`}
          style={{
            width: 70,
            height: 70,
            margin: "auto",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.3)",
            borderRadius: "50%",
          }}
          alt={item.package}
        />
      </div>
      <SVGBackground colorSet={colorSet} title={title} />
      <div className="pricing-content">
        {children}
        <Button
          variant="contained"
          style={{
            backgroundColor: colorSet.dark,
            color: "#fff",
            padding: "10px 30px",
          }}
          onClick={props.onClick}
        >
          {getLanguage("PURCHASE_NOW")}
        </Button>
      </div>
    </div>
  );
}
export default Index;

function SVGBackground(props) {
  const { colorSet } = props;
  const id = "grad-" + colorSet.light.replace("#", "");
  return (
    <svg x="0" y="0" viewBox="0 0 360 220" style={{ marginTop: -50 }}>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop
            offset="0%"
            style={{
              stopColor: colorSet.light,
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: colorSet.dark,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
      </defs>
      <g>
        <path
          fill={`url(#${id})`}
          d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                        L0.732,193.75z"
        ></path>
      </g>
    </svg>
  );
}
