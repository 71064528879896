import {
  CircularProgress,
  Container,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { _getMatchingTransaction } from "../../../actions/miningActions";
import { getLanguage } from "../../../languages/getLanguage";
import { formatAmount } from "../../../settings/format";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

export default function MatchingTx() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const { mining } = useSelector((state) => state);
  const { matchingTransaction } = mining;

  useEffect(() => {
    dispatch(_getMatchingTransaction(page, pageSize));
  }, [dispatch, page, pageSize]);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <div>
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-page">
              {getLanguage("MATCHING_COMMISSION")}
            </Typography>
            {
              isMobile || isMobileApp() ?
                <TableMobile
                  matchingTransaction={matchingTransaction}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                /> :
                <TableDeskTop
                  matchingTransaction={matchingTransaction}
                  _handleChangePage={_handleChangePage}
                  page={page}
                  pageSize={pageSize}
                />
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}


const TableMobile = (props) => {
  const {
    matchingTransaction,
    _handleChangePage,
    page,
    pageSize
  } = props;

  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableBody>
          {matchingTransaction &&
            matchingTransaction.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Grid container>
                    <Grid xs={6} className="mb-2">
                      #{row.id}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="text-right mb-2"
                    >
                      {formatAmount(row.amount)} {row.asset}
                    </Grid>
                    <Grid item xs={6}>
                      <small className="opacity-06">
                        {getLanguage("USERNAME")}
                      </small>
                      <div>{row.downlineUsername}</div>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <small className="opacity-06">
                        {getLanguage("TIME")}
                      </small>
                      <div>
                        {moment(row.createdTime).format("yyyy-MM-DD")}
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          {matchingTransaction && matchingTransaction.itemCount === 0 && (
            <TableRow>
              <TableCell align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!matchingTransaction && (
            <TableRow>
              <TableCell align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell align="right">
              {getLanguage("TOTAL")}:{" "}
              <span>
                {formatAmount(
                  matchingTransaction ? matchingTransaction.meta.total : 0
                )}{" "}
                USDT
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {matchingTransaction && matchingTransaction.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={matchingTransaction && matchingTransaction.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}

const TableDeskTop = (props) => {
  const {
    matchingTransaction,
    _handleChangePage,
    page,
    pageSize
  } = props;

  return (
    <TableContainer component={Paper} className="pt-3 pb-3">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{getLanguage("USERNAME")}</TableCell>
            <TableCell align="right">{getLanguage("AMOUNT")}</TableCell>
            <TableCell align="right">{getLanguage("TIME")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matchingTransaction &&
            matchingTransaction.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>#{row.id}</TableCell>
                <TableCell component="th" scope="row">
                  {row.downlineUsername}
                </TableCell>
                <TableCell align="right">
                  {formatAmount(row.amount)} {row.asset}
                </TableCell>
                <TableCell align="right">
                  {moment(row.createdTime).format("yyyy-MM-DD")}
                </TableCell>
              </TableRow>
            ))}
          {matchingTransaction && matchingTransaction.itemCount === 0 && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                {getLanguage("NO_RECORDS_FOUND")}
              </TableCell>
            </TableRow>
          )}
          {!matchingTransaction && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={3} align="right">
              {getLanguage("TOTAL")}:{" "}
              <span>
                {formatAmount(
                  matchingTransaction ? matchingTransaction.meta.total : 0
                )}{" "}
                USDT
              </span>
            </TableCell>
            <TableCell colSpan={1} />
          </TableRow>
        </TableBody>
      </Table>
      {matchingTransaction && matchingTransaction.itemCount > pageSize && (
        <TablePagination
          component="div"
          count={matchingTransaction && matchingTransaction.itemCount}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={_handleChangePage}
          rowsPerPageOptions={[]}
        />
      )}
    </TableContainer>
  )
}