import { en } from "./en";
import { kr } from "./kr";
import { vi } from "./vi";
import i18next from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: en
    },
    kr: {
        translation: kr
    },
    vi: {
        translation: vi
    }
};

i18next
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
export default i18next;

export function getLanguage(key, paramObj = null, paramListObj = []) {
    var lang;
    if (paramObj === null) {
        lang = i18next.t(key);
    } else {
        lang = i18next.t(key, paramObj);
    }

    var objs = [];

    paramListObj.forEach(element => {
        if (typeof element === 'object' && element !== null) {
            objs.push(element)
        }
    })

    objs.forEach((element, index) => {
        var {
            src,
            className,
            children,
            href,
            target
        } = element.props;
        var type = element.type;
        var tag;
        if (type === "img") {
            tag = `<${type} 
                ${src && `src='${src}'`} 
                ${className && `class='${className}'`} 
            />`;
        } else {
            tag = `<${type} 
                ${className && `class='${className}'`}
                ${href && `href='${href}'`}
                ${target && `target='${target}'`}
            >${children}</${type}>`;
        }
        lang = lang.replace(`[[${index}]]`, tag);
    })

    if (paramListObj.length > 0) {
        return ConvertHtml(lang);
    } else {
        return lang;
    }
};


const ConvertHtml = (lang) => {
    return (
        <div style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: lang }} />
    )
}

export function getCode() {
    var i18nextLng = localStorage.getItem('i18nextLng');
    return i18nextLng;
}