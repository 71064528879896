import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { S3_URL } from "../../../settings";
import videoBackground from "../../assets/videos/store-cloud.mp4";
import { getLanguage } from "../../../languages/getLanguage";

const WhyMiningClub = () => {
  const cards = [
    {
      title: `${getLanguage("why_mining_title_card_1")}`,
      text: `${getLanguage("why_mining_text_card_1")}`,
      image: `${S3_URL}why-is-file-coin/uqwhd812u0.png`,
    },
    {
      title: `${getLanguage("why_mining_title_card_2")}`,
      text: `${getLanguage("why_mining_text_card_2")}`,
      image: `${S3_URL}why-is-file-coin/oqwh83jwq9.png`,
    },
    {
      title: `${getLanguage("why_mining_title_card_3")}`,
      text: `${getLanguage("why_mining_text_card_3")}`,
      image: `${S3_URL}why-is-file-coin/qwhd0913.png`,
    },
  ];
  return (
    <div className="why-mining-club" id="why_mining_club">
      <video src={videoBackground} autoPlay muted loop playsInline />
      <div className="content">
        <Container>
          <Typography className="text-white" variant="h6">
            {getLanguage("why_mining_club")}
          </Typography>
          <Typography
            className="text-white mt-5 mb-5"
            variant="h4"
            style={{ maxWidth: 400 }}
          >
            {getLanguage("why_mining_title")}
          </Typography>
          <Typography
            className="text-white"
            variant="h6"
            style={{ maxWidth: 700 }}
          >
            {getLanguage("why_mining_text")}
          </Typography>
          <Grid
            container
            className="mt-5 pt-5"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            {cards.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <img src={item.image} alt="" className="mb-2" />
                <Typography className="text-white mb-3" variant="h5">
                  {item.title}
                </Typography>
                <Typography className="text-white opacity-06">
                  {item.text}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default WhyMiningClub;
