import { en } from "../languages/en";

const initialState = {
  darkMode: true,
  library: en,
};
export const SettingReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};
