import React, { useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  Chip,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  Hidden,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  InputLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage } from "../../../languages/getLanguage";
import "./style.scss";
import {
  ENDPOINT_ADD_BINARY_TREE,
  ENDPOINT_GET_BINARY_TREE,
} from "../../../settings/endpoint";
import { get, post } from "../../../utils/api";
import Add from "@material-ui/icons/Add";
import { Close, Refresh } from "@material-ui/icons";
import { _getF1MemberNotInBinaryTree } from "../../../actions/miningActions";
import { CustomToast } from "../../../settings";
import { formatAmount, formatUSD } from "../../../settings/format";
import { GET_F1_MEMBER_IN_BINARY_TREE } from "../../../constants";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import { useLayoutEffect } from "react";
import { isMobileApp } from "../../../utils/auth";
import { useTheme } from "@material-ui/core/styles";

const renderTreeDetail = [
  {
    level: 3,
    scale: 0.25,
    top: "-670px",
    left: "-100px",
  },
  {
    level: 5,
    scale: 0.15,
    top: "-1140px",
    left: "-480px",
  },
  {
    level: 10,
    scale: 0.15,
    top: "-2070px",
    left: "-1800px",
  },
  {
    level: 20,
    scale: 0.15,
    top: "-2520px",
    left: "-1970px",
  },
];

function Index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [dataTree, setDataTree] = React.useState(null);
  const [scale, setScale] = React.useState(0.4);
  const { user, mining } = useSelector((state) => state);
  const { information } = user;
  const [selectedBranch, setSelectedBranch] = useState(null);
  const dispatch = useDispatch();
  let { memberNotInBinaryTree } = mining;
  const [selectedMember, setSelectedMember] = useState("");
  const [levelCount, setLevelCount] = useState(3);
  const [showFilter, setShowFilter] = useState(false);
  const [username, setUsername] = useState(
    information ? information.username : ""
  );
  const [searchMember, setSearchMember] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const timeoutRef = useRef(null); // REF TO KEEP TRACK OF THE TIMEOUT
  const [loadingTree, setLoadingTree] = useState(false);

  async function zoomIn() {
    var num = scale + 0.05;
    num = num > 1 ? 1 : num;
    setScale(num);
  }

  async function zoomOut() {
    var num = scale - 0.05;
    num = num > 0.05 ? num : 0.05;
    setScale(num);
  }

  useEffect(() => {
    if (information) {
      setUsername(information.username);
    }
  }, [information]);

  useEffect(() => {
    if (username) {
      setLoadingTree(true);
      setDataTree(null);
      get(
        ENDPOINT_GET_BINARY_TREE +
        "?username=" +
        username +
        "&level=" +
        levelCount,
        (data) => {
          setDataTree(data);
          setTimeout(() => {
            setLoadingTree(false);
          }, 200 * levelCount);
        },
        () => {
          CustomToast("error", getLanguage("User not in binary tree"));
          setUsername(information.username);
        }
      );
    }
  }, [information, levelCount, username]);

  useEffect(() => {
    dispatch(_getF1MemberNotInBinaryTree());
  }, [dispatch]);

  useEffect(() => {
    if (memberNotInBinaryTree && memberNotInBinaryTree.items.length > 0) {
      setSelectedMember(memberNotInBinaryTree.items[0].id);
    }
  }, [memberNotInBinaryTree, selectedBranch]);

  const _handleChange = (e) => {
    setSelectedMember(e.target.value);
  };

  const _handleAddTree = () => {
    setSelectedMember(null);
    setSelectedBranch(null);
    post(
      ENDPOINT_ADD_BINARY_TREE,
      {
        userId: selectedMember,
        side: selectedBranch.position.toUpperCase(),
        sponsorId: selectedBranch.sponsor.userId,
      },
      () => {
        CustomToast("success", "Success");
        dispatch(_getF1MemberNotInBinaryTree());
        get(
          ENDPOINT_GET_BINARY_TREE +
          "?username=" +
          username +
          "&level=" +
          levelCount,
          (data) => setDataTree(data)
        );
      },
      (error) => {
        CustomToast("error", error.msg);
      }
    );
  };

  const _handleSearchByUsername = (e) => {
    e.preventDefault();
    if (e.target.inputUsername.value) {
      setUsername(e.target.inputUsername.value);
    } else {
      setUsername(information.username);
      dispatch({
        type: GET_F1_MEMBER_IN_BINARY_TREE,
        payload: null,
      });
    }
  };

  const _onClick = (e) => {
    setUsername(e);
  };

  const _resetFilter = () => {
    setUsername(information.username);
    document.getElementById("inputUsername").value = "";
    // document.getElementById("filer-form").reset();
  };

  const _handleSelectBranch = (e) => {
    if (memberNotInBinaryTree.items.length > 0) {
      setSelectedBranch(e);
    } else {
      CustomToast("error", getLanguage("NO_FREE_MEMBERS"));
    }
  };

  const _runFunctionAfter = (value) => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      // SET A TIMEOUT
      timeoutRef.current = null;
      get(`/referral/search-binary-tree?username=${value}`, (data) => {
        setSearchMember(data);
      });
    }, 1000);
  };

  const _handleSearch = (e) => {
    const { value } = e.target;
    if (value.length > 2) {
      setSearchMember(null);
      setShowSearch(true);
      _runFunctionAfter(value);
    } else {
      setSearchMember([]);
    }
  };

  const _handleClick = (e) => {
    setShowSearch(true);
  };

  useLayoutEffect(() => {
    const binaryTree = document.getElementById("binary-tree");
    const treeUl = document.getElementById("tree");
    if (binaryTree && treeUl) {
      binaryTree.scrollLeft =
        (treeUl.clientWidth / 2) * scale - binaryTree.clientWidth / 2;
    }
  });

  return (
    <div className="binary-tree">
      <Container maxWidth={isMobileApp() ? false : "lg"}>
        {
          (isMobile || isMobileApp()) ?
            <SearchMobile
              information={information}
              _resetFilter={_resetFilter}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              levelCount={levelCount}
              setLevelCount={setLevelCount}
              _handleSearchByUsername={_handleSearchByUsername}
              _handleSearch={_handleSearch}
              _handleClick={_handleClick}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              searchMember={searchMember}
              setUsername={setUsername}
            /> :
            <SearchDesktop
              information={information}
              _resetFilter={_resetFilter}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              levelCount={levelCount}
              setLevelCount={setLevelCount}
              _handleSearchByUsername={_handleSearchByUsername}
              _handleSearch={_handleSearch}
              _handleClick={_handleClick}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              searchMember={searchMember}
              setUsername={setUsername}
            />
        }
        {dataTree && dataTree.username !== information.username && (
          <Grid container spacing={1}>
            <Grid item>
              <Chip
                label={`${getLanguage("USERNAME")}: ${dataTree.username}`}
                color="primary"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Chip
                label={`${getLanguage("Level")}: ${dataTree.rootRelativeLevel}`}
                color="primary"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Chip
                label={`${getLanguage("POSITION")}: ${getLanguage(
                  dataTree.rootRelativeSide
                )}`}
                color="primary"
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}
        {information.isInBinaryTree ? (
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  height: "500px",
                  width: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 100,
                }}
                hidden={!loadingTree}
              >
                <CircularProgress style={{ margin: "auto" }} />
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "auto",
                }}
                id="binary-tree"
              >
                <div
                  style={{
                    position: "fixed",
                    right: 20,
                    bottom: 20,
                    zIndex: 100,
                  }}
                >
                  <ButtonGroup size="small">
                    <Button
                      onClick={() => zoomIn()}
                      className="no-min-width no-border-radius mr-1"
                    >
                      <AddIcon />
                    </Button>
                    <Button
                      onClick={() => zoomOut()}
                      className="no-min-width no-border-radius mr-1"
                    >
                      <RemoveIcon />
                    </Button>
                    <Button
                      onClick={() => setScale(0.4)}
                      className="no-min-width no-border-radius"
                    >
                      <Refresh />
                    </Button>
                  </ButtonGroup>
                </div>
                {dataTree && (
                  <div
                    style={{
                      transform: `scale(${scale})`,
                      transformOrigin: "top left",
                      display: loadingTree ? "none" : "block",
                    }}
                  >
                    <UlItem
                      dataTree={dataTree}
                      _addingTree={_handleSelectBranch}
                      levelCount={levelCount}
                      _onClick={_onClick}
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography variant="h6" className="mt-5" color="secondary">
              {getLanguage("YOU_ARE_NOT_IN_BINARY_TREE")}
            </Typography>
            <Typography variant="subtitle1">
              {getLanguage("YOUR_SPONSOR")}: {information.sponsorUsername}
            </Typography>
          </>
        )}
        <Drawer
          className="custom-modal-vk"
          anchor="left"
          open={selectedBranch !== null}
        >
          <Container maxWidth="md">
            <Typography variant="h5">
              {getLanguage("ADD_BRANCH_DETAIL")}
            </Typography>
            <Divider className="mt-2 mb-2" />
            <div>
              {getLanguage("YOUR_SPONSOR")}:{" "}
              {selectedBranch && selectedBranch.sponsor.username}
            </div>
            <div>
              {getLanguage("POSITION")}:{" "}
              {selectedBranch &&
                getLanguage(selectedBranch.position.toUpperCase())}
            </div>
            <FormControl
              variant="outlined"
              size="small"
              className="mt-4 mb-5"
              fullWidth
            >
              <InputLabel>{getLanguage("USERNAME")}</InputLabel>
              <Select
                value={selectedMember}
                onChange={_handleChange}
              >
                {memberNotInBinaryTree &&
                  memberNotInBinaryTree.items.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.username} - PV: {formatAmount(option.pv)} -{" "}
                      {getLanguage("PACKAGE")}: {option.package}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setSelectedBranch(null)}
                >
                  {getLanguage("CANCEL")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={_handleAddTree}
                >
                  {getLanguage("ADD")}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Drawer>
        <Hidden only={["md", "lg", "xl"]}>
          <WarningView />
        </Hidden>
      </Container>
    </div>
  );
}
export default Index;

function UlItem(props) {
  const { dataTree, _addingTree, levelCount, _onClick } = props;
  return (
    <ul className="tree" id="tree" onLoadedData={() => console.log("xxx")}>
      <LiItem
        item={dataTree}
        _addingTree={_addingTree}
        defaultLevel={0}
        levelCount={levelCount}
        _onClick={_onClick}
      />
    </ul>
  );
}

function LiItem(props) {
  const { item, _addingTree, defaultLevel, levelCount, _onClick } = props;
  const [level, setLevel] = useState(defaultLevel);

  useEffect(() => {
    setLevel(defaultLevel + 1);
  }, [defaultLevel]);

  return (
    <li>
      <ContentTree item={item} _onClick={_onClick} levelCount={levelCount} />
      {level <= levelCount && (
        <ul>
          {item.left ? (
            <LiItem
              item={item.left}
              _addingTree={_addingTree}
              defaultLevel={level}
              levelCount={levelCount}
              _onClick={_onClick}
            />
          ) : (
            <li>
              <div
                className="content-tree btn-add-tree"
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    _addingTree({
                      sponsor: item,
                      position: "left",
                    })
                  }
                  className="btn-add-sum"
                >
                  <Add fontSize="large" />
                </Button>
              </div>
            </li>
          )}
          {item.right ? (
            <LiItem
              item={item.right}
              _addingTree={_addingTree}
              defaultLevel={level}
              levelCount={levelCount}
              _onClick={_onClick}
            />
          ) : (
            <li>
              <div
                className="content-tree btn-add-tree"
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    _addingTree({
                      sponsor: item,
                      position: "right",
                    })
                  }
                  className="btn-add-sum"
                >
                  <Add fontSize="large" />
                </Button>
              </div>
            </li>
          )}
        </ul>
      )}
    </li>
  );
}

function ContentTree(props) {
  const { item, root, _onClick } = props;
  return (
    <div
      className={clsx("content-tree", root ? "root" : null)}
      style={{
        width: 250,
        border: item.indirectLevel
          ? "1px solid rgb(55, 192, 202)"
          : "1px solid rgb(0, 0, 0, 0.12)",
        cursor: "pointer",
      }}
      onClick={() => _onClick(item.username)}
    >
      <h4>{item.username}</h4>
      <div className="sum-credits">
        <span>
          {getLanguage("RANK")}: <b>{getLanguage(item.rank)}</b>
        </span>
      </div>
      <div className="sum-credits">
        <span>
          {getLanguage("PACKAGE")}: <b>{getLanguage(item.package)}</b>
        </span>
      </div>
      <div className="sum-credits">
        <span>
          {getLanguage("PV")}: <b>{formatUSD(item.pv)}</b>
        </span>
      </div>
      <div className="detail-tree">
        <div className="left-layout">
          <p>
            <b>{getLanguage("LEFT")}</b>
          </p>
          <h3>{formatUSD(item.leftPv)}</h3>
        </div>
        <div className="right-layout">
          <p>
            <b>{getLanguage("RIGHT")}</b>
          </p>
          <h3>{formatUSD(item.rightPv)}</h3>
        </div>
      </div>
      <div
        className="line"
        style={{
          width: "100%",
          // backgroundColor: ranks[item.rank].color,
          position: "absolute",
          top: -8,
          left: 0,
          display: "flex",
        }}
      >
        <div
          style={{
            backgroundColor: ranks[item.rank].color,
            height: 16,
            width: 16,
            margin: "auto",
            borderRadius: "50%",
            border: item.indirectLevel
              ? "1px solid rgb(55, 192, 202)"
              : "1px solid rgb(0, 0, 0, 0.12)",
          }}
        ></div>
      </div>
    </div>
  );
}

const ranks = {
  NO_RANK: {
    color: "#fff",
  },
  PRO: {
    color: "#00a1ff",
  },
  MASTER: {
    color: "#3ac0ca",
  },
  SUPERVISOR: {
    color: "#ed220d",
  },
  COACH: {
    color: "#f8bb00",
  },
  FOUNDER: {
    color: "#5e5e5e",
  },
};

function WarningView() {
  const [open, setOpen] = useState(true);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Before you continue ...</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="inherit">
          We recommend using the desktop version for the best experience.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="primary"
          autoFocus
          variant="outlined"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const SearchMobile = (props) => {

  const {
    information,
    _resetFilter,
    setShowFilter,
    showFilter,
    levelCount,
    setLevelCount,
    _handleSearchByUsername,
    _handleSearch,
    _handleClick,
    showSearch,
    setShowSearch,
    searchMember,
    setUsername,
  } = props;

  return (
    <Grid container className="mb-3" alignItems="center">
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="primary"
          style={{
            textTransform: "unset",
          }}
          onClick={_resetFilter}
        >
          {getLanguage("TOP")}: {information.username}
        </Button>
      </Grid>
      <Grid item xs={6} className="text-right">
        <IconButton
          onClick={() => {
            setShowFilter(!showFilter);
            _resetFilter();
          }}
          disabled={!information}
        >
          {showFilter ? <CloseIcon /> : <FilterListIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showFilter}>
          <Grid container>
            <Grid xs={12} item className="pr-1 text-right">
              <FormControl variant="outlined" size="small">
                <Select
                  value={levelCount}
                  onChange={(e) => setLevelCount(parseInt(e.target.value))}
                  inputProps={{ "aria-label": "Without label" }}
                  style={{ width: 80 }}
                  className="text-left"
                >
                  {renderTreeDetail.map((option, index) => (
                    <MenuItem key={index} value={option.level}>
                      {option.level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                size="small"
                component="form"
                onSubmit={_handleSearchByUsername}
                className="input-border mt-2 w-100pc"
                style={{ position: "relative" }}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  className="pl-3 pr-2"
                >
                  <Grid item style={{ flex: 1 }}>
                    <InputBase
                      placeholder={getLanguage("Search_username")}
                      id="inputUsername"
                      autoComplete="off"
                      size="small"
                      className="w-100pc"
                      onChange={_handleSearch}
                      onClick={_handleClick}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton type="submit" aria-label="search">
                      <SearchIcon color="primary" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Hidden only={["lg", "md", "sm", "xl"]}>
                  {showSearch && (
                    <ClickAwayListener
                      onClickAway={() => setShowSearch(false)}
                    >
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#17222d",
                          width: "calc(100%)",
                          zIndex: 9999,
                          height: 200,
                          overflow: "auto",
                          borderTop: 0,
                          marginTop: -5,
                          borderBottomLeftRadius: 8,
                          borderBottomRightRadius: 8,
                        }}
                        onBlur={(e) => console.log(e)}
                      >
                        {!searchMember && (
                          <div className="text-center">
                            <CircularProgress
                              color="primary"
                              style={{ margin: "auto" }}
                            />
                          </div>
                        )}
                        {searchMember &&
                          searchMember.length > 0 &&
                          searchMember.map((item, index) => (
                            <MenuItem
                              onClick={() => {
                                setUsername(item.username);
                                setShowSearch(false);
                              }}
                            >
                              {item.username}
                            </MenuItem>
                          ))}
                        {searchMember && searchMember.length === 0 && (
                          <Typography align="center">
                            {getLanguage("NO_RECORDS_FOUND")}
                          </Typography>
                        )}
                      </div>
                    </ClickAwayListener>
                  )}
                </Hidden>
              </Paper>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
}

const SearchDesktop = (props) => {

  const {
    information,
    _resetFilter,
    levelCount,
    setLevelCount,
    _handleSearch,
    _handleClick,
    showSearch,
    setShowSearch,
    searchMember,
    setUsername,
  } = props;

  return information.isInBinaryTree && (
    <Paper
      style={{ width: "100%" }}
      className="mt-3 mb-3 filter-bar"
      variant="outlined"
      id="filer-form"
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <FormControl variant="filled" size="small">
            <Select
              value={levelCount}
              onChange={(e) => setLevelCount(parseInt(e.target.value))}
              style={{ width: 80 }}
            >
              {renderTreeDetail.map((option, index) => (
                <MenuItem key={index} value={option.level}>
                  {option.level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item>
          <Button
            variant="text"
            color="primary"
            style={{
              textTransform: "unset",
            }}
            onClick={_resetFilter}
          >
            <Typography color="primary">
              {getLanguage("TOP")}: {information.username}
            </Typography>
          </Button>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item style={{ flex: 1, position: "relative" }}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="pl-3 pr-2"
          >
            <Grid item style={{ flex: 1 }}>
              <InputBase
                placeholder={getLanguage("Search_username")}
                id="inputUsername"
                fullWidth
                autoComplete="off"
                onChange={_handleSearch}
                onClick={_handleClick}
              />
            </Grid>
            <Grid item>
              <IconButton type="submit" aria-label="search">
                <SearchIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <Hidden only={["xs"]}>
            {showSearch && (
              <ClickAwayListener onClickAway={() => setShowSearch(false)}>
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#17222d",
                    width: "calc(100% + 2px)",
                    zIndex: 999,
                    height: 200,
                    overflow: "auto",
                    border: "1px solid rgba(255, 255, 255, 0.12)",
                    borderTop: 0,
                    marginLeft: -1,
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                >
                  {!searchMember && (
                    <div className="text-center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "auto" }}
                      />
                    </div>
                  )}
                  {searchMember &&
                    searchMember.length > 0 &&
                    searchMember.map((item, index) => (
                      <MenuItem
                        onClick={() => {
                          setUsername(item.username);
                          setShowSearch(false);
                        }}
                      >
                        {item.username}
                      </MenuItem>
                    ))}
                  {searchMember && searchMember.length === 0 && (
                    <Typography align="center">
                      {getLanguage("NO_RECORDS_FOUND")}
                    </Typography>
                  )}
                </div>
              </ClickAwayListener>
            )}
          </Hidden>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item>
          <IconButton aria-label="refresh" onClick={_resetFilter}>
            <Close color="action" />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}