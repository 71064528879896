import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Switch, Route } from "react-router-dom";
import { isWidthUp } from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  useTheme,
  Hidden,
  makeStyles,
  useMediaQuery,
  withWidth,
} from "@material-ui/core";
import Header from "./common/Header";
import LeftMenu from "./common/LeftMenu";
import { useSelector } from "react-redux";
import { _getBalance, _getNewProfile } from "../../actions/miningActions";
import {
  CssBaseline,
} from "@material-ui/core";
import { getModesTemplate } from "../../settings";

//#region page
import PageNotFound from "../common/page-not-found/Index";
import HomePage from "./home-page/Index";
import BinaryTree from "./binary-tree-page/Index";
import BuyPlans from "./buy-plans-page/Index";
import MyPlans from "./my-plans-page/Index";
import MyProfile from "./my-profile-page/Index";
import Member from "./member-page/Index";
import MineTx from "./transaction-page/MineTx";
import MatchingTx from "./transaction-page/MatchingTx";
import DirectTx from "./transaction-page/DirectTx";
import BinaryTx from "./transaction-page/BinaryTx";
import PromotionTx from "./transaction-page/PromotionTx";
import Achievement from "./achievement-page/Achievement";
import Deposit from "./deposit-page/Index";
import Withdraw from "./withdraw-page/Index";
import Transfer from "./transfer-page/Index";
import BalancePage from "./balance-page/BalancePage";
import NewKYC from "./verify-page/NewKYC";
import Swap from "./swap/Swap";

import Footer from "../mobile-app-areas/common/Footer";

Main.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

function Main(props) {
  const { user } = useSelector((state) => state);
  const { width } = props;
  const classes = useStyles();

  const [openLeftMenu, setOpenLeftMenu] = useState(
    isWidthUp("md", width) ? true : false
  );

  useEffect(() => {
    getModesTemplate();
  }, []);

  //#region Check login
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(_getNewProfile());
    dispatch(_getBalance());
  }, [dispatch]);
  //#endregion

  const handleDrawerToggleLeftMenu = () => {
    setOpenLeftMenu(!openLeftMenu);
  };

  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down("sm"));
  const toggleDrawer = () => {
    if (screen) {
      setOpenLeftMenu(false);
    }
  };

  return (
    <div className={clsx(classes.root) + " dashboard"}>
      <div
        onClick={() => toggleDrawer()}
        className={clsx(openLeftMenu ? classes.fixLayoutMobile : null)}
      />
      <Header
        user={user}
        openLeftMenu={openLeftMenu}
        handleDrawerToggleLeftMenu={handleDrawerToggleLeftMenu}
      />
      <div className={classes.root}>
        <CssBaseline />
        <LeftMenu
          openLeftMenu={openLeftMenu}
          toggleDrawer={toggleDrawer}
          handleDrawerToggleLeftMenu={handleDrawerToggleLeftMenu}
        />
        <div className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/dashboard/wallet/balance" component={BalancePage} />
            <Route exact path="/dashboard/wallet/deposit/:asset" component={Deposit} />
            <Route exact path="/dashboard/wallet/deposit" component={Deposit} />
            <Route exact path="/dashboard/wallet/withdraw/:asset" component={Withdraw} />
            <Route exact path="/dashboard/wallet/withdraw" component={Withdraw} />
            <Route exact path="/dashboard/wallet/transfer" component={Transfer} />
            <Route exact path="/dashboard/member" component={Member} />
            <Route exact path="/dashboard/verify" component={NewKYC} />
            <Route exact path="/dashboard/my-profile" component={MyProfile} />
            <Route exact path="/dashboard/binary-tree" component={BinaryTree} />
            <Route exact path="/dashboard/plans/buy" component={BuyPlans} />
            <Route exact path="/dashboard/plans/my" component={MyPlans} />
            <Route exact path="/dashboard/transaction/promotion" component={PromotionTx} />
            <Route exact path="/dashboard/transaction/binary-commission" component={BinaryTx} />
            <Route exact path="/dashboard/transaction/direct-commission" component={DirectTx} />
            <Route exact path="/dashboard/transaction/matching-commission" component={MatchingTx} />
            <Route exact path="/dashboard/transaction/mining-history" component={MineTx} />
            <Route exact path="/dashboard/achievement" component={Achievement} />
            <Route exact path="/dashboard" component={HomePage} />
            <Route exact path="/dashboard/wallet/swap" component={Swap} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>

      <Hidden only={['lg', 'md', 'sm', 'xl']}>
        <Footer />
      </Hidden>
    </div>
  );
}

export default withWidth()(Main);

const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: "100vw",
    overflowX: "hidden",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    // width: "100%",
    paddingTop: 30,
    paddingBottom: 115,
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: 50,
    },
  },
}));
