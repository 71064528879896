import React from "react";
import {
    Grid,
    Typography,
    Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getLanguage } from "../../../languages/getLanguage";
import { isMobileApp } from "../../../utils/auth";

function Wallet() {
    return (
        <Grid item xs={12}>
            <Typography variant="h5" className="text-center mb-2">{getLanguage("WALLET")}</Typography>
            <Paper variant="outlined" className="p-2 wallet-home-page bg-transparent">
                <Grid container justify="center" spacing={1} className="pb-2 pt-2">
                    <Grid item xs={3} className="text-center wallet-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/wallet/balance" : "/dashboard/wallet/balance"}>
                            <img src="/images/app-icon/Balance.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {getLanguage("BALANCE")}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={3} className="text-center wallet-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/wallet/deposit" : "/dashboard/wallet/deposit"}>
                            <img src="/images/app-icon/Deposit.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {getLanguage("DEPOSIT")}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={3} className="text-center wallet-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/wallet/withdraw" : "/dashboard/wallet/withdraw"}>
                            <img src="/images/app-icon/Withdraw.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {getLanguage("TRANSFER_WITHDRAW")}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={3} className="text-center wallet-item">
                        <Link to={isMobileApp() ? "/mobile-app/dashboard/wallet/swap" : "/dashboard/wallet/swap"}>
                            <img src="/images/app-icon/swap.png" alt="" className="mb-2 icon-app" />
                            <Typography component="p">
                                {getLanguage("SWAP")}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
export default Wallet;