import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { _getBalance, _getNewProfile } from "../../actions/miningActions";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
    Toolbar,
    Fab
} from "@material-ui/core";
import ScrollTop from "../common/scroll-top/ScrollTop";

//#region page
import Header from "./common/Header";
import Footer from "./common/Footer";
import PageNotFound from "../common/page-not-found/Index";
import HomePage from "./home-page/home-mobile";
import BuyPlans from "../dashboard-areas/buy-plans-page/Index";
import BalancePage from "../dashboard-areas/balance-page/BalancePage";
import MyPlans from "../dashboard-areas/my-plans-page/Index";
import MineTx from "../dashboard-areas/transaction-page/MineTx";
import MatchingTx from "../dashboard-areas/transaction-page/MatchingTx";
import DirectTx from "../dashboard-areas/transaction-page/DirectTx";
import BinaryTx from "../dashboard-areas/transaction-page/BinaryTx";
import PromotionTx from "../dashboard-areas/transaction-page/PromotionTx";
import Member from "../dashboard-areas/member-page/Index";
import BinaryTree from "../dashboard-areas/binary-tree-page/Index";
import Achievement from "../dashboard-areas/achievement-page/Achievement";
import MyProfile from "../dashboard-areas/my-profile-page/Index";
import Deposit from "../dashboard-areas/deposit-page/Index";
import Withdraw from "../dashboard-areas/withdraw-page/Index";
import NewKYC from "../dashboard-areas/verify-page/NewKYC";
import Swap from "../dashboard-areas/swap/Swap";
//#endregion

function Main(props) {
    const { user } = useSelector((state) => state);

    //#region Check login
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(_getNewProfile());
        dispatch(_getBalance());
    }, [dispatch]);
    //#endregion

    return (
        <>
            <Header />
            <Toolbar id="back-to-top-anchor" />
            <main className="pt-3">
                <Switch>
                    <Route exact path="/mobile-app/dashboard" component={HomePage} />
                    <Route exact path="/mobile-app/dashboard/plans/buy" component={BuyPlans} />
                    <Route exact path="/mobile-app/dashboard/wallet/balance" component={BalancePage} />
                    <Route exact path="/mobile-app/dashboard/plans/my" component={MyPlans} />
                    <Route exact path="/mobile-app/dashboard/transaction/promotion" component={PromotionTx} />
                    <Route exact path="/mobile-app/dashboard/transaction/binary-commission" component={BinaryTx} />
                    <Route exact path="/mobile-app/dashboard/transaction/direct-commission" component={DirectTx} />
                    <Route exact path="/mobile-app/dashboard/transaction/matching-commission" component={MatchingTx} />
                    <Route exact path="/mobile-app/dashboard/transaction/mining-history" component={MineTx} />
                    <Route exact path="/mobile-app/dashboard/member" component={Member} />
                    <Route exact path="/mobile-app/dashboard/binary-tree" component={BinaryTree} />
                    <Route exact path="/mobile-app/dashboard/achievement" component={Achievement} />
                    <Route exact path="/mobile-app/dashboard/my-profile" component={MyProfile} />
                    <Route exact path="/mobile-app/dashboard/wallet/deposit/:asset" component={Deposit} />
                    <Route exact path="/mobile-app/dashboard/wallet/deposit" component={Deposit} />
                    <Route exact path="/mobile-app/dashboard/wallet/withdraw/:asset" component={Withdraw} />
                    <Route exact path="/mobile-app/dashboard/wallet/withdraw" component={Withdraw} />
                    <Route exact path="/mobile-app/dashboard/verify" component={NewKYC} />
                    <Route exact path="/mobile-app/dashboard/wallet/swap" component={Swap} />

                    <Route component={PageNotFound} />
                </Switch>
            </main>
            <ScrollTop {...props}>
                <Fab color="secondary" size="small">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <Toolbar className="footer-fix-layout mt-3" />
            <Footer />
        </>
    );
}

export default Main;
