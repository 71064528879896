import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLanguage } from "../../../languages/getLanguage";
import { useDispatch, useSelector } from "react-redux";
import { _getBalance } from "../../../actions/miningActions";
import { S3_URL } from "../../../settings";
import { formatAmount } from "../../../settings/format";
import Info from "./Info";
import History from "./History";
import Transfer from "./Transfer";

function Index() {
  const dispatch = useDispatch();
  const { mining } = useSelector((state) => state);
  const { balances } = mining;
  const [balance, setBalance] = useState(balances[0]);

  const _handleChangeSelectOption = (e) => {
    var asset = e.target.value;
    const found = balances.find(element => element.asset === asset);
    setBalance(found);
  };

  useEffect(() => {
    dispatch(_getBalance());
  }, [dispatch]);

  useEffect(() => {
    const found = balances.find(element => element.asset === balance.asset);
    setBalance(found);
  }, [balances]);

  return (
    <div className="transfer">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" className="title-page">{getLanguage("TRANSFER")}</Typography>
        </Grid>
        <Grid item xs={12} md={5} className="info">
          <div className="card mb-4">
            <div className="card-body">
              <FormControl fullWidth className="mb-3">
                <InputLabel>Balance</InputLabel>
                <Select
                  value={balance.asset}
                  onChange={(e) => _handleChangeSelectOption(e)}
                >
                  {
                    balances.map((item, index) =>
                      <MenuItem value={item.asset} key={index}>
                        <img src={`${S3_URL}/coins/${item.asset}.png`} alt={item.asset} width="20" className="mr-2" />
                        {item.asset} - {formatAmount(item.amount)}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          <Info balance={balance} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Transfer balance={balance} />
        </Grid>
        <Grid item xs={12}>
          <History balance={balance} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Index;
